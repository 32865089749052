import { Avatar, Chip } from '@reverse-hr/pattern-library';
import PropTypes from 'prop-types';
import { getConcernsCategoriesString } from '../../../../../utils/application';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Photo = props => {
  const {
    avatarUrl,
    firstName,
    lastName,
    currentJobTitle,
    id,
    concerns,
    discardedAt,
  } = props;
  const history = useHistory();
  const { jobId } = useParams();
  const { t } = useTranslation();

  const containerClassNames = classNames({
    'c-app-showcase__cell': true,
    'c-app-showcase__cell--center-aligned': true,
    'c-app-showcase__cell--interested-but': concerns !== null && !discardedAt,
    'c-app-showcase__cell--out': !!discardedAt,
  });

  const avatarSize = concerns !== null || discardedAt ? 24 : 72;

  const handleClick = () => {
    if (jobId && id) {
      history.push(`/jobs/${jobId}/candidates/${id}`);
    }
  };

  const renderCandidateOutChip = () =>
    discardedAt && (
      <Chip
        modifier="c-chip--no-hover u-bg-color-error-color-40 u-color-error-color-120"
        size="small"
        clickable={false}
        leftIcon="icn-user-close-24"
      >
        {t('showcase.chip.out')}
      </Chip>
    );

  const renderInterestedButChip = () => {
    if (!discardedAt && concerns !== null) {
      const uniqueCategories = getConcernsCategoriesString(concerns, t);

      return (
        <>
          <Chip
            modifier="c-chip--no-hover u-bg-color-accent-60 u-color-accent-120"
            size="small"
            clickable={false}
            leftIcon="icn-warning-outline-24"
          >
            {t('showcase.chip.interestedBut')}
          </Chip>
          <p className="typo-text-caption">
            <strong>{uniqueCategories}</strong>
          </p>
        </>
      );
    }
    return null;
  };

  return (
    <td className={containerClassNames}>
      <div onClick={handleClick} className="c-app-showcase--photo-link">
        <Avatar avatar={avatarUrl || null} size={avatarSize} />
        <p className="u-txt-underline typo-text-body u-color-primary-100 u-mt-space-8">
          <strong>
            {firstName || ''} {lastName || ''}
          </strong>
        </p>
        <p className="typo-text-caption u-color-grey-80 u-mb-space-8">
          {currentJobTitle}
        </p>
        {renderCandidateOutChip()}
        {renderInterestedButChip()}
      </div>
    </td>
  );
};
Photo.propTypes = {
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  currentJobTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  concerns: PropTypes.array,
  discardedAt: PropTypes.string,
};

export default Photo;
