const ENDPOINTS = {
  closing: '/employer_view/closing',
  success_fee: '/employer_view/success_fee',
  share: '/employer_view/deals/{id}/shares',
  jwt: '/v1/users/jwt',
  token_refresh: '/v1/users/token/refresh',
  test: '/test',
};

export { ENDPOINTS };
