import React, { useCallback } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import TextBlock from '../components/TextBlock';
import Brackets from '../components/Brackets';
import { profile } from '../utils/default-values';
import { selectorUser } from '../model/User';
import { AppBanner } from '../components/AppBanner';
import { useTranslation } from 'react-i18next';
import { utilityGetPageState } from '../utils/get-page-state';
import { PageStateProvider } from '../components/Providers/PageStateProvider';
import { Referents } from '../components/Referents';
import { GTM } from '../gtm';
import { USER_TYPES_MAP } from '../constants/session';
import {
  jobProgressSelector,
  jobStoreSelector,
} from '../model/Job/Job.selectors';
import { JOB_PROGRESS_STATES } from '../model/Job/_constants/Job.constants';
import {
  getSignedContractSelector,
  setSignedContractDataSelector,
  signedContractDataSelector,
} from '../model/Contract/Contract.selectors';
import { useDownloadFile } from '../utils/hooks/use-download-file';

const Profiling = () => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector) || {};
  const progress = useStoreState(jobProgressSelector);
  const { type: userType } = useStoreState(selectorUser);
  const getSignedContract = useStoreActions(getSignedContractSelector);
  const signedContractData = useStoreState(signedContractDataSelector);
  const setSignedContractData = useStoreActions(setSignedContractDataSelector);

  useDownloadFile({
    url: signedContractData?.url,
    filename: signedContractData?.filename,
    cb: () => setSignedContractData(null),
  });

  const { header, cards } = profile();

  const pageState = utilityGetPageState({
    progress,
    currentRoute: JOB_PROGRESS_STATES.PROFILING,
  });

  const handleDownloadContractClick = useCallback(async () => {
    const eventAction = `Download contratto ${
      userType === USER_TYPES_MAP.MANAGER ? 'prospect' : 'cliente'
    }`;

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${job.companyName} - ${job.id} - ${job.title}`,
    });

    GTM.trackGA4Event(eventAction, job.companyName, `${job.id} - ${job.title}`);

    await getSignedContract({ contractId: job.contractId });
  }, [job.contractId]);

  const handleMiroClick = () => {
    const eventAction = `Miro ${
      userType === USER_TYPES_MAP.MANAGER ? 'prospect' : 'cliente'
    }`;

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${userType} - ${job.companyName} - ${job.id} - ${job.title}`,
    });

    GTM.trackGA4Event(
      eventAction,
      job.companyName,
      `${userType} - ${job.id} - ${job.title}`,
    );
  };

  return (
    <PageStateProvider value={pageState}>
      <section data-testid="profiling-page">
        <div className="container">
          <div className="row">
            {job.profilingBoardUrl ? (
              <div className="col col--x-padded small-12 large-6">
                <div data-testid="profiling-page-header">
                  <TextBlock {...header} />
                </div>
                <div
                  data-testid="profiling-page-miro"
                  className="o-wrapper o-wrapper--base-width"
                >
                  <Brackets
                    items={cards.items}
                    button={{
                      icon: 'icn-chevron-big-right-24',
                      iconPosition: 'right',
                      label: cards.action.label,
                      url: job.profilingBoardUrl,
                      onClick: handleMiroClick,
                    }}
                  />
                </div>
              </div>
            ) : null}

            <div className="col col--x-padded small-12 large-6">
              <Referents />
            </div>
          </div>
        </div>

        {progress ? (
          <div className="container">
            <div className="row">
              <div className="col col--x-padded u-mt-space-16">
                <AppBanner
                  title={t('profile.contract.title')}
                  ctaLabel={t('profile.contract.download')}
                  ctaUrl={signedContractData?.url}
                  onClick={handleDownloadContractClick}
                />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </PageStateProvider>
  );
};

export default Profiling;
