import type { ActionCreator, Actions } from 'easy-peasy';
import type { StoreModel } from '../types/store-model';

export const setCurrentStatusSelector = (
  state: Actions<StoreModel>,
): ActionCreator<string> => state.setCurrentStatus;

export const setCurrentRouteSelector = (
  state: Actions<StoreModel>,
): ActionCreator<string> => state.setCurrentRoute;
