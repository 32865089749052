import React from 'react';
import { useTranslation } from 'react-i18next';

export const FooterLeftBlock: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="c-footer__paragraph">
        {t('footer.company', {
          year: new Date().getFullYear(),
        })}
      </p>
      <p className="c-footer__paragraph">{t('footer.headquarter')}</p>
    </div>
  );
};
