import { action, thunk } from 'easy-peasy';
import type { TContract, TContractStore } from './Contract.type';
import i18next from 'i18next';

export const contractStore: TContractStore = {
  data: null,
  signedContractData: null,
  error: null,
  loading: false,

  setContract: action((state, payload) => {
    state.data = payload;
  }),

  setSignedContractData: action((state, payload) => {
    state.signedContractData = payload;
  }),

  setError: action((state, payload) => {
    state.error = payload;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  getContract: thunk(async (actions, { contractId }, helpers) => {
    const _storeActions = helpers.getStoreActions();

    actions.setLoading(true);
    actions.setError(null);

    try {
      const response = (await _storeActions.session.authFetch({
        endpoint: `/v1/employer-view/contracts/${contractId}`,
      })) as TContract;

      actions.setContract(response);
    } catch (error: unknown) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),

  getSignedContract: thunk(async (actions, payload, helpers) => {
    const { contractId } = payload || {};
    const _storeActions = helpers.getStoreActions();
    const _storeState = helpers.getStoreState();
    const job = _storeState.job.data;
    let fallbackFilename: string | null = null;

    actions.setLoading(true);
    actions.setError(null);

    try {
      const { filename, blob } = await _storeActions.session.authFetchPdf({
        endpoint: `/v1/employer-view/contracts/${contractId}/downloads/signed-document`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      const url = URL.createObjectURL(blob);

      if (!filename) {
        fallbackFilename = i18next.t(
          'common.downloads.signedContractFallback',
          {
            companyName: job?.title,
          },
        );
      }

      actions.setSignedContractData({
        filename: filename ?? fallbackFilename,
        url,
      });
      return { filename: filename ?? fallbackFilename, url };
    } catch (error: unknown) {
      actions.setError(error);
      return null;
    } finally {
      actions.setLoading(false);
    }
  }),
};
