import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Action, Alert, FormField } from '@reverse-hr/pattern-library';
import { shareLinkModal } from '../../utils/default-values';
import share from '../../assets/images/share.jpg';
import akiShare from '../../assets/images/aaron-king/modal-share.jpg';
import { validate } from '../../utils/form-validation';
import { useFormValidation } from '../../utils/hooks/useFormValidation';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { selectorRootActions } from '../../model';
import { utilityThrowError } from '../../utils/errors';
import classNames from 'classnames';
import { ShareLinkModalList } from './_partials/ShareLinkModalList';
import {
  jobClientSelector,
  jobSharesSelector,
  postJobShareSelector,
} from '../../model/Job/Job.selectors';
import { useParams } from 'react-router-dom';
import { IS_THEME_AKI } from '../../.configs/environment';

const initialModel = {
  firstName: {
    value: '',
    valid: false,
  },
  lastName: {
    value: '',
    valid: false,
  },
  email: {
    value: '',
    valid: false,
  },
  language: {
    value: 'it',
    valid: true,
  },
};

export const ShareLinkModal = forwardRef(({ modifier }, ref) => {
  const { onChange, onSubmit, isFormValid } = useFormValidation(initialModel);
  const { title, button, shared_with, alert, inputs } = shareLinkModal();
  const [mappedInputs, setMappedInputs] = useState(inputs);

  const [alertDetail, setAlertDetail] = useState({
    visible: false,
    icon: 'icn-check-24',
    type: 'positive',
    children: alert.positive,
  });

  const customer = useStoreState(jobClientSelector);
  const shares = useStoreState(jobSharesSelector);
  const { entityId, jobId } = useParams();

  const { setModalOpened, setModalId } = useStoreActions(selectorRootActions);
  const postShare = useStoreActions(postJobShareSelector);

  const hasShares = shares?.length > 0;

  const wrapperHeightClassName = classNames(
    'c-app-colleague-sharing c-share-link-modal',
    {
      'c-app-colleague-sharing--no-shares': !hasShares,
      'c-app-colleague-sharing--with-shares': hasShares,
    },
    modifier,
  );

  const handleFormChange = event => {
    onChange(event);
  };
  const handleValidation = (event, input) => {
    if (input.id === 'email') {
      const emailValid = validate('email', event);
      if (typeof emailValid === 'boolean' && emailValid === true) {
        const hint = validate('domain', event, customer.email.split('@')[1]);
        if (hint) {
          setMappedInputs(prev => {
            return prev.map(input => ({
              ...input,
              hint: input.id === 'email' ? hint : null,
            }));
          });
        } else {
          setMappedInputs(prev => {
            return prev.map(input => ({ ...input, hint: null }));
          });
        }
      }
      return emailValid;
    }
    if (input.required) {
      return validate('required', event);
    }
    return true;
  };

  const handleSubmit = async formValues => {
    setMappedInputs(prev => {
      return prev.map(input => ({ ...input, disabled: true }));
    });
    try {
      const response = await postShare({
        jobId: entityId ?? jobId,
        ...formValues,
      });

      if (!response) {
        utilityThrowError(
          'Share link modal failed while sending share request.',
        );
      } else {
        const resettedInputs = mappedInputs.map(input => {
          const value = input.type === 'input' ? '' : 'it';
          const valid = input.type !== 'input';
          onChange({ name: input.id, value, valid });
          const keyArr = input.key.split('_');
          const key = Number(keyArr[keyArr.length - 1]);
          return {
            ...input,
            value,
            hint: null,
            key: `${input.id}_${key + 1}`,
          };
        });

        setMappedInputs(resettedInputs);
        setAlertDetail(prevAlert => ({ ...prevAlert, visible: true }));
      }
    } catch (error) {
      setAlertDetail({
        visible: true,
        type: 'negative',
        icon: 'icn-close-big-24',
        children: alert.negative,
      });

      utilityThrowError(
        error,
        'Share link modal failed while sending share request.',
      );
    } finally {
      setMappedInputs(prev => {
        return prev.map(input => ({ ...input, disabled: false }));
      });
    }
  };

  const handleCloseModal = () => {
    setModalOpened(false);
    setModalId(null);
  };
  useImperativeHandle(ref, () => ({
    cleanModal() {
      handleCloseModal();
    },
  }));

  return (
    <div className={wrapperHeightClassName}>
      <div
        style={{ backgroundImage: `url(${IS_THEME_AKI ? akiShare : share})` }}
        className="c-app-colleague-sharing_image"
      ></div>
      <div className="c-app-colleague-sharing__content">
        <div className="c-app-colleague-sharing__close">
          <Action
            iconOnly
            icon="icn-close-big-24"
            type="outline"
            onClick={handleCloseModal}
          />
        </div>
        <div className="container container--full">
          <div className="row">
            <div className="col col--x-padded">
              <p className="c-app-colleague-sharing__title">{title}</p>
            </div>
          </div>
        </div>

        <div className="container container--full">
          <div className="row">
            {mappedInputs.map((input, index) => {
              return (
                <div
                  key={`form-field-${index}`}
                  className="col col--x-padded col--y-padded small-12 large-6"
                >
                  <FormField
                    {...input}
                    inputProps={{ value: input.value }}
                    onChange={handleFormChange}
                    validateFn={evt => handleValidation(evt, input)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="container container--full u-mt-space-24">
          <div className="row">
            <div className="col col--x-padded">
              <Action
                type="secondary"
                label={button}
                icon="icn-chevron-big-right-24"
                iconPosition="right"
                disabled={!isFormValid}
                onClick={() => onSubmit(handleSubmit)}
              />
            </div>
            {alertDetail.visible ? (
              <div className="col col--x-padded u-mt-space-24">
                <Alert
                  type={alertDetail.type}
                  icon={alertDetail.icon}
                  onClose={() =>
                    setAlertDetail(prev => ({ ...prev, visible: false }))
                  }
                >
                  {alertDetail.children}
                </Alert>
              </div>
            ) : null}
          </div>
        </div>

        <div
          className={`container container--full u-mt-space-${
            alertDetail.visible ? '24' : '48'
          }`}
        >
          {hasShares && (
            <div className="row">
              <div className="col col--x-padded">
                <p className="u-mb-space-16 u-color-grey-80">{shared_with}</p>

                <ShareLinkModalList />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
