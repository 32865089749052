import { Link, useParams } from 'react-router-dom';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import Masthead from '../Masthead';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { jobStoreSelector } from '../../model/Job/Job.selectors';

export const SuccessFeeHeader = () => {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const job = useStoreState(jobStoreSelector);

  return (
    <Masthead full job={job} title={t('hero.prospect.title')}>
      {{
        left: (
          <div className="c-app-masthead__back">
            <div className="c-back">
              <Link
                to={`/jobs/${jobId}/closing`}
                modifier="c-back__anchor"
                component={LinkWrapper}
                type="simple-text"
                label={t('successFee.backCta')}
                icon="icn-chevron-big-left-24"
                iconPosition="left"
              />
            </div>
          </div>
        ),
      }}
    </Masthead>
  );
};
