import { useEffect, useMemo } from 'react';
import type { TJobProgressSegment } from '../../components/navigation/JobProgressSwitch/JobProgressSwitch.types';
import {
  JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES,
  TJobProgressSwitchParams,
} from '../../components/navigation/JobProgressSwitch/JobProgressSwitch.types';
import {
  candidateLoadingSelector,
  candidatesSelector,
} from '../../model/Candidate/Candidate.selectors';
import {
  jobLoadingSelector,
  jobStoreSelector,
  setJobProgressSelector,
} from '../../model/Job/Job.selectors';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { JOB_PROGRESS_SEGMENTS } from './../../components/navigation/JobProgressSwitch/_constants/JobProgressSwitch.constants';
import { useParams } from 'react-router-dom';
import { CANDIDATE_EVENT } from '../../model/Candidate/constants/types.constants';
import { isPast } from 'date-fns';

/**
 * This hooks returns the current job progress segment based on the job and candidates data.
 * All needed data is fetched from the store.
 *
 * @returns TJobProgressSegment
 */
export const useJobProgress = (): TJobProgressSegment => {
  const job = useStoreState(jobStoreSelector);
  const candidates = useStoreState(candidatesSelector);
  const isCandidatesFetching = useStoreState(candidateLoadingSelector);
  const isJobFetching = useStoreState(jobLoadingSelector);
  const setJobProgress = useStoreActions(setJobProgressSelector);

  const { entityType, entityId: jobId } = useParams<TJobProgressSwitchParams>();

  const jobProgress = useMemo(() => {
    const isLoading = isJobFetching || isCandidatesFetching;

    const isContract =
      entityType === JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES.CONTRACTS || !job;

    if (isLoading || isContract) {
      return JOB_PROGRESS_SEGMENTS.PROFILING;
    }

    const hasAcceptedOffers = candidates?.results?.some(candidate =>
      candidate.events.some(
        event => event.type === CANDIDATE_EVENT.OFFER_ACCEPTED,
      ),
    );

    if (hasAcceptedOffers || job.closedAt) {
      return JOB_PROGRESS_SEGMENTS.CLOSING;
    }

    if (!job.presentationMeetingScheduledAt) {
      return JOB_PROGRESS_SEGMENTS.PROFILING;
    }

    const presentationMeetingDate = new Date(
      job.presentationMeetingScheduledAt.utcDateTime,
    );

    return isPast(presentationMeetingDate)
      ? JOB_PROGRESS_SEGMENTS.INTERVIEWING
      : JOB_PROGRESS_SEGMENTS.SEARCHING;
  }, [jobId, job, candidates, isJobFetching, isCandidatesFetching]);

  useEffect(() => {
    setJobProgress(jobProgress);
  }, [jobProgress]);

  return jobProgress;
};
