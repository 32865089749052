export const COMMON_EN = {
  formatting: {
    date: {
      daysAgo: '{{ count }} days ago',
      daysAgo_zero: 'Today',
      daysAgo_one: 'Yesterday',

      month_1: 'January',
      month_2: 'February',
      month_3: 'March',
      month_4: 'April',
      month_5: 'May',
      month_6: 'June',
      month_7: 'July',
      month_8: 'August',
      month_9: 'September',
      month_10: 'October',
      month_11: 'November',
      month_12: 'December',

      dateFormat_pretty:
        '{{- date, datetime(format: dd)}} $t(common.formatting.date.month_{{- date, datetime(format: M) }}) {{- date, datetime(format: yyyy)}}',
    },
  },

  downloads: {
    candidateDossierFallback:
      'Candidate Dossier - {{ firstName }} {{ lastName }}',
    candidateCommitmentLetterFallback:
      'Commitment Letter - {{ firstName }} {{ lastName }}',
    candidateResignationLetterFallback:
      'Resignation Letter - {{ firstName }} {{ lastName }}',
    candidateTechnicalReportFallback:
      'Candidate Technical Report - {{ firstName }} {{ lastName }}',
    signedContractFallback: 'Contract - {{ companyName }}',
  },
};
