import { useTranslation } from 'react-i18next';
import { Parser } from 'html-to-react';
import { intervalToDuration } from 'date-fns';

export const useDrivingTime = drivingDistanceInMinutes => {
  const { t } = useTranslation();
  const htmlParser = new Parser();

  if (!drivingDistanceInMinutes) return;

  const outputStrings = [];
  const drivingDistanceInMilliseconds = drivingDistanceInMinutes * 60 * 1000;
  const { hours, minutes } = intervalToDuration({
    start: 0,
    end: drivingDistanceInMilliseconds,
  });

  if (hours > 0) outputStrings.push(t('showcase.drivingTime.hours', { hours }));
  if (minutes > 0)
    outputStrings.push(t('showcase.drivingTime.minutes', { minutes }));

  return <>{htmlParser.parse(outputStrings.join(' '))}</>;
};
