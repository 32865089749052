export const CANDIDATE_CONCERN = {
  ROLE_TRAVEL_AVAILABILITY: 'role_travel_availability',
  ROLE_SENIORITY: 'role_seniority',
  ROLE_RESPONSIBILITIES: 'role_responsibilities',
  ROLE_TECHNICAL_TOOLS: 'role_technical_tools',
  ROLE_FUTURE_PERSPECTIVES: 'role_future_perspectives',
  ROLE_CHANGED_JOB_RECENTLY: 'role_changed_job_recently',
  ROLE_SOFT_SKILLS: 'role_soft_skills',
  LOCATION_NO_REMOTE: 'location_no_remote',
  LOCATION_TOO_FAR: 'location_too_far',
  LOCATION_TOO_ISOLATED: 'location_too_isolated',
  COMPENSATION_BENEFITS: 'compensation_benefits',
  COMPENSATION_SALARY: 'compensation_salary',
  COMPESATION_BONUSES: 'compensation_bonuses',
  COMPENSATION_WORKING_HOURS: 'compensation_working_hours',
  BRAND_REPUTATION: 'brand_reputation',
  BRAND_SECTOR: 'brand_sector',
  BRAND_PRODUCT_RTANGE: 'brand_product_range',
  BRAND_RECENT_CHANGES: 'brand_recent_changes',
  BRAND_COMPANY_SIZE: 'brand_company_size',
};

export const CANDIDATE_EVENT = {
  PRESENTED: 'presented',
  SEEN_BY_CLIENT: 'seen_by_client',
  LIKED_BY_CLIENT: 'liked_by_client',
  DISLIKED_BY_CLIENT: 'disliked_by_client',
  TECHNICAL_REPORT_INCOMING: 'technical_report_incoming',
  TECHNICAL_REPORT_PUBLISHED: 'technical_report_published',
  CLIENT_INTERVIEW_TO_BE_SCHEDULED: 'client_interview_to_be_scheduled',
  CLIENT_INTERVIEW_INCOMING: 'client_interview_incoming',
  CLIENT_INTERVIEW_DONE: 'client_interview_done',
  OFFER_INCOMING: 'offer_incoming',
  OFFER_MADE: 'offer_made',
  OFFER_ACCEPTED: 'offer_accepted',
  OFFER_DECLINED: 'offer_declined',
  CANDIDATE_WITHDREW: 'candidate_withdrew',
  CANDIDATE_REJECTED: 'candidate_rejected',
} as const;

export const CANDIDATE_BENEFIT = {
  CANTEEN: 'canteen',
  CAR: 'car',
  TRAINING: 'training',
  WELFARE: 'welfare',
  MEAL_VOUCHERS: 'meal_vouchers',
  INSURANCE: 'insurance',
  SMARTPHONE: 'smartphone',
  REMOTE_WORKING: 'remote_working',
  PERSONAL_COMPUTER: 'personal_computer',
} as const;
