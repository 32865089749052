import showcase from './showcase';
import { COMMON_ES } from './common';
import { SEARCHING_ES } from './searching';
import { INTERVIEWING_ES } from './interviewing';
import { CLOSING_ES } from './closing';
import { SUCCESS_FEE_ES } from './success-fee';
import { HOMEPAGE_ES } from './homepage';
import { CANDIDATE_DETAILS_ES } from './candidate-details';
import { ERRORS_ES } from './errors';
import * as LOGIN_ES from './login.json';

const ES = {
  translation: {
    header: {
      welcome: 'Hola',
      share: 'Compartir',
    },
    hero: {
      backToHomepage: 'Todos tus mandatos',
      backToHomepage_headHunter: 'Todos los mandatos del cliente',
      prospect: {
        title: 'Cálculo de la tarifa a éxito',
      },
      steps: {
        profiling: {
          title: 'Perfil',
          title_no_miro: 'Personas de contacto',
          description: 'Inicio',
        },
        searching: {
          title: 'Búsqueda',
          description: '1-10°días',
        },
        showcase: {
          title: 'Showcase',
          description: '11°día',
        },
        interviewing: {
          title: 'Entrevistas',
          description: '11-20°días',
        },
        closing: {
          title: 'Cierre',
          description: 'Fin',
        },
      },
      helper: {
        profiling: {
          'to-do':
            'Hola. Te presentamos nuestro proceso. Si lo desea, puede echar un vistazo. Siempre <strong>podrá ver</strong> el progreso.',
          'to-do_withName':
            'Hola {{ name }}. Te presentamos nuestro proceso. Si lo desea, puede echar un vistazo. Siempre <strong>podrá ver</strong> el progreso.',
          doing:
            'Hola. Te presentamos nuestro proceso. Si lo desea, puede echar un vistazo. Siempre <strong>podrá ver</strong> el progreso.',
          doing_withName:
            'Hola {{ name }}. Te presentamos nuestro proceso. Si lo desea, puede echar un vistazo. Siempre <strong>podrá ver</strong> el progreso.',
          done: 'En esta página encontrarás un resumen siempre actualizado del perfil del candidato, de su empresa y de nuestro servicio.',
        },
        searching: {
          'to-do':
            'Durante la búsqueda, <strong>podrás ver las actividades que llevamos a cabo</strong>, hasta que los datos estén disponibles.',
          doing:
            'Durante la búsqueda, <strong>podrás ver las actividades que llevamos a cabo</strong>, hasta que los datos estén disponibles.',
          done: '¡Fin de la búsqueda! A continuación, encontrarás <strong>las cifras</strong> y el <strong>JRA</strong>, nuestro análisis final.',
        },
        showcase: {
          'to-do':
            'Durante la búsqueda, <strong>podrás ver las actividades que llevamos a cabo</strong>, hasta que los datos estén disponibles.',
          doing:
            'Durante la búsqueda, <strong>podrás ver las actividades que llevamos a cabo</strong>, hasta que los datos estén disponibles.',
          done: '¡Fin de la búsqueda! A continuación, encontrarás <strong>las cifras</strong> y el <strong>JRA</strong>, nuestro análisis final.',
        },
        interviewing: {
          'to-do':
            'En esta fase, te <strong>mostraremos los candidatos</strong> y te pediremos <strong>feedback</strong>',
          doing:
            'Recuerda que <strong>cuanto más ágiles seamos, mayor será la probabilidad</strong> de éxito.',
          done: 'Recuerda que <strong>cuanto más ágiles seamos, mayor será la probabilidad</strong> de éxito.',
        },
        closing: {
          'to-do':
            'Es la fase final: la más delicada. <strong>Negociación, renuncias e incorporaciones</strong>.',
          doing:
            'Es la fase final: la más delicada. <strong>Negociación, renuncias e incorporaciones</strong>.',
          done: 'Es la fase final: la más delicada. <strong>Negociación, renuncias e incorporaciones</strong>.',
        },
      },
    },
    profile: {
      header: {
        title: 'Perfil',
        subtitle:
          'En esta página encontrarás un resumen siempre actualizado del perfil del candidato, de su empresa y de nuestro servicio.',
      },
      cards: {
        action: {
          label: 'Ver los detalles',
        },
        1: {
          title: 'El perfil del candidato',
        },
        2: {
          title: 'Así le hablamos de tu empresa al candidato',
        },
      },
      contract: {
        title: 'Aquí encontrarás el contrato de este mandato',
        download: 'Descargar el contrato',
      },
      references: {
        title: 'Tus personas de contacto',
        blank: {
          title: 'Estamos buscando a la persona adecuada para este trabajo',
          text: 'Queremos al mejor cazador que tenemos disponible...',
        },
      },
      mirror: {
        body: '<strong>Una nueva forma de seleccionar personal</strong><br><br>FLEXIBLE, DIGITAL, ESPECIALIZADA.',
        link: 'Descubre la selección de personal colaborativa',
      },
    },
    general: {
      error: {
        title: 'Se ha producido un error',
        subtitle:
          'Intenta actualizar la página. Si no consigues solucionar el problema, inténtalo de nuevo más tarde.',
        hash: {
          title: 'Este enlace ha caducado.',
          subtitle:
            'Deberías haber recibido el <strong>enlace correcto</strong> en uno de nuestros <strong>últimos correos electrónicos</strong>',
          disclaimer:
            '¿No lo encuentras? <strong>Comunícate con tus personas de contacto</strong>',
        },
        unauthorized: {
          title: 'Questo link sembra sbagliato.',
          subtitle:
            'Deberías haber recibido el <strong>enlace correcto</strong> en uno de nuestros <strong>correos electrónicos</strong>, <strong>poco después</strong> de la <strong>firma del contrato</strong>.',
          disclaimer:
            '¿No lo encuentras? <strong>Comunícate con tus personas de contacto</strong>',
        },
        unauthorizedHomepage: {
          title: 'No estás autorizado para ver esta página.',
          subtitle: '',
          disclaimer: '',
        },
      },
      document_title: {
        profiling: 'Perfil',
        searching: 'Búsqueda',
        interviewing: 'Entrevistas',
        closing: 'Cierre',
        showcase: 'Showcase',
      },
      date_on: 'los',
      date_hour: 'a las',
    },
    candidate: {
      hero: {
        title: 'estás buscando:',
        back_label: 'Volver al mandato',
      },
      infos: {
        age: 'anni',
        benefits: 'Beneficios actuales',
        bonuses: 'Bonificaciones actuales',
      },
      sections: {
        horse: {
          title: 'Estado actual',
        },
        bet: {
          open_chat: 'Escribir por chat a <strong>{{ name }}</strong>',
        },
        curriculum: {
          title: 'Curriculum Vitae',
          screening: 'Ranking de preselección',
          button: 'Abrir el currículum',
        },
        summary: {
          otherSelectionsTitle: 'Otros procesos de selección',
        },
        chat: {
          hour: ' horas ',
          feedback_title: 'Déjanos tu feedback',
          name_title: 'Escribir a <strong>{{ name }}</strong>',
          open: 'leer más',
          close: 'cerrar',
          fullscreen: 'Pantalla completa',
          default_message:
            'Hola. Si deseas compartir algo con nosotros, utiliza este chat.',
          default_message_withName:
            'Hola, {{name}}. Si deseas compartir algo con nosotros, utiliza este chat.',
          default_message_positive:
            'Hola. ¿Por qué <strong>te interesa</strong>? En la entrevista, ¿deseas profundizar en algún aspecto?',
          default_message_positive_withName:
            'Hola {{ name }}. ¿Por qué <strong>te interesa</strong>? En la entrevista, ¿deseas profundizar en algún aspecto?',
          default_message_negative:
            'Hola. Introduce tus motivaciones para que podamos perfeccionar la búsqueda de candidatos.',
          default_message_negative_withName:
            'Hola, {{name}}. Introduce tus motivaciones para que podamos perfeccionar la búsqueda de candidatos.',
        },
        concerns: {
          intro:
            '{{ name }} es un perfil muy interesante pero no del todo acorde con los requisitos.',
        },
      },
    },
    footer: {
      company: 'Reverse España S.L.',
      headquarter: 'VAT B72409337',
      informations: [
        'Oficina reg.: Barcelona (08006)',
        'Carrer de Tuset, 10 Esc. Izq., 5º 2ª',
      ],
      menu: {
        contact: {
          label: 'Contáctenos',
          slug: 'contactenos',
        },
        legal: {
          label: 'Cookies',
          slug: 'legal',
        },
        cookies: {
          label: 'Legal&privacy',
          url: 'https://www.iubenda.com/privacy-policy/41212079/cookie-policy',
        },
      },
    },
    modal: {
      share: {
        title:
          'Enviar invitación para actualizaciones sobre el progreso de contratación',
        inputs: {
          first_name: {
            label: 'Nombre',
          },
          last_name: {
            label: 'Apellido',
          },
          email: {
            label: 'Correo electrónico',
            hint: 'El correo electrónico no coincide con el dominio con el que está registrado, ¿está seguro de que desea continuar?',
          },
          lang: {
            label: 'Lengua',
          },
        },
        lang_options: {
          it: 'Italiano',
          en: 'Inglés',
          de: 'Alemán',
          fr: 'Francés',
          es: 'Español',
        },
        button: 'Enviar invitación',
        shared_with: 'Compartido con',
        alert: {
          positive: 'La invitación por correo electrónico ha sido enviada',
          negative:
            'Algo no ha ido bien. Inténtalo más tarde o comunícate con las personas de contacto',
        },
      },
    },
    validation: {
      scout_approved: 'Validado por el Scout',
      scout_required: 'Validar con el Scout',
      required: 'Este campo es obligatorio',
      email: 'Esta dirección de correo electrónico no es válida',
    },
    language_switcher: {
      it: 'IT',
      en: 'EN',
      de: 'DE',
      fr: 'FR',
      es: 'ES',
    },
    showcase,
    candidateDetails: CANDIDATE_DETAILS_ES,
    common: COMMON_ES,
    searching: SEARCHING_ES,
    interviewing: INTERVIEWING_ES,
    closing: CLOSING_ES,
    successFee: SUCCESS_FEE_ES,
    homepage: HOMEPAGE_ES,
    login: LOGIN_ES,
    errors: ERRORS_ES,
  },
};

export default ES;
