import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import MainSwitch from './components/navigation/MainSwitch';
import { SpriteSVG } from '@reverse-hr/pattern-library';
import { GTM } from './gtm';
import Hotjar from './hotjar';
import { pdfjs } from 'react-pdf';
import { selectorUser } from './model/User';
import { APP_BASE_PATH } from './constants/navigation';
import { IS_THEME_AKI } from './.configs/environment';
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

const App = () => {
  const {
    locale,
    email: userEmail,
    type: userType,
  } = useStoreState(selectorUser);

  useEffect(() => {
    if (
      userType &&
      userType !== 'manager' &&
      process.env.NODE_ENV !== 'development'
    ) {
      GTM.initialize({ locale, userEmail });
      Hotjar.initialize();
    }
    if (IS_THEME_AKI) {
      document.title = 'Aaron King International';
    }
  }, [userType]);

  return (
    <div className="customer">
      <BrowserRouter basename={APP_BASE_PATH}>
        <MainSwitch />
      </BrowserRouter>
      <SpriteSVG />
    </div>
  );
};

export default App;
