import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Horserace from '../utils/horserace';
import { Chip, Icon } from '@reverse-hr/pattern-library';

import { formatDate } from '../utils/functions';
import { CANDIDATE_EVENT_TYPES } from '../constants/candidate';

const Horse = ({
  candidateId,
  type,
  name,
  dateTime,
  modifier,
  showLinks,
  showAdditionalInfo,
}) => {
  const { t } = useTranslation();
  const horserace = new Horserace();
  const {
    type: horseType,
    tag,
    additional_info,
  } = horserace.getHorsecard({ type, name });
  const { entityId: jobId } = useParams();
  const candidateLink = `/jobs/${jobId}/candidates/${candidateId}`;
  const candidateDetailLink =
    additional_info && additional_info.type === 'action' ? candidateLink : '/';

  const getFormattedDate = () => {
    if (type === CANDIDATE_EVENT_TYPES.CLIENT_INTERVIEW_DONE) {
      return `${t('general.date_on')} ${formatDate(dateTime, 'dd/MM/yyyy')} ${t(
        'general.date_hour',
      )} ${formatDate(dateTime, 'HH:mm')}`;
    }
    return formatDate(dateTime, 'dd/MM/yyyy');
  };

  return (
    <div
      data-testid="horse-container"
      className={`c-app-horse c-app-horse--${horseType} ${modifier || ''}`}
    >
      {horseType !== 'finish' ? (
        <>
          <p data-testid="horse-date" className="c-app-horse__top-text">
            {getFormattedDate()}
          </p>
          {tag && (
            <div data-testid="horse-tag" className="c-horse__chip">
              <Chip {...tag} />
            </div>
          )}
          {additional_info && showAdditionalInfo ? (
            additional_info.type === 'text' ? (
              <p
                data-testid="horse-additional-text"
                className="c-app-horse__anchor"
              >
                {additional_info.text}
              </p>
            ) : showLinks && additional_info.type === 'link' ? (
              <a
                data-testid="horse-additional-link"
                href={additional_info.url}
                className="c-app-horse__anchor"
              >
                {additional_info.text}
              </a>
            ) : showLinks && additional_info.type === 'action' ? (
              <Link
                data-testid="horse-additional-action-link"
                to={candidateDetailLink}
              >
                <p
                  className="c-app-horse__anchor"
                  data-testid="horse-additional-action-label"
                >
                  {additional_info.text}
                </p>
              </Link>
            ) : null
          ) : null}
        </>
      ) : (
        <div className="c-app-horse__finish-item">
          <Icon size={56} name="icn-trophy-24" />
        </div>
      )}
    </div>
  );
};

Horse.defaultProps = {
  name: '',
  showLinks: true,
};

export default Horse;
