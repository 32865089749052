import type {
  TJobProgressSegment,
  TJobProgressSegmentLoaderProps,
} from '../JobProgressSwitch.types';
import { useStoreState } from '../../../../store/hooks';
import ProfileLoader from '../../../../loaders/Profile';
import SearchLoader from '../../../../loaders/Search';
import InterviewLoader from '../../../../loaders/Interview';
import ClosingLoader from '../../../../loaders/Closing';
import { jobProgressSelector } from '../../../../model/Job/Job.selectors';

export const JobProgressSwitchSegmentWrapper = (
  props: TJobProgressSegmentLoaderProps,
): JSX.Element => {
  const { loaderType, children } = props;
  const initialized: boolean = useStoreState(state => state.initialized);

  if (!initialized) {
    return (
      <div className="container">
        <div className="row">
          <div className="col col--x-padded">
            <JobProgressSwitchSegmentWrapperLoader name={loaderType} />
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export const JobProgressSwitchSegmentWrapperLoader = (props: {
  name: TJobProgressSegment;
}): null | JSX.Element => {
  const { name } = props;
  const progress = useStoreState(jobProgressSelector);

  switch (name) {
    case 'profiling':
      return <ProfileLoader />;

    case 'searching':
      return (
        <SearchLoader
          status={
            ['interviewing', 'closing'].includes(progress as string)
              ? 'done'
              : 'doing'
          }
        />
      );

    case 'interviewing':
      return (
        <InterviewLoader
          status={['closing'].includes(progress as string) ? 'done' : 'doing'}
        />
      );

    case 'closing':
      return <ClosingLoader />;
    default:
      return null;
  }
};
