import React from 'react';
import { useStoreState } from 'easy-peasy';
import { utilityGetPageState } from '../utils/get-page-state';
import { PageStateProvider } from '../components/Providers/PageStateProvider';
import {
  InterviewingHeader,
  InterviewingHorsejogs,
  InterviewingSidebar,
} from '../components/Interviewing';
import { Referents } from '../components/Referents';
import classNames from 'classnames';
import { jobProgressSelector } from '../model/Job/Job.selectors';
import {
  JOB_PAGE_STATES,
  JOB_PROGRESS_STATES,
} from '../model/Job/_constants/Job.constants';
import { candidatesSelector } from '../model/Candidate/Candidate.selectors';

const Interviewing = () => {
  const progress = useStoreState(jobProgressSelector);
  const { results: candidates } = useStoreState(candidatesSelector);

  const pageState = utilityGetPageState({
    progress,
    currentRoute: JOB_PROGRESS_STATES.INTERVIEWING,
    hasApplications: candidates.length > 0,
  });

  const isPageStateDoing = pageState === JOB_PAGE_STATES.DOING;

  const pageBodyClassNames = classNames('container container--full', {
    'u-mt-space-80': isPageStateDoing,
  });

  const horsejogsWrapperClassNames = classNames('col', {
    'u-mt-space-48': pageState === JOB_PAGE_STATES.DONE,
  });

  return (
    <PageStateProvider value={pageState}>
      <section data-testid="interviewing-page">
        <div className="container">
          <div className="row">
            <InterviewingHeader />
            <InterviewingSidebar />
          </div>
        </div>

        {pageState !== JOB_PAGE_STATES.TO_DO ? (
          <div className={pageBodyClassNames}>
            <div className="row">
              <div className={horsejogsWrapperClassNames}>
                <InterviewingHorsejogs />
              </div>
            </div>
          </div>
        ) : null}

        {isPageStateDoing ? (
          <div className="container">
            <div className="row">
              <div className="col col--x-padded small-12 large-6">
                <Referents />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </PageStateProvider>
  );
};

export default Interviewing;
