import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import {
  SearchingHeader,
  SearchingSidebar,
  SearchingTasks,
} from '../components/Searching';
import { PageStateProvider } from '../components/Providers/PageStateProvider';
import { utilityGetPageState } from '../utils/get-page-state';
import { GTM } from '../gtm';
import {
  jobProgressSelector,
  jobStoreSelector,
} from '../model/Job/Job.selectors';
import {
  JOB_PAGE_STATES,
  JOB_PROGRESS_STATES,
} from '../model/Job/_constants/Job.constants';

const Searching = () => {
  const job = useStoreState(jobStoreSelector);
  const progress = useStoreState(jobProgressSelector);

  const pageState = utilityGetPageState({
    progress,
    currentRoute: JOB_PROGRESS_STATES.SEARCHING,
  });

  const isSearchingTasksVisible =
    pageState === JOB_PAGE_STATES.DOING && !!job.statistics.activities?.length;

  useEffect(() => {
    if (pageState === JOB_PAGE_STATES.DONE) {
      GTM.trackGTMEvent({
        action: EVENT_ACTION_SEARCH_DONE,
        label: `${job.companyName} - ${job.id} - ${job.title}`,
      });

      GTM.trackGA4Event(
        EVENT_ACTION_SEARCH_DONE,
        job.companyName,
        `${job.id} - ${job.title}`,
      );
    }
  }, [job.companyName, job.id, job.title, pageState]);

  return (
    <PageStateProvider value={pageState}>
      <section data-testid="searching-page">
        <div className="container">
          <div className="row">
            <SearchingHeader />
            <SearchingSidebar />
          </div>
        </div>

        {isSearchingTasksVisible ? <SearchingTasks /> : null}
      </section>
    </PageStateProvider>
  );
};

export default Searching;

const EVENT_ACTION_SEARCH_DONE = 'Ricerca completata';
