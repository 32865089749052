import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainLayout from '../../../layouts/MainLayout';
import Profiling from '../../../pages/Profiling';
import Searching from '../../../pages/Searching';
import Showcase from '../../../pages/stepper/showcase';
import { JobProgressSwitchNotFound } from './_partials/JobProgressNotFound';
import { JobProgressSwitchSegmentWrapper } from './_partials/JobProgressSwitchSegmentWrapper';
import Interviewing from '../../../pages/Interviewing';
import Closing from '../../../pages/Closing';
import { useEntityNavigation } from './_hooks/use-entity-navigation';
import { useJobProgress } from '../../../utils/hooks/use-job-progress';
import { useStoreState } from '../../../store/hooks';
import { jobStoreSelector } from '../../../model/Job/Job.selectors';
import { LoaderFullPage } from '../../../loaders/LoaderFullPage';

const JobProgressSwitch: React.FC = () => {
  const jobProgress = useJobProgress();

  // STATE
  const job = useStoreState(jobStoreSelector);

  useEntityNavigation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  if (!job) {
    return <LoaderFullPage />;
  }
  return (
    <MainLayout>
      <Switch>
        <Route path="/:entityType(jobs|contracts)/:entityId/profiling">
          <JobProgressSwitchSegmentWrapper loaderType="profiling">
            <Profiling />
          </JobProgressSwitchSegmentWrapper>
        </Route>

        <Route path="/:entityType(jobs|contracts)/:entityId/searching">
          <JobProgressSwitchSegmentWrapper loaderType="searching">
            <Searching />
          </JobProgressSwitchSegmentWrapper>
        </Route>

        <Route path="/:entityType(jobs|contracts)/:entityId/interviewing">
          <JobProgressSwitchSegmentWrapper loaderType="interviewing">
            <Interviewing />
          </JobProgressSwitchSegmentWrapper>
        </Route>

        <Route path="/:entityType(jobs|contracts)/:entityId/closing">
          <JobProgressSwitchSegmentWrapper loaderType="closing">
            <Closing />
          </JobProgressSwitchSegmentWrapper>
        </Route>

        <Route path="/jobs/:jobId/showcase">
          {job?.isShowcaseEnabled && <Showcase />}
        </Route>

        <Route
          path={[
            '/:entityType(jobs|contracts)/:entityId/*',
            '/:entityType(jobs|contracts)/:entityId',
          ]}
        >
          <JobProgressSwitchNotFound progress={jobProgress} />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default JobProgressSwitch;
