import { differenceInDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { AppCardTask } from '../AppCardTask';
import { sortStacksKeys } from './_helpers';

export const AppTasksStack = ({ stacks }) => {
  const sortedStacksKeys = sortStacksKeys(stacks);
  return (
    <>
      {sortedStacksKeys.map(stackDate => (
        <div className="c-app-tasks c-app-tasks--stack" key={stackDate}>
          <div className="row">
            <div className="col col--x-padded small-12 large-5">
              <AppTasksStackDate stackDate={stackDate} />
            </div>

            <div className="col col--x-padded small-12 large-7">
              {stacks[stackDate].map((task, taskIdx) => (
                <div className="c-app-tasks__item" key={taskIdx}>
                  <AppCardTask task={task} />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const AppTasksStackDate = ({ stackDate }) => {
  const { t } = useTranslation();
  const daysPast = differenceInDays(new Date(), new Date(stackDate));

  return (
    <p className="c-app-tasks__date">
      {daysPast <= 6
        ? t('common.formatting.date.daysAgo', { count: daysPast })
        : t('common.formatting.date.dateFormat', {
            context: 'pretty',
            date: new Date(stackDate),
          })}
    </p>
  );
};
