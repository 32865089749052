import { Header } from '@reverse-hr/pattern-library';
import { WelcomeMessage } from '../WelcomeMessage';
import LanguageSwitcher from '../TheHeader/LanguageSwitcher';
import { HomepageNavigation } from './HomepageNavigation';
import { useStoreState } from 'easy-peasy';
import { jobCompanyNameSelector } from '../../model/Job/Job.selectors';
import { IS_THEME_AKI } from '../../.configs/environment';
import { APP_BASE_PATH } from '../../constants/navigation';

export const HomepageHeader = () => {
  const companyName = useStoreState(jobCompanyNameSelector);

  return (
    <div className="c-homepage-header container container--full">
      <div className="container">
        <div className="row">
          <div className="col col--x-padded u-py-space-16">
            <Header
              externalLogo={`${APP_BASE_PATH}/assets/images/logo/reverse-group-logo.png`}
            >
              <div className="c-homepage-header__content">
                <WelcomeMessage
                  labelClassName={
                    IS_THEME_AKI ? 'typo-text-label--aki' : undefined
                  }
                  nameClassName={
                    IS_THEME_AKI ? 'typo-text-body--aki' : undefined
                  }
                />
                <LanguageSwitcher />
              </div>
            </Header>
          </div>
        </div>

        {!!companyName && (
          <div className="row">
            <div className="col col--x-padded">
              <p className="c-homepage-header__company typo-secondary-heading-03">
                {companyName}
              </p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col col--x-padded">
            <HomepageNavigation />
          </div>
        </div>
      </div>
    </div>
  );
};
