import React from 'react';
import {
  Avatar,
  Chip,
  LinkWrapper,
  Pictogram,
} from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { getConcernsCategoriesString } from '../utils/application';
import { useStoreState } from 'easy-peasy';
import { Link, useParams } from 'react-router-dom';
import { selectorUser } from '../model/User';
import { USER_TYPES_MAP } from '../constants/session';
import { API_HOST } from '../.configs/environment';

const ProfileInfo = ({
  modifier,
  avatar,
  candidateId,
  firstName,
  lastName,
  email,
  phone,
  linkedin,
  concerns,
}) => {
  const { t } = useTranslation();
  const { type: userType } = useStoreState(selectorUser);
  const { jobId } = useParams();

  const isUserManager = userType === USER_TYPES_MAP.MANAGER;

  const mappedItems = [
    {
      icon: 'icn-phone-outline-24',
      children: phone,
    },
    {
      icon: 'icn-mail-24',
      children: email,
    },
    {
      icon: 'icn-LinkedIn-24',
      children: linkedin,
    },
  ];

  const editCandidateLink = `${API_HOST}/dashboard/recruitment/jobs/${jobId}/candidates/${candidateId}/edit`;

  return (
    <div className={`c-profile-info ${modifier}`}>
      <div data-testid="profile-info-header" className="c-profile-info__header">
        <div className="c-profile-info__intro">
          <div className="c-profile-info__image">
            <Avatar avatar={avatar || null} size={96} />
          </div>

          {isUserManager && (
            <div className="c-profile-info__edit">
              <Link
                to={editCandidateLink}
                url={editCandidateLink}
                component={LinkWrapper}
                type="raw"
                shadow={false}
                icon="icn-edit-24"
                iconOnly
                label={t('candidateDetails.intro.editLink')}
                external
                target="_blank"
              />
            </div>
          )}
        </div>
        <p
          data-testid="profile-info-candidate"
          className="c-profile-info__name"
        >
          {firstName} {lastName}
        </p>
      </div>

      {concerns ? (
        <div className="c-explanation c-profile-info__explanation--warning">
          <p className="c-explanatio__text">
            {t('candidate.sections.concerns.intro', { name: firstName })}
          </p>
          <div className="c-explanation__tags">
            <div className="c-explanation__tag">
              <Chip
                modifier="is-relevant"
                size="medium"
                clickable={false}
                leftIcon="icn-warning-outline-24"
              >
                {t('showcase.chip.interestedBut')}...
              </Chip>
            </div>

            <div className="c-explanation__tag">
              <Chip size="medium" clickable={false}>
                {getConcernsCategoriesString(concerns, t)}
              </Chip>
            </div>
          </div>
        </div>
      ) : null}

      {mappedItems && mappedItems.length ? (
        <div data-testid="profile-info-body" className="c-profile-info__body">
          <ul className="c-profile-info__list">
            {mappedItems.map((item, itemIndex) => {
              return (
                <li
                  data-testid={`profile-info-pictogram-${itemIndex}`}
                  key={`pictogram-${itemIndex}`}
                  className={`c-profile-info__list-item ${
                    Array.isArray(item)
                      ? 'c-profile-info__list-item--multiple'
                      : ''
                  }`}
                >
                  {Array.isArray(item) ? (
                    item.map((picto, pictoIndex) => {
                      return picto.children ? (
                        <Pictogram key={`pictogram-${pictoIndex}`} {...picto} />
                      ) : null;
                    })
                  ) : item.children ? (
                    <Pictogram {...item} />
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

ProfileInfo.defaultProps = {
  modifier: '',
};
export default ProfileInfo;
