import React, { useState, useEffect, useCallback } from 'react';
import Horse from './Horse';
import Rider from './Rider';
import Scroller from './Scroller';
import { formatPrice } from '../utils/functions';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { jobStoreSelector } from '../model/Job/Job.selectors';

const Horsejog = ({ id, horse, person, modifier, showLinks }) => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector);
  const jobId = job.id;
  const [showScroller, setShowScroller] = useState(false);
  const candidateAge = horse.yearOfBirth
    ? new Date().getFullYear() - horse.yearOfBirth
    : null;

  const getRiderInfos = horse => {
    let infos = [];
    if (candidateAge) {
      infos.push({
        icon: 'icn-user-24',
        label: `${candidateAge} ${t('candidate.infos.age')}`,
      });
    }
    if (horse.currentAnnualSalary) {
      infos.push({
        icon: 'icn-money-24',
        label: formatPrice(horse.currentAnnualSalary, job.salaryCurrency),
      });
    }
    return infos;
  };

  const handleScroll = useCallback(
    direction => {
      const track = document.querySelector(`#horsejog-track-${id}`);
      if (track) {
        const scroll = 300;
        track.scrollTo({
          top: 0,
          left: Math.max(
            direction === 'prev'
              ? track.scrollLeft - scroll
              : track.scrollLeft + scroll,
          ),
          behavior: 'smooth',
        });
      }
    },
    [id],
  );
  useEffect(() => {
    const track = document.querySelector(`#horsejog-track-${id}`);
    if (track && track.scrollWidth > track.offsetWidth) {
      track.scrollLeft = track.scrollWidth;
      setShowScroller(true);
    }
  }, [id, handleScroll]);

  return (
    <div
      className={`c-horsejog ${
        !!horse.discardedAt ? 'c-horsejog--discarded' : ''
      } ${modifier || ''}`}
    >
      {person ? (
        <div className="c-horsejog__rider c-horsejog__rider">
          <Rider
            candidateId={horse.id}
            jobId={jobId}
            name={t('common.formatting.fullName', {
              firstName: horse.firstName,
              lastName: horse.lastName,
            })}
            infos={getRiderInfos(horse)}
            showLink={showLinks}
          />
        </div>
      ) : null}
      <div data-testid="horsejog-track" className="c-horsejog__track">
        {showScroller ? (
          <div className="c-horsejog__nav c-horsejog__nav--prev">
            <Scroller
              modifier="c-app-scroller--prev"
              icon="icn-short-left-24"
              onScroll={() => handleScroll('prev')}
            />
          </div>
        ) : null}
        <div className="c-horsejog__items" id={`horsejog-track-${id}`}>
          {horse.events &&
            horse.events.map((event, index) => {
              return (
                <div key={`event-${index}`} className="c-horsejog__item">
                  <Horse
                    name={horse.firstName}
                    surname={horse.lastName}
                    candidateId={horse.id}
                    jobId={jobId}
                    showLinks={showLinks}
                    showAdditionalInfo={!horse.discardedAt}
                    status={event.type}
                    {...event}
                  />
                </div>
              );
            })}
        </div>
        {showScroller ? (
          <div className="c-horsejog__nav c-horsejog__nav--next">
            <Scroller
              modifier="c-app-scroller--next"
              onScroll={() => handleScroll('next')}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

Horsejog.defaultProps = {
  id: 'candidate',
  person: true,
  horse: {},
  showLinks: true,
};

export default Horsejog;
