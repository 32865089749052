import { thunk } from 'easy-peasy';
import { utilityThrowError } from '../../../utils/errors';
import type { TJobSharesStore } from './Shares.type';
import type { TJob } from '../Job.type';

export const sharesStore: TJobSharesStore = {
  postShares: thunk(async (_, payload, helpers) => {
    const _actions = helpers.getStoreActions();
    const { jobId, ...rest } = payload;

    _actions.job.setLoading(true);
    _actions.job.setError(false);

    try {
      const res = await _actions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/shares`,
        requestOptions: { method: 'POST', body: JSON.stringify(rest) },
      });

      _actions.job.setData(res as TJob);
      return res;
    } catch (error) {
      _actions.job.setError(true);
      utilityThrowError(error, 'Error to save shares');
      return null;
    }
  }),

  deleteShares: thunk(async (_, payload, helpers) => {
    const { clientId, jobId } = payload;
    const _actions = helpers.getStoreActions();

    try {
      const res = await _actions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/shares/${clientId}`,
        requestOptions: { method: 'DELETE' },
      });

      _actions.job.setData(res as TJob);
    } catch (error) {
      _actions.job.setError(true);
      utilityThrowError(error, 'Error to save shares');
    }
  }),
};
