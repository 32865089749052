import { AppNumbers } from '../AppNumbers';
import { AppDaysInfo } from '../AppDaysInfo';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import { useContext } from 'react';
import { PageStateContext } from '../Providers/PageStateProvider';
import { AppIntroFlow } from '../AppIntroFlow';
import { useTranslation } from 'react-i18next';
import { jobStoreSelector } from '../../model/Job/Job.selectors';
import { JOB_PAGE_STATES } from '../../model/Job/_constants/Job.constants';

export const SearchingHeader = () => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector);
  const pageState = useContext(PageStateContext);

  const isPageStateDone = pageState === JOB_PAGE_STATES.DONE;
  const isAppNumbersVisible = isPageStateDone && job && !!job.statistics;

  const wrapperClassNames = classNames('col col--x-padded small-12', {
    'col--y-padded large-9': isPageStateDone,
    'large-8': !isPageStateDone,
  });

  return (
    <div className={wrapperClassNames}>
      <AppIntroFlow
        title={t('searching.header.title')}
        body={t('searching.header.subtitle', { context: pageState })}
      />

      {isAppNumbersVisible && <AppNumbers />}

      {isPageStateDone ? null : (
        <AppDaysInfo className="c-app-days-info--spaced" />
      )}
    </div>
  );
};
