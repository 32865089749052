import type { FC } from 'react';
import { useState } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import type { TJobShare } from '../../../model/Job/Job.type';
import {
  deleteJobShareSelector,
  jobIdSelector,
} from '../../../model/Job/Job.selectors';
import { useStoreState, useStoreActions } from '../../../store/hooks';

interface TShareLinkModalListItemProps {
  share: TJobShare;
}

export const ShareLinkModalListItem: FC<TShareLinkModalListItemProps> = ({
  share,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'common.formatting' });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const removeShare = useStoreActions(deleteJobShareSelector);
  const jobId = useStoreState(jobIdSelector);

  const onDeleteShareClick = async (): Promise<void> => {
    setIsDeleteLoading(true);

    try {
      if (jobId) {
        await removeShare({ clientId: share.clientId, jobId });
      }
      setIsDeleteLoading(false);
    } catch {
      setIsDeleteLoading(false);
    }
  };

  return (
    <tr className="c-share-link-modal__list-item">
      <td className="c-share-link-modal__list-cell">
        <div className="c-share-link-modal__list-info">
          <p className="c-share-link-modal__list-name">
            {t('fullName', {
              firstName: share.firstName,
              lastName: share.lastName,
            })}
          </p>

          <p className="c-share-link-modal__list-email" title={share.email}>
            {share.email}
          </p>
        </div>

        <Action
          disabled={isDeleteLoading}
          icon="icn-trash-full-24"
          iconOnly
          modifier="c-share-link-modal__list-delete"
          onClick={onDeleteShareClick}
          size="small"
          type="simple-text"
        />
      </td>
    </tr>
  );
};
