import type { FC } from 'react';
import React from 'react';
import { ShareLinkModalListItem } from './ShareLinkModalListItem';
import { jobSharesSelector } from '../../../model/Job/Job.selectors';
import type { TJobShare } from '../../../model/Job/Job.type';
import { useStoreState } from '../../../store/hooks';

export const ShareLinkModalList: FC = () => {
  const shares: TJobShare[] = useStoreState(jobSharesSelector);

  return (
    <div className="c-share-link-modal__list">
      <table
        className="c-share-link-modal__list-table"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          {shares.map(share => (
            <ShareLinkModalListItem share={share} key={share.clientId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
