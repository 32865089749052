import { useTranslation } from 'react-i18next';

export const FooterRightBlock: React.FC = () => {
  const { t } = useTranslation();
  const footerInformations = t<string, string[]>('footer.informations', {
    returnObjects: true,
  });

  return (
    <div>
      {footerInformations.map(information => (
        <p key={information} className="c-footer__paragraph">
          {information}
        </p>
      ))}
    </div>
  );
};
