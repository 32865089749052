import React from 'react';
import { Hero } from './Hero';

const Masthead = ({
  full,
  modifier,
  job,
  title,
  isBackToHomepageAvailable,
  children,
}) => {
  return (
    <div
      className={`c-app-masthead ${
        full ? 'c-app-masthead--primary-single-tint' : ''
      } ${modifier}`}
    >
      <div className="container container--full">
        <div className="row">
          <div className="col c-app-masthead__background">
            <div className="container">
              <div className="row">
                <div className="col col--x-padded small-12 large-9 c-app-masthead__primary">
                  <Hero
                    isBackToHomepageAvailable={isBackToHomepageAvailable}
                    title={title ? title : job.title}
                  />
                  {children && children.left ? children.left : null}
                </div>
                {!full ? (
                  <div className="col col--x-padded small-12 large-3 c-app-masthead__secondary">
                    {children && children.right ? children.right : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Masthead.defaultProps = {
  modifier: '',
  full: false,
};

export default Masthead;
