import type { ObjectValues } from '../../../utils/types/ObjectValues';
import type { JOB_PROGRESS_SEGMENTS } from './_constants/JobProgressSwitch.constants';
import type React from 'react';

export type TJobProgressSegment = ObjectValues<typeof JOB_PROGRESS_SEGMENTS>;

export interface TJobProgressSegmentLoaderProps {
  loaderType: TJobProgressSegment;
  children: React.ReactNode;
}

export const JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES = {
  JOBS: 'jobs',
  CONTRACTS: 'contracts',
} as const;

export type TJobProgressSwitchSegmentEntity = ObjectValues<
  typeof JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES
>;

export interface TJobProgressSwitchParams {
  entityId?: string;
  entityType: TJobProgressSwitchSegmentEntity;
  segment?: string;
}
