import Video from '../Video';
import { APP_BASE_PATH } from '../../constants/navigation';

export const ClosingSidebar = ({ hasClosings }) => {
  const noClosingsImageSrc = `${APP_BASE_PATH}/assets/images/cube.png`;

  return (
    <div className="o-sidebar o-sidebar--y-center">
      {hasClosings ? (
        <Video video="cubes" sources={['webm']} />
      ) : (
        <img src={noClosingsImageSrc} alt="cube" />
      )}
    </div>
  );
};
