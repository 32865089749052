import type { FC, ReactNode } from 'react';
import { Logo, SpriteSVG } from '@reverse-hr/pattern-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import { APP_BASE_PATH } from '../../constants/navigation';
import { IS_THEME_AKI } from '../../.configs/environment';

interface TErrorScreenProps {
  children?: ReactNode;
  className?: string;
  title?: string;
}

export const ErrorScreen: FC<TErrorScreenProps> = ({
  children,
  className,
  title,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'general.error',
  });

  const wrapperClassNames = classNames('c-error-screen', className);
  const headerLogo = IS_THEME_AKI
    ? `${APP_BASE_PATH}/assets/images/logo/aaron-king-logo.png`
    : '';

  return (
    <main className={wrapperClassNames}>
      <header className="c-error-screen__header">
        <Logo modifier="c-error-screen__logo" image={headerLogo} />
      </header>

      <div className="c-error-screen__wrapper">
        <h1 className="c-error-screen__title">{title ?? t('title')}</h1>
        <div className="c-error-screen__content">{children}</div>
      </div>

      <Footer
        logo={
          IS_THEME_AKI
            ? `${APP_BASE_PATH}/assets/images/logo/aaron-king-logo.png`
            : ''
        }
        payoff={IS_THEME_AKI ? 'aki-payoff' : 'reverse-payoff'}
      />
      <SpriteSVG />
    </main>
  );
};
