import { useMemo, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { showcaseTableSelector } from '../../../../store/selectors/showcase-table';
import tableDataFactory from '../helpers/table-data-factory';
import TableRows from '../constants/table-rows';
import useDebounce from '../../../../utils/hooks/use-debounce';
import { getApplicationsSortedByState } from '../helpers/sort-candidates';
import { candidatesSelector } from '../../../../model/Candidate/Candidate.selectors';

const useShowcase = () => {
  const {
    rows,
    columns,
    areDiscardedVisible,
    areInterestedButVisible,
    isEditedByUser,
  } = useStoreState(showcaseTableSelector);
  const { results: candidates } = useStoreState(candidatesSelector);
  const { isConfigurationLoaded } = useStoreState(showcaseTableSelector);
  const { saveTableStatus, setIsEditedByUser } = useStoreActions(
    showcaseTableSelector,
  );
  const defaultRowsOrder = TableRows.map(row => row.id);

  const autoSaveGuard = useDebounce(
    JSON.stringify({
      rows,
      columns,
      areDiscardedVisible,
      areInterestedButVisible,
    }),
    3000,
  );

  const [areColumnsHidden, setAreColumnsHidden] = useState(false);
  const [areRowsHidden, setAreRowsHidden] = useState(false);
  const [areRowsRearranged, setAreRowsRearranged] = useState(false);
  const [areColumnsRearranged, setAreColumnsRiarranged] = useState(false);

  const defaultApplicationsOrder = useMemo(() => {
    return getApplicationsSortedByState(candidates)?.map(application =>
      String(application.id),
    );
  }, [candidates]);

  const filteredRows = useMemo(() => {
    if (!candidates?.length) return [];

    return rows.reduce((visibleRows, row) => {
      if (!row.isVisible) return visibleRows;

      return [
        ...visibleRows,
        {
          ...row,
          cells: tableDataFactory(row, candidates),
        },
      ];
    }, []);
  }, [rows, candidates]);

  const filteredColumns = useMemo(() => {
    return columns.reduce(
      (visibleColumns, { isDiscarded, isInterestedBut, isVisible, id }) => {
        if (!areDiscardedVisible && isDiscarded) return visibleColumns;
        if (!areInterestedButVisible && isInterestedBut) return visibleColumns;
        if (!isVisible) return visibleColumns;

        const parsedId = String(id);

        return [
          ...visibleColumns,
          { id: parsedId, Header: parsedId, accessor: parsedId },
        ];
      },
      [
        {
          id: 'initial-column',
          Header: 'initial-column',
          accessor: 'initial-column',
        },
      ],
    );
  }, [columns, areDiscardedVisible, areInterestedButVisible]);

  const isRestoreViewButtonVisible = useMemo(() => {
    return (
      !!areColumnsHidden ||
      !!areRowsHidden ||
      areDiscardedVisible ||
      areInterestedButVisible ||
      areRowsRearranged ||
      areColumnsRearranged
    );
  }, [
    areColumnsHidden,
    areRowsHidden,
    areDiscardedVisible,
    areInterestedButVisible,
    areRowsRearranged,
    areColumnsRearranged,
  ]);

  useEffect(() => {
    return () => {
      setIsEditedByUser(false);
    };
  }, []);

  useEffect(() => {
    const currentRowsOrder = rows.map(row => row.id);
    setAreRowsHidden(rows.find(row => !row.isVisible));
    setAreRowsRearranged(
      JSON.stringify(defaultRowsOrder) !== JSON.stringify(currentRowsOrder),
    );
  }, [rows]);

  useEffect(() => {
    const currentColumnsOrder = columns.map(column => column.id);
    setAreColumnsHidden(!!columns.find(column => !column.isVisible));
    setAreColumnsRiarranged(
      JSON.stringify(defaultApplicationsOrder) !==
        JSON.stringify(currentColumnsOrder),
    );
  }, [columns]);

  useEffect(() => {
    if (isEditedByUser && isConfigurationLoaded) {
      saveTableStatus();
    }
  }, [autoSaveGuard]);

  return { filteredRows, filteredColumns, isRestoreViewButtonVisible };
};

export default useShowcase;
