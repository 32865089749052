import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  dealEntityStoreActionsSelector,
  dealEntityStoreStateSelector,
} from '../../../model/DealEntity/DealEntity.selectors';
import { JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES } from '../JobProgressSwitch/JobProgressSwitch.types';
import { APP_BASE_PATH } from '../../../constants/navigation';
import { LoaderFullPage } from '../../../loaders/LoaderFullPage';
import type { TDealRedirectManagerParams } from './DealRedirectManager.types';
import {
  CANDIDATES_SEGMENT,
  DEAL_CANDIDATE_SEGMENTS,
  DEAL_SUCCESS_FEE_SEGMENT,
  SUCCESS_FEE_SEGMENT,
} from '../../../constants/navigation.constants';

export const DealRedirectManager: FC = () => {
  const { dealId, segment, applicationId } =
    useParams<TDealRedirectManagerParams>();

  const { getDealEntity } = useStoreActions(dealEntityStoreActionsSelector);
  const dealEntity = useStoreState(dealEntityStoreStateSelector);

  const onDealIdChange = async (): Promise<void> => {
    if (!dealId) {
      return;
    }

    await getDealEntity({ dealId, applicationId });
  };

  const onEntityChange = (): void => {
    if (!dealEntity) {
      return;
    }

    const { jobId, contractId, candidateId } = dealEntity;

    const entityType = jobId
      ? JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES.JOBS
      : JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES.CONTRACTS;

    const entityId = jobId ?? contractId;
    const routeSegment = getRouteSegment(segment);

    const successFeeSegment =
      segment === DEAL_SUCCESS_FEE_SEGMENT ? SUCCESS_FEE_SEGMENT : '';

    const redirectUrl = [
      APP_BASE_PATH,
      entityType,
      entityId,
      routeSegment,
      candidateId,
      successFeeSegment,
    ]
      .filter(redirectUrlSegment => Boolean(redirectUrlSegment))
      .join('/');

    window.location.assign(`${redirectUrl}`);
  };

  useEffect(() => {
    onDealIdChange();
  }, [dealId]);

  useEffect(() => {
    onEntityChange();
  }, [dealEntity]);

  return <LoaderFullPage />;
};

const getRouteSegment = (segment: string | undefined): string => {
  if (!segment) {
    return '';
  }

  if (DEAL_CANDIDATE_SEGMENTS.includes(segment)) {
    return CANDIDATES_SEGMENT;
  }

  return segment;
};
