import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link, useParams } from 'react-router-dom';

import SecondaryLayout from '../layouts/SecondaryLayout';
import ProfileInfo from '../components/ProfileInfo';
import Horsejog from '../components/Horsejog';
import Modal from '../components/Modal';
import Chat from '../components/Chat/Chat';

import Masthead from '../components/Masthead';
import { LinkWrapper } from '@reverse-hr/pattern-library';

import CandidateDetailLoader from '../loaders/CandidateDetail';

import { candidateDetail } from '../utils/default-values';

import useParsedCandidateDetails from '../utils/hooks/useParsedCandidateDetails';
import { Skills } from '../components/candidate-detail/skills';
import { ShareLinkModal } from '../components/ShareLinkModal';
import { selectorUser } from '../model/User';
import { MODAL_IDS_MAP } from '../constants/modals';
import { utilityThrowError } from '../utils/errors';
import { ApplicationFeedback } from '../components/ApplicationFeedback';
import { GTM } from '../gtm';
import { ScoutReport } from '../components/ScoutReport';
import { CandidateDossier } from '../components/CandidateDossier';
import { NegativeFeedbackModal } from '../components/NegativeFeedbackModal/NegativeFeedbackModal';
import {
  jobCompanyNameSelector,
  jobStoreSelector,
} from '../model/Job/Job.selectors';
import {
  candidateDetailLoadingSelector,
  candidateFeedbacksSelector,
  candidateSelector,
  feedbacksLoadingSelector,
  getCandidateSelector,
  getCurriculumSelector,
  postFeedbackMessageSelector,
  putClientFeedbackSelector,
} from '../model/Candidate/Candidate.selectors';

const CandidateDetail = () => {
  const { candidateId } = useParams();
  const { hero, sections } = candidateDetail();
  const [manager, setManager] = useState(null);
  const [chatCollapsable, setChatCollapsable] = useState(false);

  // STATE
  const job = useStoreState(jobStoreSelector);
  const companyName = useStoreState(jobCompanyNameSelector);
  const user = useStoreState(selectorUser);
  const candidateDetailLoading = useStoreState(candidateDetailLoadingSelector);
  const candidateError = useStoreState(
    state => state.candidates.candidateError,
  );
  const candidate = useStoreState(candidateSelector);
  const feedbacks = useStoreState(candidateFeedbacksSelector);
  const feedbacksLoading = useStoreState(feedbacksLoadingSelector);
  const isModalOpened = useStoreState(state => state.isModalOpened);
  const modalId = useStoreState(state => state.modalId);

  const {
    candidateFullName,
    candidateProfileInfo,
    candidateHorse,
    candidateSummaryDetails,
    customerInterest,
    isCandidateInterviewed,
  } = useParsedCandidateDetails(candidate, candidateDetailLoading);

  const isScoutReportAvailable = candidate
    ? Boolean(candidate.technicalReport)
    : false;

  const putClientFeedback = useStoreActions(putClientFeedbackSelector);
  const postFeedbackMessage = useStoreActions(postFeedbackMessageSelector);
  const getCandidate = useStoreActions(getCandidateSelector);
  const getCurriculum = useStoreActions(getCurriculumSelector);

  const setModalOpened = useStoreActions(actions => actions.setModalOpened);
  const setModalId = useStoreActions(actions => actions.setModalId);

  const isChatModalVisible = isModalOpened && modalId === MODAL_IDS_MAP.CHAT;
  const isShareModalVisible = isModalOpened && modalId === MODAL_IDS_MAP.SHARE;
  const isNegativeFeedbackModalVisible =
    isModalOpened && modalId === MODAL_IDS_MAP.NEGATIVE_FEEDBACK;
  const isMobileChatToggleVisible =
    manager && candidate && candidate.isLikedByClient !== null;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (job?.id && candidateId) {
      getCandidate({ candidateId, jobId: job?.id });
      getCurriculum({ candidateId, jobId: job?.id });
    }
  }, [candidateId, getCandidate, getCurriculum, job?.id]);

  useEffect(() => {
    if (job) {
      setManager(job?.recruitmentConsultant ?? job?.salesConsultant);
    }
  }, [job?.recruitmentConsultant, job?.salesConsultant]);

  useEffect(() => {
    const handlePrintEvents = () => {
      const accordions = document.querySelectorAll('.c-accordion__label');
      if (accordions) {
        Array.from(accordions).forEach(acc => acc.click());
      }
    };
    window.addEventListener('beforeprint', handlePrintEvents);
    window.addEventListener('afterprint', handlePrintEvents);

    return () => {
      window.removeEventListener('beforeprint', handlePrintEvents);
      window.removeEventListener('afterprint', handlePrintEvents);
    };
  });

  const getMastheadClassModifier = isCustomerInterested => {
    if (candidate?.discardedAt) {
      return 'waiting';
    }

    if (isCandidateInterviewed) {
      return 'positive';
    }

    if (isCustomerInterested === undefined || isCustomerInterested === null) {
      return 'waiting';
    }

    return isCustomerInterested ? 'positive' : 'negative';
  };

  const handleAnalytics = interest => {
    const feedback = interest ? 'Promosso' : 'Bocciato';

    GTM.trackGTMEvent({
      action: EVENT_ACTION_FEEDBACK_UPDATE,
      label: `${companyName} - ${job.id} - ${job.title} - ${feedback}`,
    });

    GTM.trackGA4Event(
      EVENT_ACTION_FEEDBACK_UPDATE,
      companyName,
      `${job.id} - ${job.title} - ${feedback}`,
    );
  };

  const toggleChatModalStatus = isOpened => {
    const updatedModalId = isOpened ? MODAL_IDS_MAP.CHAT : null;

    setModalOpened(isOpened);
    setModalId(updatedModalId);
    setChatCollapsable(isOpened);
  };

  const openNegativeFeedbackModal = () => {
    setModalOpened(true);
    setModalId(MODAL_IDS_MAP.NEGATIVE_FEEDBACK);
  };

  const handleSendMessage = async message => {
    try {
      await postFeedbackMessage({
        jobId: job.id,
        candidateId,
        content: message,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const handleOnInterestFeedback = async (
    isCustomerInterested,
    customerInterestNotes,
  ) => {
    if (isCustomerInterested === false && !customerInterestNotes) {
      return openNegativeFeedbackModal();
    }
    const clientFeedbackPayload = {
      jobId: job.id,
      candidateId,
      notes: customerInterestNotes,
    };

    await putClientFeedback(clientFeedbackPayload);
    handleAnalytics(isCustomerInterested);
    await getCandidate({ candidateId, jobId: job.id });
  };

  const onFeedbackUpdate = async interested => {
    try {
      await handleOnInterestFeedback(interested);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleCandidateAnalytics = (action, type) => {
    GTM.trackGTMEvent({
      action,
      label: `${job.id} - ${candidateId} - ${type}`,
    });

    GTM.trackGA4Event(
      action,
      companyName,
      `${job.id} - ${candidateId} - ${type}`,
    );
  };

  const getChatLabels = device => {
    if (device === 'desktop') {
      let msgs = sections.chat(manager.first_name, user.firstName);
      if (candidate && candidate?.isLikedByClient !== null) {
        msgs.default_message = msgs[`default_message_${customerInterest}`];
      }
      return msgs;
    } else {
      const default_message =
        candidate?.feedbacks && candidate?.feedbacks.length
          ? null
          : candidate?.isLikedByClient === true
          ? sections.chat().default_message_positive
          : sections.chat().default_message_negative;
      return {
        default_message,
      };
    }
  };

  useEffect(() => {
    if (!!candidateError) {
      utilityThrowError(candidateError, 'Candidate page failed to render');
    }
  }, [candidateError]);

  return (
    <SecondaryLayout>
      {candidateDetailLoading || !job ? (
        <div>
          <CandidateDetailLoader />
        </div>
      ) : (
        <div data-testid="candidate-page">
          <Masthead
            modifier={`c-app-masthead--candidate-${getMastheadClassModifier(
              customerInterest,
            )}`}
            job={job}
          >
            {{
              left: (
                <div className="c-app-masthead__back">
                  <div className="c-back">
                    <Link
                      to={`/jobs/${job.id}/interviewing`}
                      modifier="c-back__anchor"
                      component={LinkWrapper}
                      type="simple-text"
                      label={hero.back_label}
                      icon="icn-chevron-big-left-24"
                      iconPosition="left"
                    />
                  </div>
                </div>
              ),
              right: (
                <ApplicationFeedback
                  candidateFullName={candidateFullName}
                  isCustomerInterested={customerInterest}
                  isCandidateDiscarded={candidate?.discardedAt}
                  isCandidateInterviewed={isCandidateInterviewed}
                  onFeedbackUpdate={onFeedbackUpdate}
                  manager={manager}
                />
              ),
            }}
          </Masthead>

          {candidateHorse ? (
            <div className="container container--full">
              <div className="row">
                <div data-testid="candidate-page-horsejog" className="col">
                  <Horsejog horse={candidateHorse} showLinks={false} />
                </div>
              </div>
            </div>
          ) : null}

          <div className="container">
            <div className="row">
              <div
                data-testid="candidate-page-details"
                className="col col--x-padded small-12 large-6 u-mt-space-64"
              >
                <ProfileInfo {...candidateProfileInfo} />
              </div>
              <div className="col col--x-padded small-12 large-6 u-hidden-large-down u-mt-space-64">
                <Chat
                  id="bet"
                  title={manager ? getChatLabels('desktop').feedback_title : ''}
                  messages={feedbacks}
                  labels={manager ? { ...getChatLabels('desktop') } : {}}
                  manager={manager}
                  onSend={handleSendMessage}
                  loading={feedbacksLoading}
                  onToggle={() => toggleChatModalStatus(true)}
                />
              </div>
            </div>
          </div>

          <Skills
            candidateSkills={candidate?.skills}
            isScoutReportAvailable={isScoutReportAvailable}
          />

          {isScoutReportAvailable && (
            <ScoutReport scoutReport={candidate?.technicalReport} />
          )}

          <CandidateDossier
            candidateSummary={candidate?.summary}
            candidateSummaryDetails={candidateSummaryDetails}
          />

          <Chat
            id="mobile"
            modifier="c-app-chat--mini-bottom"
            title={sections.chat(manager?.first_name).name_title}
            messages={[]}
            manager={manager}
            labels={getChatLabels('mobile')}
            collapsable
            showInput={false}
            onToggle={() => toggleChatModalStatus(true)}
          />
        </div>
      )}

      <Modal visible={isChatModalVisible} modifier="c-app-modal--chat">
        <Chat
          id="modal"
          title={
            sections.chat(manager?.first_name, user?.firstName).feedback_title
          }
          messages={feedbacks}
          manager={manager}
          labels={sections.chat(manager?.first_name, user?.firstName)}
          onSend={handleSendMessage}
          loading={feedbacksLoading}
          closable={chatCollapsable}
          onToggle={() => toggleChatModalStatus(false)}
        />
      </Modal>

      <Modal visible={isShareModalVisible}>
        <ShareLinkModal />
      </Modal>

      <Modal
        visible={isNegativeFeedbackModalVisible}
        modifier="c-app-modal--negative-feedback"
      >
        <NegativeFeedbackModal
          onSendNegativeFeedback={async notes =>
            await handleOnInterestFeedback(false, notes)
          }
        />
      </Modal>
    </SecondaryLayout>
  );
};

export default CandidateDetail;

const EVENT_ACTION_FEEDBACK_UPDATE = 'promossobocciato';
