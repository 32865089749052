import showcase from './showcase';
import { COMMON_EN } from './common';
import { SEARCHING_EN } from './searching';
import { INTERVIEWING_EN } from './interviewing';
import { CLOSING_EN } from './closing';
import { SUCCESS_FEE_EN } from './success-fee';
import { HOMEPAGE_EN } from './homepage';
import { CANDIDATE_DETAILS_EN } from './candidate-details';
import { ERRORS_EN } from './errors';
import * as LOGIN_EN from './login.json';

const EN = {
  translation: {
    header: {
      welcome: 'Welcome',
      share: 'Share',
    },
    hero: {
      backToHomepage: 'All your jobs',
      backToHomepage_headHunter: "All the client's jobs",
      prospect: {
        title: 'Success fee calculation',
      },
      steps: {
        profiling: {
          title: 'Profile',
          title_no_miro: 'Contacts',
          description: 'Start',
        },
        searching: {
          title: 'Research',
          description: 'Day 1-10',
        },
        interviewing: {
          title: 'Interviews',
          description: 'Day 11-20',
        },
        closing: {
          title: 'Closing',
          description: 'End',
        },
      },
      helper: {
        profiling: {
          'to-do':
            'Hi. On these pages you <strong>can see</strong> every step of the activities we carry out for you, updated in real time.',
          'to-do_withName':
            'Hi {{ name }}. On these pages you <strong>can see</strong> every step of the activities we carry out for you, updated in real time.',
          doing:
            'Hi. On these pages you <strong>can see</strong> every step of the activities we carry out for you, updated in real time.',
          doing_withName:
            'Hi {{ name }}. On these pages you <strong>can see</strong> every step of the activities we carry out for you, updated in real time.',
          done: 'Hi. On these pages you <strong>can see</strong> every step of the activities we carry out for you, updated in real time.',
          done_withName:
            'Hi {{ name }}. On these pages you <strong>can see</strong> every step of the activities we carry out for you, updated in real time.',
        },
        searching: {
          'to-do':
            'During the search, until the data is available, <strong>you will see the activities I carry out for you.</strong>',
          doing:
            'During the search, until the data is available, <strong>you will see the activities I carry out for you.</strong>',
          done: 'Search completed! Below you can find the <strong>numbers</strong> and the <strong>JRA</strong>, our final analysis.',
        },
        interviewing: {
          'to-do':
            'At this stage <strong>I will show you the candidates and we will need to gather timely feedback</strong>.',
          doing:
            'We are in the middle of the selection process. Remember, <strong>the quicker we are, the more likely we are to recruit.</strong>',
          done: 'We are in the middle of the selection process. Remember, <strong>the quicker we are, the more likely we are to recruit.</strong>',
        },
        closing: {
          'to-do':
            'It is the final phase: the most delicate one. <strong>Negotiation, resignation and onboarding</strong>.',
          doing:
            'It is the final phase: the most delicate one. <strong>Negotiation, resignation and onboarding</strong>.',
          done: 'It is the final phase: the most delicate one. <strong>Negotiation, resignation and onboarding</strong>.',
        },
      },
    },
    profile: {
      header: {
        title: 'Profile',
        subtitle:
          "On this page you will find an always updated summary of the candidate profile, your company's profile and our service.",
      },
      cards: {
        action: {
          label: 'See the details',
        },
        1: {
          title: "The candidate's profile",
        },
        2: {
          title:
            "That's how we'll tell the story of your company to the candidate",
        },
      },
      contract: {
        title: "Here you'll find this job's contract",
        download: 'Download the contract',
      },
      references: {
        title: 'Your contacts',
        blank: {
          title: 'To assign to',
          subtitle: 'Head Hunter',
          text: 'To choose based on the job',
        },
      },
      mirror: {
        body: 'A new way of recruiting: flexible, digital and specialised.',
        link: 'Discover Collaborative Recruitment',
      },
    },
    general: {
      error: {
        title: 'An error has occurred.',
        subtitle:
          "Please refresh the page. If it's not resolved, try again later.",
        button: 'Go back to home',
        hash: {
          title: 'This link is no longer valid.',
          subtitle:
            'You should have received the <strong>right link in one of our emails</strong>.',
          disclaimer:
            "Can't find it? <strong>Please get in touch with your contact persons</strong>",
        },
        unauthorized: {
          title: 'Wrong link.',
          subtitle:
            'You should have received the <strong>right link</strong> in one <strong>of our emails</strong>, shortly <strong>after having signed the contract.</strong>',
          disclaimer:
            "Can't find it? <strong>Please get in touch with your contact persons</strong>",
        },
        unauthorizedHomepage: {
          title: 'You are not authorized to view this page.',
          subtitle: '',
          disclaimer: '',
        },
      },
      document_title: {
        profiling: 'Profile',
        searching: 'Research',
        interviewing: 'Interviews',
        closing: 'Closing',
        showcase: 'Showcase',
      },
      date_on: 'on',
      date_hour: 'at',
    },
    candidate: {
      hero: {
        title: 'you are looking for:',
        back_label: 'Back to the job',
      },
      infos: {
        age: 'years',
        benefits: 'Current benefits',
        bonuses: 'Current bonus',
      },
      sections: {
        horse: {
          title: 'Current status',
        },
        bet: {
          open_chat: 'Chat with <strong>{{ name }}</strong>',
        },
        curriculum: {
          title: 'CV',
          screening: 'Screening ranking',
          button: 'Download the CV',
        },
        summary: {
          otherSelectionsTitle: 'Other selection processes',
        },
        chat: {
          hour: ' at ',
          feedback_title: 'Write your feedback here',
          name_title: 'Chat with <strong>{{ name }}</strong>',
          open: 'more',
          close: 'close',
          fullscreen: 'Fullscreen',
          default_message:
            'Hello, if you have something to communicate feel free to use the chat.',
          default_message_withName:
            'Hello {{ name }}, if you have something to communicate feel free to use the chat.',
          default_message_positive:
            'Hello, why <strong>is he/she interesting</strong>? Would you like to deepen some specific aspects during the interview?',
          default_message_positive_withName:
            'Hello {{ name }}, why <strong>is he/she interesting</strong>? Would you like to deepen some specific aspects during the interview?',
          default_message_negative:
            'Hello, tell me your reasons so I can refine the search for candidates.',
          default_message_negative_withName:
            'Hello {{ name }}, tell me your reasons so I can refine the search for candidates.',
        },
        concerns: {
          intro:
            '{{ name }} has a really interesting profile, but she does not completely fulfill the requirements.',
        },
      },
    },
    footer: {
      company: '© Reverse SpA',
      headquarter: 'Registered Office: Piazza Sigmund Freud 1, 20154 Milano',
      informations: [
        'VAT Number: 09714690964',
        'PEC: reversespa@pec.it',
        'Commercial registry: MI - 2109818',
        'Paid up share capital: 50.000€',
      ],
      menu: {
        contact: {
          label: 'Contact us',
          slug: 'contact-us',
        },
        legal: {
          label: 'Legal',
          slug: 'legal',
        },
        cookies: {
          label: 'Cookies',
          url: 'https://www.iubenda.com/privacy-policy/19612468/cookie-policy',
        },
      },
    },
    modal: {
      share: {
        title: 'Send invite for real-time updates on the hiring progress',
        inputs: {
          first_name: {
            label: 'Name',
          },
          last_name: {
            label: 'Surname',
          },
          email: {
            label: 'Email',
            hint: 'This e-mail adress does not have the same domain you signed up with. Do you want to proceed anyway?',
          },
          lang: {
            label: 'Language',
          },
        },
        lang_options: {
          it: 'Italian',
          en: 'English',
          de: 'German',
          fr: 'French',
          es: 'Spanish',
        },
        button: 'Send invite',
        shared_with: 'Shared with',
        alert: {
          positive: 'Email invitation has been sent',
          negative:
            'Something went wrong. Please try again later or contact us.',
        },
      },
    },
    validation: {
      scout_approved: 'Validated by Scout',
      scout_required: 'Requires Scout validation',
      required: 'This field is required',
      email: 'This email address is not valid',
    },
    language_switcher: {
      it: 'IT',
      en: 'EN',
      de: 'DE',
      fr: 'FR',
      es: 'ES',
    },
    showcase,
    candidateDetails: CANDIDATE_DETAILS_EN,
    common: COMMON_EN,
    searching: SEARCHING_EN,
    interviewing: INTERVIEWING_EN,
    closing: CLOSING_EN,
    successFee: SUCCESS_FEE_EN,
    homepage: HOMEPAGE_EN,
    login: LOGIN_EN,
    errors: ERRORS_EN,
  },
};

export default EN;
