import Sentry from '../../sentry';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { I18N_LOCALE_LIST } from '../../constants/locales';
import { selectorRootActions } from '../../model';
import { JOB_PROGRESS_SWITCH_ENTITY_ID_REGEXP } from '../../components/navigation/JobProgressSwitch/_constants/JobProgressSwitch.constants';
import { JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES } from '../../components/navigation/JobProgressSwitch/JobProgressSwitch.types';

export const getEntityIdFromPathname = pathname => {
  const entityMatches = pathname.match(JOB_PROGRESS_SWITCH_ENTITY_ID_REGEXP);

  if (!entityMatches || entityMatches.length === 0) {
    return {};
  }

  const entityType = entityMatches[1];
  const entityId = entityMatches[2];

  return {
    contractId:
      entityType === JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES.CONTRACTS
        ? entityId
        : null,
    jobId:
      entityType === JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES.JOBS
        ? entityId
        : null,
  };
};

const useInitializeApp = () => {
  const history = useHistory();
  const location = useLocation();
  const initialized = useStoreState(state => state.initialized);
  const initialize = useStoreActions(state => state.initialize);
  const { changeLanguage } = useStoreActions(selectorRootActions);

  const queryParams = new URL(window.location).searchParams;

  const getShareModalAndLanguageInfoFromUrl = () => {
    const language = queryParams.get('language') || null;
    const isShareModalOpen = queryParams.get('share') || false;

    return {
      language,
      isShareModalOpen,
    };
  };

  const setSentryContext = () => {
    const cookies = document.cookie;
    const isEnvDevelopment = process.env.NODE_ENV === 'development';
    const hasSessionCookie = cookies.includes('PHPSESSID');

    if (isEnvDevelopment || hasSessionCookie) return;

    Sentry.setContext('user', {
      info: 'User is not logged, it could crash on some authenticated routes',
    });
  };

  const setLanguageFromSearch = language => {
    if (!language) {
      return;
    }

    queryParams.delete('language');
    history.replace({ ...location, search: queryParams.toString() });

    if (I18N_LOCALE_LIST.includes(language)) {
      changeLanguage(language);
    }
  };

  const resetShareParam = isShareModalOpen => {
    if (!isShareModalOpen) {
      return;
    }

    queryParams.delete('share');
    history.replace({ ...location, search: queryParams.toString() });
  };

  const executeInitializationSteps = async () => {
    const { language, isShareModalOpen } =
      getShareModalAndLanguageInfoFromUrl();
    const { contractId, jobId } = getEntityIdFromPathname(location.pathname);

    setLanguageFromSearch(language);
    resetShareParam(isShareModalOpen);
    setSentryContext();

    initialize({ isShareModalOpen, contractId, jobId });
  };

  useEffect(() => {
    if (!initialized) {
      executeInitializationSteps();
    }
  }, [initialize, initialized]);
};

export default useInitializeApp;
