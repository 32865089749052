export const COMMON_DE = {
  formatting: {
    date: {
      daysAgo: 'vor {{ count }} Tagen',
      daysAgo_zero: 'Heute',
      daysAgo_one: 'Gestern',

      month_1: 'Januar',
      month_2: 'Februar',
      month_3: 'März',
      month_4: 'April',
      month_5: 'Mai',
      month_6: 'Juni',
      month_7: 'Juli',
      month_8: 'August',
      month_9: 'September',
      month_10: 'Oktober',
      month_11: 'November',
      month_12: 'Dezember',

      dateFormat_pretty:
        '{{- date, datetime(format: dd)}} $t(common.formatting.date.month_{{- date, datetime(format: M) }}) {{- date, datetime(format: yyyy)}}',
    },
  },

  downloads: {
    candidateDossierFallback:
      'Candidate Dossier - {{ firstName }} {{ lastName }}',
    candidateCommitmentLetterFallback:
      'Commitment Letter - {{ firstName }} {{ lastName }}',
    candidateResignationLetterFallback:
      'Resignation Letter - {{ firstName }} {{ lastName }}',
    candidateTechnicalReportFallback:
      'Candidate Technical Report - {{ firstName }} {{ lastName }}',
    signedContractFallback: 'Contract - {{ companyName }}',
  },
};
