import { useMemo } from 'react';
import { jobStoreSelector } from '../../../model/Job/Job.selectors';
import { useStoreState } from '../../../store/hooks';
import { getStacks, sortStacksKeys, sortTasksByDate } from '../_helpers';
import type { StacksByDate, TStackedActivity } from '../_helpers';

export const useActivitiesStacks = (): {
  stacks: StacksByDate;
  flattenedStacks: TStackedActivity[];
} => {
  const job = useStoreState(jobStoreSelector);
  const activities = job?.statistics.activities;

  return useMemo(() => {
    const sortedActivities = sortTasksByDate(activities ?? []);
    const stacks = getStacks(sortedActivities);
    const sortedStacksKeys = sortStacksKeys(stacks);

    return {
      stacks,
      sortedStacksKeys,
      flattenedStacks: Object.values(stacks).flat(),
    };
  }, [activities]);
};
