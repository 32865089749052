import React from 'react';
import { useStoreState } from 'easy-peasy';

import TheHeader from '../components/TheHeader';
import Footer from '../components/Footer/Footer';

import { header } from '../utils/default-values';
import useShareModal from '../utils/hooks/use-share-modal';
import { selectorSession } from '../model/session';
import { LoaderFullPage } from '../loaders/LoaderFullPage';
import { APP_BASE_PATH } from '../constants/navigation';
import { IS_THEME_AKI } from '../.configs/environment';

const SecondaryLayout = ({ children, customClasses = '' }) => {
  const headerLabels = header();
  const initialized = useStoreState(state => state.initialized);
  const { loading } = useStoreState(selectorSession);

  const { handleShareButtonClick } = useShareModal();

  if (!initialized || loading) {
    return <LoaderFullPage />;
  }

  return (
    <main data-testid="secondary-layout">
      <div className="container">
        <div className="row">
          <div className="col col--x-padded u-py-space-16">
            <TheHeader labels={headerLabels} onClick={handleShareButtonClick} />
          </div>
        </div>
      </div>
      <div className={`c-secondary-layout ${customClasses}`}>{children}</div>
      <Footer
        logo={
          IS_THEME_AKI
            ? `${APP_BASE_PATH}/assets/images/logo/aaron-king-logo.png`
            : undefined
        }
        payoff={IS_THEME_AKI ? 'aki-payoff' : undefined}
      />
    </main>
  );
};

export default SecondaryLayout;
