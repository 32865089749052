export const COMMON_IT = {
  formatting: {
    date: {
      daysAgo: '{{ count }} giorni fa',
      daysAgo_zero: 'Oggi',
      daysAgo_one: 'Ieri',

      month_1: 'gennaio',
      month_2: 'febbraio',
      month_3: 'marzo',
      month_4: 'aprile',
      month_5: 'maggio',
      month_6: 'giugno',
      month_7: 'luglio',
      month_8: 'agosto',
      month_9: 'settembre',
      month_10: 'ottobre',
      month_11: 'novembre',
      month_12: 'dicembre',

      dateFormat: '{{- date, datetime(format: dd/MM/yyyy) }}',
      dateFormat_time: '{{- date, datetime(format: HH:mm) }}',
      dateFormat_full: '{{- date, datetime(format: dd/MM/yyyy HH:mm) }}',
      dateFormat_pretty:
        '{{- date, datetime(format: dd)}} $t(common.formatting.date.month_{{- date, datetime(format: M) }}) {{- date, datetime(format: yyyy)}}',
    },

    price: '{{ value, currency }}',
    fullName: '{{- firstName }} {{- lastName }}',
  },
  downloads: {
    candidateDossierFallback:
      'Dossier Candidato - {{ firstName }} {{ lastName }}',
    candidateCommitmentLetterFallback:
      'Lettera di impegno - {{ firstName }} {{ lastName }}',
    candidateResignationLetterFallback:
      'Lettera di dimissione - {{ firstName }} {{ lastName }}',
    candidateTechnicalReportFallback:
      'Relazione tecnica - {{ firstName }} {{ lastName }}',
    signedContractFallback: 'Contratto firmato - {{ companyName }}',
  },
};
