import React from 'react';
import { Footer as FooterComponent } from '@reverse-hr/pattern-library';
import { FooterLeftBlock } from './_partials/FooterLeftBlock';
import { FooterRightBlock } from './_partials/FooterRightBlock';
import { useTranslation } from 'react-i18next';
import type { FooterProps } from './Footer.types';

const Footer: React.FC<FooterProps> = props => {
  const { logo, payoff } = props;
  const { t } = useTranslation();

  const defaultMenu = [
    {
      external: true,
      target: '_blank',
      label: t('footer.menu.contact.label'),
      url: t('footer.menu.contact.url'),
    },
    {
      external: true,
      target: '_blank',
      label: t('footer.menu.legal.label'),
      url: t('footer.menu.legal.url'),
    },
    {
      external: true,
      target: '_blank',
      label: t('footer.menu.cookies.label'),
      url: t('footer.menu.cookies.url'),
    },
  ];

  const germanVariantMenu = [
    ...defaultMenu,
    {
      external: true,
      target: '_blank',
      label: t('footer.menu.impressum.label'),
      url: t('footer.menu.impressum.url'),
    },
  ];

  return (
    <div className="c-app-footer">
      <div className="container">
        <div className="row">
          <div className="col col--x-padded">
            <FooterComponent
              modifier="c-footer--top-spaced"
              externalLogo={logo}
              externalPayoff={payoff}
              blocks={{
                left: [<FooterLeftBlock />],
                right: [<FooterRightBlock />],
                bottom: '',
              }}
              navs={{
                primary: [],
                secondary:
                  window.employer_locale === 'de'
                    ? germanVariantMenu
                    : defaultMenu,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
