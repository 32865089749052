const {
  REACT_APP_EMPLOYER_VIEW_ENVIRONMENT,
  REACT_APP_EMPLOYER_VIEW_PORT,
  REACT_APP_API_ENVIRONMENT,
  REACT_APP_API_PORT,
  REACT_APP_EMPLOYER_VIEW_BRAND,
} = process.env;

export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  LOCAL: 'local',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const APP_THEMES = {
  REVERSE: 'reverse',
  AKI: 'aki',
};

const EMPLOYER_VIEW_ENVIRONMENT =
  REACT_APP_EMPLOYER_VIEW_ENVIRONMENT ?? ENVIRONMENTS.DEVELOPMENT;

const API_ENVIRONMENT = REACT_APP_API_ENVIRONMENT ?? ENVIRONMENTS.DEVELOPMENT;
const API_PORT = REACT_APP_API_PORT ?? 9000;
const EMPLOYER_VIEW_PORT = REACT_APP_EMPLOYER_VIEW_PORT ?? 3000;

const EMPLOYER_VIEW_ENV_TO_HOST_MAP = {
  [ENVIRONMENTS.DEVELOPMENT]: `http://localhost:${EMPLOYER_VIEW_PORT}`,
  [ENVIRONMENTS.LOCAL]: 'http://localhost',
  [ENVIRONMENTS.STAGING]: 'https://staging.app.reverse.hr',
  [ENVIRONMENTS.PRODUCTION]: 'https://app.reverse.hr',
};

const API_ENV_TO_HOST_MAP = {
  ...EMPLOYER_VIEW_ENV_TO_HOST_MAP,
  [ENVIRONMENTS.DEVELOPMENT]: `http://localhost:${API_PORT}`,
};

export const API_HOST = API_ENV_TO_HOST_MAP[API_ENVIRONMENT];

export const REACT_APP_HOST =
  EMPLOYER_VIEW_ENV_TO_HOST_MAP[EMPLOYER_VIEW_ENVIRONMENT];

export const IS_THEME_AKI = REACT_APP_EMPLOYER_VIEW_BRAND === APP_THEMES.AKI;
