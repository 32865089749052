import { SUCCESS_FEE_ESTIMATE_COUNTS_MAP } from '../constants/success-fee';

export const utilityGetSuccessFeePayments = prospect => {
  const {
    accommodationBenefit,
    annualSalaryValue,
    bonusBenefit,
    companyCarBenefit,
    healthInsuranceBenefit,
    minimumAmount,
    stocksBenefit,
    successFeePercentageOfCompensationPackage,
  } = prospect;

  const successFeeFromSalary = getBonusPercentageAmount({
    bonusAmount: annualSalaryValue,
    successFeePercentageOfCompensationPackage,
  });

  const bonusBenefitOfferAmount =
    bonusBenefit !== null && bonusBenefit.amount !== null
      ? getBonusPercentageAmount({
          bonusAmount: bonusBenefit.amount,
          bonusPercentage: bonusBenefit.percentage,
          successFeePercentageOfCompensationPackage,
        })
      : null;

  const bonusBenefitSalaryAmount =
    bonusBenefit !== null && bonusBenefit.amount === null
      ? getBonusPercentageAmount({
          bonusAmount: annualSalaryValue,
          bonusPercentage: bonusBenefit.percentage,
          successFeePercentageOfCompensationPackage,
        })
      : null;

  const carBenefitAmount =
    companyCarBenefit !== null
      ? getBonusPercentageAmount({
          bonusAmount: companyCarBenefit.value,
          successFeePercentageOfCompensationPackage,
        })
      : null;

  const healthInsuranceBenefitAmount =
    healthInsuranceBenefit !== null
      ? getBonusPercentageAmount({
          bonusAmount: annualSalaryValue,
          bonusPercentage: healthInsuranceBenefit.percentage,
          successFeePercentageOfCompensationPackage,
        })
      : null;

  const accommodationBenefitAmountFullYear =
    accommodationBenefit !== null
      ? getBonusPercentageAmount({
          bonusAmount: annualSalaryValue,
          bonusPercentage: accommodationBenefit.percentage,
          successFeePercentageOfCompensationPackage,
        })
      : null;

  const accommodationBenefitAmount =
    accommodationBenefit !== null
      ? (accommodationBenefitAmountFullYear * accommodationBenefit.months) / 12
      : null;

  const stockOptionsBenefitAmount =
    stocksBenefit !== null
      ? getBonusPercentageAmount({
          bonusAmount: annualSalaryValue,
          bonusPercentage: stocksBenefit.percentage,
          successFeePercentageOfCompensationPackage,
        })
      : null;

  const allCounts = {
    successFeeFromSalary,
    bonusBenefitOffer: bonusBenefitOfferAmount,
    bonusBenefitSalary: bonusBenefitSalaryAmount,
    carBenefit: carBenefitAmount,
    healthInsuranceBenefit: healthInsuranceBenefitAmount,
    accommodationBenefit: accommodationBenefitAmount,
    stockOptionsBenefit: stockOptionsBenefitAmount,
  };

  const overallFeeCounts = getValidCounts(allCounts);
  const overallFee = getCountsSum(overallFeeCounts);

  const { total: prePaymentsTotal } = utilityGetPrePayments(prospect);

  if (!minimumAmount || minimumAmount < overallFee || !prePaymentsTotal) {
    return { counts: overallFeeCounts, total: overallFee };
  }

  const counts = [
    ...overallFeeCounts,
    { key: 'overallFee', value: overallFee },
    {
      key: SUCCESS_FEE_ESTIMATE_COUNTS_MAP.MINIMUM_SUCCESS_FEE_AMOUNT,
      value: minimumAmount,
    },
  ];

  return { counts, total: minimumAmount };
};

export const utilityGetPrePayments = prospect => {
  const { alreadyInvoicedRetainerFee, alreadyInvoicedConfidenceFee } = prospect;

  const counts = getValidCounts({
    retainerFee: alreadyInvoicedRetainerFee || null,
    confidenceFee: alreadyInvoicedConfidenceFee || null,
  });

  const total = getCountsSum(counts);

  return { counts, total };
};

export const utilityGetTotal = prospect => {
  const { discountPercentage, minimumAmount } = prospect;

  const { total: successFee } = utilityGetSuccessFeePayments(prospect);

  const { total: prePaymentsTotal } = utilityGetPrePayments(prospect);
  const prePayments = prePaymentsTotal > 0 ? prePaymentsTotal * -1 : null;
  const totalWithoutDiscount = successFee - prePaymentsTotal;

  if (prePaymentsTotal) {
    return {
      counts: getValidCounts({ successFee, prePayments }),
      total: totalWithoutDiscount,
    };
  }

  const { discount, total } = utilityHandleDiscount(
    totalWithoutDiscount,
    discountPercentage,
  );

  if (total < minimumAmount) {
    const counts = [
      ...getValidCounts({
        successFee,
        discount,
      }),
      {
        key: SUCCESS_FEE_ESTIMATE_COUNTS_MAP.MINIMUM_SUCCESS_FEE_AMOUNT,
        value: minimumAmount,
      },
    ];

    return { counts, total: minimumAmount };
  }

  return {
    counts: getValidCounts({
      successFee,
      discount,
    }),
    total,
  };
};

export const utilityIsMinimalActive = counts =>
  counts.some(
    count =>
      count.key === SUCCESS_FEE_ESTIMATE_COUNTS_MAP.MINIMUM_SUCCESS_FEE_AMOUNT,
  );

const utilityHandleDiscount = (totalWithoutDiscount, discountPercentage) => {
  if (!discountPercentage) {
    return { discount: null, total: totalWithoutDiscount };
  }

  const discount = totalWithoutDiscount * -1 * (discountPercentage / 100);
  const total = totalWithoutDiscount + discount;

  return { discount, total };
};

const getBonusPercentageAmount = ({
  bonusAmount,
  bonusPercentage,
  successFeePercentageOfCompensationPackage,
}) =>
  bonusAmount *
  ((bonusPercentage ?? 100) / 100) *
  (successFeePercentageOfCompensationPackage / 100);

const getValidCounts = allCounts => {
  return Object.keys(allCounts).reduce((validCounts, currentCountKey) => {
    const countValue = allCounts[currentCountKey];
    if (countValue === null || Number(countValue) === 0) {
      return validCounts;
    }

    return [
      ...validCounts,
      {
        key: currentCountKey,
        value: countValue,
      },
    ];
  }, []);
};

const getCountsSum = counts =>
  counts.reduce((sum, currentCount) => sum + Number(currentCount.value), 0);
