import showcase from './showcase';
import { COMMON_DE } from './common';
import { SEARCHING_DE } from './searching';
import { INTERVIEWING_DE } from './interviewing';
import { CLOSING_DE } from './closing';
import { SUCCESS_FEE_DE } from './success-fee';
import { HOMEPAGE_DE } from './homepage';
import { CANDIDATE_DETAILS_DE } from './candidate-details';
import { ERRORS_DE } from './errors';
import * as LOGIN_DE from './login.json';

const DE = {
  translation: {
    header: {
      welcome: 'Willkommen',
      share: 'Teilen',
    },
    hero: {
      backToHomepage: 'Alle Ihre Aufträge',
      backToHomepage_headHunter: 'Alle Aufträge des Kunden',
      prospect: {
        title: 'Vorschau Success Berechnung',
      },
      steps: {
        profiling: {
          title: 'Profil',
          title_no_miro: 'Ansprechpartner',
          description: 'Start',
        },
        searching: {
          title: 'Suche',
          description: 'Tag 1-10',
        },
        interviewing: {
          title: 'Gespräche',
          description: 'Tag 11-20',
        },
        closing: {
          title: 'Closing',
          description: 'Ende',
        },
      },
      helper: {
        profiling: {
          'to-do':
            'Hallo. Auf diesen Seiten können Sie jeden Schritt der Aktivitäten <strong>sehen</strong>, die wir für Sie durchführen, aktualisiert in Echtzeit.',
          'to-do_withName':
            'Hallo {{ name }}. Auf diesen Seiten können Sie jeden Schritt der Aktivitäten <strong>sehen</strong>, die wir für Sie durchführen, aktualisiert in Echtzeit.',
          doing:
            'Hallo. Auf diesen Seiten können Sie jeden Schritt der Aktivitäten <strong>sehen</strong>, die wir für Sie durchführen, aktualisiert in Echtzeit.',
          doing_withName:
            'Hallo {{ name }}. Auf diesen Seiten können Sie jeden Schritt der Aktivitäten <strong>sehen</strong>, die wir für Sie durchführen, aktualisiert in Echtzeit.',
          done: 'Hallo. Auf diesen Seiten können Sie jeden Schritt der Aktivitäten <strong>sehen</strong>, die wir für Sie durchführen, aktualisiert in Echtzeit.',
          done_withName:
            'Hallo {{ name }}. Auf diesen Seiten können Sie jeden Schritt der Aktivitäten <strong>sehen</strong>, die wir für Sie durchführen, aktualisiert in Echtzeit.',
        },
        searching: {
          'to-do':
            'Bis die Daten verfügbar sein werden, <strong>sehen Sie die Aktivitäten, die ich für Sie durchführe</strong>',
          doing:
            'Bis die Daten verfügbar sein werden, <strong>sehen Sie die Aktivitäten, die ich für Sie durchführe</strong>',
          done: 'Die Suche ist abgeschlossen! Im folgenden finden Sie die <strong>Zahlen</strong> und die <strong>JRA</strong>, unsere finale Analyse. ',
        },
        interviewing: {
          'to-do':
            'In dieser Phase zeigen wir Ihnen die Bewerber und werden zeitnahes Feedback einfordern',
          doing:
            'Wir sind mittendrin. Denken Sie daran, <strong>je schneller wir sind, desto größer ist die Chance</strong>, dass wir einstellen.',
          done: 'Wir sind mittendrin. Denken Sie daran, <strong>je schneller wir sind, desto größer ist die Chance</strong>, dass wir einstellen.',
        },
        closing: {
          'to-do':
            'Das ist die finale Phase: die empfindlichste von allen. <strong>Verhandlung, Kündigung des alten Jobs und onboarding</strong>.',
          doing:
            'Das ist die finale Phase: die empfindlichste von allen. <strong>Verhandlung, Kündigung des alten Jobs und onboarding</strong>.',
          done: 'Tolle Leistung! Hier finden Sie eine Zusammenfassung der Kosten und alle nötigen Unterlagen.',
        },
      },
    },
    profile: {
      header: {
        title: 'Profil',
        subtitle:
          'Auf dieser Seite finden Sie eine aktualisierte Zusammenfassung des Bewerberprofils, Ihres Unternehmensprofils und eine unserer Dienstleistung',
      },
      cards: {
        action: {
          label: 'Zu den Details',
        },
        1: {
          title: 'Das Bewerberprofil',
        },
        2: {
          title: 'So erzählen wir dem Bewerber Ihr Unternehmen',
        },
      },
      contract: {
        title:
          'Hier finden Sie die Dienstleistunsvereinbarung für dieses Mandat',
        download: 'Download the contract',
      },
      references: {
        title: 'Ihre Ansprechpartner',
        blank: {
          title: 'Zuweisen',
          subtitle: 'Head Hunter',
          text: 'Je nach Mandat auswählen',
        },
      },
      mirror: {
        body: '<strong>Eine neue Art des Recruitings</strong><br><br>FLEXIBEL, DIGITAL UND SPEZIALISIERT.',
        link: 'Entdecken Sie das Collaborative Recruiting',
      },
    },
    general: {
      error: {
        title: 'Ein Fehler ist aufgetreten',
        subtitle:
          'Seite neu laden. Wenn der Fehler weiterhin besteht, versuchen Sie es später nochmal.',
        button: 'Go back to home',
        hash: {
          title: 'Der Link ist nicht mehr gültig.',
          subtitle:
            'Sie sollten den <strong>korrekten Link</strong> in einer <strong>unserer Emails</strong> erhalten haben.',
          disclaimer:
            'Kannst du es nicht finden? <strong>Kontaktiere deinen Ansprechpartner</strong>',
        },
        unauthorized: {
          title: 'Der Link ist falsch.',
          subtitle:
            'Sie sollten den <strong>korrekten Link</strong>, kurz nach Vertragsunterzeichnung, in einer <strong>unserer Emails</strong> erhalten haben.',
          disclaimer:
            'Kannst du es nicht finden? <strong>Kontaktiere deinen Ansprechpartner</strong>',
        },
        unauthorizedHomepage: {
          title: 'Sie sind nicht berechtigt, diese Seite anzuzeigen.',
          subtitle: '',
          disclaimer: '',
        },
      },
      document_title: {
        profiling: 'Profil',
        searching: 'Suche',
        interviewing: 'Gespräche',
        closing: 'Closing',
        showcase: 'Showcase',
      },
      date_on: 'am',
      date_hour: 'um',
    },
    candidate: {
      hero: {
        title: 'Sie suchen:',
        back_label: 'Zurück zum Mandat',
      },
      infos: {
        age: 'Jahre',
        benefits: 'Aktuelle Benefits',
        bonuses: 'Aktuelle Boni',
      },
      sections: {
        horse: {
          title: 'Aktueller Status',
        },
        bet: {
          open_chat: 'Mit <strong>{{ name }}</strong> chatten',
        },
        curriculum: {
          title: 'Lebenslauf',
          screening: 'Screening ranking',
          button: 'Lebenslauf runterladen',
        },
        summary: {
          otherSelectionsTitle: 'Andere Auswahlverfahren',
        },
        chat: {
          hour: ' um ',
          feedback_title: 'Geben Sie hier ein Feedback',
          name_title: 'Mit <strong>{{ name }}</strong> chatten',
          open: 'Mehr',
          close: 'Schließen',
          fullscreen: 'Vollbild',
          default_message:
            'Hallo, wenn Sie uns etwas mitteilen möchten, verwenden Sie diesen Chat.',
          default_message_withName:
            'Hallo {{ name }}, wenn Sie uns etwas mitteilen möchten, verwenden Sie diesen Chat.',
          default_message_positive:
            'Hallo, warum ist <strong>er/sie interessant</strong>? Möchten Sie einen bestimmten Punkt im Bewerbungsgespräch?',
          default_message_positive_withName:
            'Hallo {{ name }}, warum ist <strong>er/sie interessant</strong>? Möchten Sie einen bestimmten Punkt im Bewerbungsgespräch?',
          default_message_negative:
            'Hallo, schreiben Sie uns Ihre Begründung, damit wir unsere Suche anpassen können.',
          default_message_negative_withName:
            'Hallo {{ name }}, schreiben Sie uns Ihre Begründung, damit wir unsere Suche anpassen können.',
        },
        concerns: {
          intro:
            '{{ name }} ist ein sehr interessantes Profil, das aber nicht ganz den Anforderungen entspricht.',
        },
      },
    },
    footer: {
      company: 'Reverse Deutschland GmbH',
      headquarter: 'Firmensitz: Unter den Linden 26‑30, Berlin',
      informations: [
        'MwSt Nr: DE328318880',
        'info.de@reverse.hr',
        'Handelsregister: Amtsgericht Berlin-Charlottenburg, HRB 213573',
        'Grundkapital: 50.000€',
      ],
      menu: {
        contact: {
          label: 'Kontaktieren Sie uns',
          slug: 'kontaktieren-sie-uns',
        },
        legal: {
          label: 'Legal',
          slug: 'legal',
        },
        cookies: {
          label: 'Cookies',
          url: 'https://www.iubenda.com/privacy-policy/28745003/cookie-policy',
        },
        impressum: {
          label: 'Impressum',
          slug: 'impressum',
        },
      },
    },
    modal: {
      share: {
        title:
          'Sende Sie eine Einladung für Echtzeit-Updates zum Einstellungsfortschritt',
        inputs: {
          first_name: {
            label: 'Name',
          },
          last_name: {
            label: 'Nachname',
          },
          email: {
            label: 'E-Mail',
            hint: 'Diese E-Mail Adresse entspricht nicht der Domani mit der Sie sich angemeldet haben. Sind SIe sicher, fortfahren zu wollen?',
          },
          lang: {
            label: 'Sprache',
          },
        },
        lang_options: {
          it: 'Italienish',
          en: 'Englisch',
          de: 'Deutsch',
          fr: 'Französisch',
          es: 'Spanisch',
        },
        button: 'Einladung senden',
        shared_with: 'Geteilt mit',
        alert: {
          positive: 'Die E-Mail-Einladung wurde gesende',
          negative:
            'Irgendwashat nicht geklappt. Bitte versuchen Sie es erneut, oder kontaktieren Sie Ihre Ansprechpartner',
        },
      },
    },
    validation: {
      scout_approved: 'von Scout überprüft',
      scout_required: 'muss von Scout überprüft werden',
      required: 'This field is required',
      email: 'This email address is not valid',
    },
    language_switcher: {
      it: 'IT',
      en: 'EN',
      de: 'DE',
      fr: 'FR',
      es: 'ES',
    },
    showcase,
    candidateDetails: CANDIDATE_DETAILS_DE,
    common: COMMON_DE,
    searching: SEARCHING_DE,
    interviewing: INTERVIEWING_DE,
    closing: CLOSING_DE,
    successFee: SUCCESS_FEE_DE,
    homepage: HOMEPAGE_DE,
    login: LOGIN_DE,
    errors: ERRORS_DE,
  },
};

export default DE;
