import React from 'react';

import { Action, Header } from '@reverse-hr/pattern-library';
import LanguageSwitcher from './TheHeader/LanguageSwitcher';
import { useStoreState } from 'easy-peasy';
import { selectorUser } from '../model/User';
import { useTranslation } from 'react-i18next';
import { WelcomeMessage } from './WelcomeMessage';
import { IS_THEME_AKI } from '../.configs/environment';
import { APP_BASE_PATH } from '../constants/navigation';

const TheHeader = ({ modifier, labels, onClick }) => {
  const { t } = useTranslation();
  const user = useStoreState(selectorUser);

  return (
    <div className="c-app-header">
      <Header
        externalLogo={
          IS_THEME_AKI
            ? `${APP_BASE_PATH}/assets/images/logo/aaron-king-logo.png`
            : undefined
        }
      >
        <div className="c-header__actions">
          <div className="c-header__actions-item">
            <WelcomeMessage />
          </div>

          <div className="c-header__actions-item desk-btn">
            <Action
              type="secondary"
              icon="icn-share-outline-24"
              label={labels.share}
              onClick={onClick}
            />
          </div>

          <div className="c-header__actions-item mobile-btn">
            <Action
              type="secondary"
              icon="icn-share-outline-24"
              iconOnly
              onClick={onClick}
            />
          </div>
          <div className="c-header__actions-item c-header__actions-item--dropdown">
            <LanguageSwitcher />
          </div>
        </div>
      </Header>
    </div>
  );
};

TheHeader.defaultProps = {
  modifier: '',
  labels: {
    welcome: 'Bentornato',
    share: 'Condividi',
  },
};

export default TheHeader;
