import showcase from './showcase';
import { COMMON_FR } from './common';
import { SEARCHING_FR } from './searching';
import { INTERVIEWING_FR } from './interviewing';
import { CLOSING_FR } from './closing';
import { SUCCESS_FEE_FR } from './success-fee';
import { HOMEPAGE_FR } from './homepage';
import { CANDIDATE_DETAILS_FR } from './candidate-details';
import { ERRORS_FR } from './errors';
import * as LOGIN_FR from './login.json';

const FR = {
  translation: {
    header: {
      welcome: 'Bienvenue',
      share: 'Partager',
    },
    hero: {
      backToHomepage: 'Tous vos mandats',
      backToHomepage_headHunter: 'Tous les mandats du client',
      prospect: {
        title: 'Calcul honoraire',
      },
      steps: {
        profiling: {
          title: 'Profil',
          title_no_miro: 'Information',
          description: 'Démarrage ',
        },
        searching: {
          title: 'Recherche',
          description: '1-10 JRS',
        },
        interviewing: {
          title: 'Entretiens',
          description: '11-20 JRS',
        },
        closing: {
          title: 'Clôture',
          description: 'Fin',
        },
      },
      helper: {
        profiling: {
          'to-do':
            "Bonjour. Voici notre processus, vous pouvez le consulter si vous le souhaitez. Vous serez toujours en mesure de consulter <strong>l'avancée de la recherche</strong>.",
          'to-do_withName':
            "Bonjour {{ name }}. Voici notre processus, vous pouvez le consulter si vous le souhaitez. Vous serez toujours en mesure de consulter <strong>l'avancée de la recherche</strong>.",
          doing:
            "Bonjour. Voici notre processus, vous pouvez le consulter si vous le souhaitez. Vous serez toujours en mesure de consulter <strong>l'avancée de la recherche</strong>.",
          doing_withName:
            "Bonjour {{ name }}. Voici notre processus, vous pouvez le consulter si vous le souhaitez. Vous serez toujours en mesure de consulter <strong>l'avancée de la recherche</strong>.",
          done: "Bonjour. Voici notre processus, vous pouvez le consulter si vous le souhaitez. Vous serez toujours en mesure de consulter <strong>l'avancée de la recherche</strong>.",
          done_withName:
            "Bonjour {{ name }}. Voici notre processus, vous pouvez le consulter si vous le souhaitez. Vous serez toujours en mesure de consulter <strong>l'avancée de la recherche</strong>.",
        },
        searching: {
          'to-do':
            "Pendant la recherche, <strong>vous pourrez voir les activités que nous réalisons pour vous</strong>, jusqu'à ce que des données plus précises soient disponibles.",
          doing:
            "Pendant la recherche, <strong>vous pourrez voir les activités que nous réalisons pour vous</strong>, jusqu'à ce que des données plus précises soient disponibles.",
          done: 'Recherche terminée! Voici les <strong>résultats</strong> et notre <strong>analyse</strong> finale.',
        },
        interviewing: {
          'to-do':
            'À ce stade, <strong>nous vous présenterons les candidats</strong> et nous aurons besoin de recevoir <strong>un retour en temps opportun</strong>.',
          doing:
            'Nous sommes au cœur du processus de sélection. Rappelons-nous : plus nous sommes réactifs, plus nous avons de chances de <strong>recruter</strong>.',
          done: 'Nous sommes au cœur du processus de sélection. Rappelons-nous : plus nous sommes réactifs, plus nous avons de chances de <strong>recruter</strong>.',
        },
        closing: {
          'to-do':
            "C'est la phase finale: la plus délicate. <strong>Négociation, démission et onboarding</strong>.",
          doing:
            "C'est la phase finale: la plus délicate. <strong>Négociation, démission et onboarding</strong>.",
          done: "C'est la phase finale: la plus délicate. <strong>Négociation, démission et onboarding</strong>.",
        },
      },
    },
    profile: {
      header: {
        title: 'Profil',
        subtitle:
          'Sur cette page, vous trouverez un résumé du profil recherché, de votre entreprise et de notre service.',
      },
      cards: {
        action: {
          label: 'Voir les détails',
        },
        1: {
          title: 'Le profil du candidat',
        },
        2: {
          title: 'La présentation de votre entreprise au candidat',
        },
      },
      contract: {
        title: 'Vous trouvez ici le contrat pour ce mandat',
        download: 'Téléchargez le contrat',
      },
      references: {
        title: 'Vos contacts',
        blank: {
          title: 'Nous recherchons la bonne personne pour cette recherche',
          text: 'Nous voulons le meilleur HR Consultant dont nous disposons...',
        },
      },
      mirror: {
        body: '<strong>Une nouvelle approche pour le recrutement</strong><br><br>FLEXIBLE, DIGITALE ET SPÉCIALISÉE.',
        link: 'Découvrez le recrutement collaboratif',
      },
    },
    general: {
      error: {
        title: "Une erreur s'est produite.",
        subtitle:
          "Veuillez rafraîchir la page. Si le problème n'est pas résolu, réessayez plus tard.",
        hash: {
          title: 'Le lien a expiré.',
          subtitle:
            "Vous devriez avoir reçu le <strong>bon lien</strong> dans l'un de <strong>nos récents e-mails</strong>.",
          disclaimer:
            'Vous ne le trouvez pas ? <strong>Veuillez informer vos personnes de contact</strong>',
        },
        unauthorized: {
          title: 'Questo link sembra sbagliato.',
          subtitle:
            'Vous devriez avoir reçu le <strong>lien correct</strong> dans un <strong>e-mail de notre part</strong> peu après avoir <strong>signé le contrat</strong>.',
          disclaimer:
            'Vous ne le trouvez pas ? <strong>Veuillez informer vos personnes de contact</strong>',
        },
        unauthorizedHomepage: {
          title: "Vous n'êtes pas autorisé à afficher cette page.",
          subtitle: '',
          disclaimer: '',
        },
      },
      document_title: {
        profiling: 'Profil',
        searching: 'Recherche',
        interviewing: 'Entretiens',
        closing: 'Clôture',
        showcase: 'Showcase',
      },
      date_on: 'le',
      date_hour: 'à',
    },
    candidate: {
      hero: {
        title: 'vous recherchez:',
        back_label: 'Retournez au mandat',
      },
      infos: {
        age: 'années',
        benefits: 'Avantages actuels',
        bonuses: 'Primes actuelles',
      },
      sections: {
        horse: {
          title: 'Situation actuelle',
        },
        bet: {
          open_chat: 'Écrivez en chat à <strong>{{ name }}</strong>',
        },
        curriculum: {
          title: 'Curriculum Vitae',
          screening: 'Évaluation de présélection ',
          button: 'Consultez le CV',
        },
        summary: {
          otherSelectionsTitle: 'Autres processus de sélection',
        },
        chat: {
          hour: ' heures ',
          feedback_title: 'Laissez-nous vos commentaires',
          name_title: 'Écrivez  à <strong>{{ name }}</strong>',
          open: "Plus d'infos",
          close: 'Fermer',
          fullscreen: 'Plein écran',
          default_message:
            'Bonjour, si vous avez quelque chose à nous signaler, veuillez utiliser ce chat.',
          default_message_withName:
            'Bonjour {{ name }}, si vous avez quelque chose à nous signaler, veuillez utiliser ce chat.',
          default_message_positive:
            "Bonjour, pourquoi <strong>êtes-vous intéressé</strong>? Voulez-vous approfondir certains aspects lors de l'entretien ?",
          default_message_positive_withName:
            "Bonjour {{ name }}, pourquoi <strong>êtes-vous intéressé</strong>? Voulez-vous approfondir certains aspects lors de l'entretien ?",
          default_message_negative:
            'Bonjour, écrivez-moi vos motivations afin que nous puissions affiner la recherche de candidats.',
          default_message_negative_withName:
            'Bonjour {{ name }}, écrivez-moi vos motivations afin que nous puissions affiner la recherche de candidats.',
        },
        concerns: {
          intro:
            '{{ name }} est un profil très intéressant mais pas tout à fait aligné avec les prérequis.',
        },
      },
    },
    footer: {
      company: 'Reverse France SAS',
      headquarter: 'Siège social: 29 rue Blanche 75009 Paris',
      informations: [
        'SIRET: 910 505 684 00019',
        'VAT: FR71910505684',
        'Pec: reversespa@pec.it',
        'Capitale sociale libéré: 50.000€',
      ],
      menu: {
        contact: {
          label: 'Nous contacter',
          slug: 'nous contacter',
        },
        legal: {
          label: 'Legal',
          slug: 'legal',
        },
        cookies: {
          label: 'Cookies',
          url: 'https://www.iubenda.com/privacy-policy/80643942/cookie-policy',
        },
      },
    },
    modal: {
      share: {
        title:
          "Envoyer une invitation pour des mises à jour sur l'avancement du recrutement",
        inputs: {
          first_name: {
            label: 'Prénom',
          },
          last_name: {
            label: 'Nom',
          },
          email: {
            label: 'Email',
            hint: "L'e-mail ne correspond pas au domaine sur lequel vous êtes enregistré. Voulez-vous vraiment continuer?",
          },
          lang: {
            label: 'Langue',
          },
        },
        lang_options: {
          it: 'Italien',
          en: 'Anglais',
          de: 'Allemand',
          fr: 'Français',
          es: 'Espagnol',
        },
        button: 'Envoyer une invitation',
        shared_with: 'Partagé avec',
        alert: {
          positive: "L'invitation par e-mail a été envoyée",
          negative:
            'Quelque chose a mal tourné. Veuillez réessayer plus tard ou contacter vos personnes de contact',
        },
      },
    },
    validation: {
      scout_approved: 'Validé par un scout',
      scout_required: 'Nécessite la validation des scouts',
      required: 'Ce champ est obligatoire',
      email: "Cette adresse e-mail n'est pas valide",
    },
    language_switcher: {
      it: 'IT',
      en: 'EN',
      de: 'DE',
      fr: 'FR',
      es: 'ES',
    },
    showcase,
    candidateDetails: CANDIDATE_DETAILS_FR,
    common: COMMON_FR,
    searching: SEARCHING_FR,
    interviewing: INTERVIEWING_FR,
    closing: CLOSING_FR,
    successFee: SUCCESS_FEE_FR,
    homepage: HOMEPAGE_FR,
    login: LOGIN_FR,
    errors: ERRORS_FR,
  },
};

export default FR;
