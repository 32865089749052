import { action, thunk } from 'easy-peasy';
import type { TCandidateFeedbackMessagesStore } from './FeedbackMessages.type';
import { utilityThrowError } from '../../../utils/errors';
import type { TCandidate } from '../Candidate.type';

export const feedbackMessagesStore: TCandidateFeedbackMessagesStore = {
  feedbacksLoading: false,
  feedbacksError: null,

  setFeedbacksLoading: action((state, payload) => {
    state.feedbacksLoading = payload;
  }),

  setFeedbacksError: action((state, payload) => {
    state.feedbacksError = payload;
  }),

  postFeedbackMessage: thunk(async (actions, payload, helpers) => {
    const { content, jobId, candidateId } = payload;
    const _actions = helpers.getStoreActions();

    actions.setFeedbacksError(null);
    actions.setFeedbacksLoading(true);

    try {
      const res = await _actions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/feedback-messages`,
        requestOptions: { method: 'POST', body: JSON.stringify({ content }) },
      });

      _actions.candidates.setCandidate(res as TCandidate);
    } catch (error) {
      actions.setFeedbacksError(error);
      utilityThrowError(error);
    } finally {
      actions.setFeedbacksLoading(false);
      actions.setFeedbacksError(null);
    }
  }),
};
