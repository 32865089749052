import { Card } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { jobCompanyNameSelector } from '../../model/Job/Job.selectors';

export const AppCardTask = ({ task }) => {
  const { t } = useTranslation();
  const companyName = useStoreState(jobCompanyNameSelector);

  return (
    <div className="c-app-card-task">
      <Card modifier="c-card--ordinary">
        {{
          head: {
            avatar: task.headHunter.avatarUrl,
            title: t('common.formatting.fullName', task.headHunter),
            subtitle: t('searching.tasks.card.date', {
              date: new Date(task.completedAt),
            }),
          },
          body: (
            <p className="c-card__body-text typo-text-body typo-lh-title">
              {t('searching.tasks.card.taskDescription', {
                companyName,
                context: task.type,
                count: task.count,
              })}
            </p>
          ),
        }}
      </Card>
    </div>
  );
};
