import type { Actions, RecursiveActions, State } from 'easy-peasy';
import type { StoreModel } from '../../store/types/store-model';
import type { TDealEntity, TDealEntityStore } from './DealEntity.type';
import type { Nullable } from '../../utils/types/Nullable';

export const dealEntityStoreStateSelector = (
  state: State<StoreModel>,
): Nullable<TDealEntity> => state.dealEntity.data;

export const dealEntityStoreActionsSelector = (
  actions: Actions<StoreModel>,
): RecursiveActions<TDealEntityStore> => actions.dealEntity;
