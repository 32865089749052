import { thunk } from 'easy-peasy';
import type { TJobShowcaseStore } from './Showcase.type';
import { utilityThrowError } from '../../../utils/errors';
import type { TJob } from '../Job.type';

export const showcaseStore: TJobShowcaseStore = {
  putShowcase: thunk(async (_, payload, helpers) => {
    const _actions = helpers.getStoreActions();
    const { jobId, ...rest } = payload;

    _actions.job.setLoading(true);
    _actions.job.setError(false);

    try {
      const res = await _actions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/showcase-configuration`,
        requestOptions: { method: 'POST', body: JSON.stringify(rest) },
      });

      _actions.job.setData(res as TJob);
    } catch (error) {
      _actions.job.setError(true);
      utilityThrowError(error, 'Error to save shares');
    }
  }),

  deleteShowcase: thunk(async (_, payload, helpers) => {
    const { jobId } = payload;
    const _actions = helpers.getStoreActions();

    try {
      await _actions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/showcase-configuration`,
        requestOptions: { method: 'DELETE' },
      });
    } catch (error) {
      _actions.job.setError(true);
      utilityThrowError(error, 'Error to save shares');
    }
  }),
};
