import React from 'react';
import { profile } from '../utils/default-values';

import { Card, LinkWrapper, Pictogram } from '@reverse-hr/pattern-library';
import { APP_BASE_PATH } from '../constants/navigation';

const ReferentCard = ({ manager, modifier }) => {
  const { blankReferentCard } = profile();
  const isManager = manager && manager.email;
  const jobTitle = isManager ? manager.jobTitle : '';

  const avatar =
    isManager && manager.avatarUrl
      ? manager.avatarUrl
      : REFERENT_PLACEHOLDER_AVATAR;

  const fullName = isManager
    ? `${manager.firstName} ${manager.lastName}`
    : blankReferentCard.title;

  const text = blankReferentCard.text;

  return (
    <>
      <div
        data-testid="referent-card"
        className={`c-app-card-referent ${modifier || ''} ${
          !isManager ? 'c-app-card-referent--placeholder' : ''
        }`}
      >
        <Card order={['media', 'head', 'body']}>
          {{
            head: {
              title: fullName,
              subtitle: isManager ? jobTitle : null,
              right: null,
            },
            media: {
              classes: 'c-card__media--1-1',
              image: avatar,
            },
            body: isManager ? (
              <div data-testid="referent-links">
                <LinkWrapper
                  external
                  url={`mailto:${manager.email}`}
                  children={
                    <Pictogram icon="icn-mail-24" size="small">
                      {manager.email}
                    </Pictogram>
                  }
                />
                {manager.phoneNumber && (
                  <LinkWrapper
                    external
                    url={`tel:${manager.phoneNumber}`}
                    children={
                      <Pictogram icon="icn-phone-outline-24" size="small">
                        {manager.phoneNumber}
                      </Pictogram>
                    }
                  />
                )}
              </div>
            ) : (
              <div>{text}</div>
            ),
          }}
        </Card>
      </div>
    </>
  );
};

ReferentCard.defaultProps = {
  manager: {},
};

export default ReferentCard;

const REFERENT_PLACEHOLDER_AVATAR = `${APP_BASE_PATH}/assets/images/referent-placeholder.png`;
