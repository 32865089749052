import React from 'react';
import { APP_BASE_PATH } from '../constants/navigation';
import { IS_THEME_AKI } from '../.configs/environment';

const Video = ({ modifier, video, sources, autoPlay, loop, muted }) => {
  const videoPath = IS_THEME_AKI ? `/videos/aaron-king/` : `/videos/`;

  return (
    <div className={`c-app-video ${modifier}`}>
      <video
        className="c-app-video__item"
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        title={video}
      >
        {sources
          ? sources.map((src, index) => {
              return (
                <source
                  key={`video-source-${index}`}
                  src={`${APP_BASE_PATH}${videoPath}${video}.${src}`}
                  type={`video/${src}`}
                />
              );
            })
          : null}
      </video>
    </div>
  );
};

Video.defaultProps = {
  modifier: '',
  autoPlay: true,
  loop: true,
  muted: true,
};

export default Video;
