export const LANG_AKI_DE = {
  footer: {
    company: '© Aaron King International part of Reverse SpA',
    headquarter: 'Firmensitz: Unter den Linden 26‑30, Berlin',
    informations: [
      'VAT Number: 09714690964',
      'PEC: reversespa@pec.it',
      'Commercial registry: MI - 2109818',
      'Paid up share capital: 50.000€',
      'REA Number: MI - 2109818',
    ],
    companyBaseUrl: 'https://www.reverse.hr/de/',
    menu: {
      contact: {
        label: 'Kontaktieren Sie uns',
        url: 'https://reverse.hr/de/kontaktieren-sie-uns/',
      },
      legal: {
        label: 'Legal',
        url: 'https://www.reverse.hr/de/legal',
      },
      cookies: {
        label: 'Cookies',
        url: 'https://www.iubenda.com/privacy-policy/28745003/cookie-policy',
      },
      impressum: {
        label: 'Impressum',
        url: 'https://www.reverse.hr/de/impressum',
      },
    },
  },
};
