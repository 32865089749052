import React, { useEffect, useMemo, useState } from 'react';
import { selectorCustomerActions } from '../../model/Customer';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { utilityGetErrorInstance } from '../../utils/errors';
import { selectorUser, selectorUserActions } from '../../model/User';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useHomepageRouteMatch } from '../../utils/hooks/use-homepage-route-match';
import { ErrorScreen } from '../ErrorScreen';
import { useTranslation } from 'react-i18next';
import { selectorRootActions } from '../../model';
import { jobCompanyNameSelector } from '../../model/Job/Job.selectors';

export const HomepageContext = React.createContext({
  isLoading: true,
  customerJobs: [],
});

export const HomepageProvider = ({ children }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [customerJobs, setCustomerJobs] = useState([]);
  const { customerId } = useParams();
  const { setAppError } = useStoreActions(selectorRootActions);

  const { thunkFetchCustomerJobs, thunkFetchCustomerUserData } =
    useStoreActions(selectorCustomerActions);

  const { thunkTrackHomepageAccess } = useStoreActions(selectorUserActions);
  const { isHomepageCandidatesPage } = useHomepageRouteMatch();
  const companyName = useStoreState(jobCompanyNameSelector);
  const currentPath = useRouteMatch();
  const user = useStoreState(selectorUser);

  useEffect(() => {
    if (user.id) {
      fetchData();
    }
  }, [user.id]);

  useEffect(() => {
    thunkTrackHomepageAccess(isHomepageCandidatesPage);
  }, [currentPath?.path, companyName]);

  const providerValue = useMemo(
    () => ({
      isLoading,
      customerJobs,
    }),
    [isLoading, customerJobs],
  );

  const fetchData = async () => {
    try {
      await fetchCustomerJobs();
      await fetchCustomerUserData();
    } catch (error) {
      if (error?.cause?.status === 403) {
        return setIsUnauthorized(true);
      }

      setAppError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomerJobs = async () => {
    try {
      const result = await thunkFetchCustomerJobs(customerId);
      const sortedJobs = sortJobsByDate(result);

      setCustomerJobs(sortedJobs);
    } catch (error) {
      await Promise.reject(error);
    }
  };

  const fetchCustomerUserData = async () => {
    try {
      await thunkFetchCustomerUserData(customerId);
    } catch (error) {
      await Promise.reject(
        utilityGetErrorInstance(
          error,
          "Homepage Provider failed to fetch customer's user data.",
        ),
      );
    }
  };

  return (
    <HomepageContext.Provider value={providerValue}>
      {isUnauthorized ? (
        <ErrorScreen
          className="c-error-screen--unauthorized"
          title={t('general.error.unauthorizedHomepage.title')}
        />
      ) : (
        children
      )}
    </HomepageContext.Provider>
  );
};

export const sortJobsByDate = jobs =>
  jobs.sort(
    (a, b) =>
      new Date(b.publishedOn).getTime() - new Date(a.publishedOn).getTime(),
  );
