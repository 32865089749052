import type { TJwt } from '../models/Session';
import { REACT_APP_HOST } from '../.configs/environment';

export const apiGenerateTokenPost = async (
  email: string,
): Promise<Response> => {
  const response = await fetch(
    `${REACT_APP_HOST}/api/v1/auth/users/login-tokens`,
    {
      body: JSON.stringify({ email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );

  if (!response.ok) {
    return Promise.reject(response);
  }

  return Promise.resolve(response);
};

interface TApiVerificationCodePostPayload {
  userId: string;
  verificationCode: string;
}

export const apiVerificationCodePost = async ({
  userId,
  verificationCode,
}: TApiVerificationCodePostPayload): Promise<TJwt> => {
  const response = await fetch(
    `${REACT_APP_HOST}/api/v1/auth/users/${userId}/verify-login-token`,
    {
      body: JSON.stringify({ verificationCode }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
  );

  if (!response.ok) {
    return Promise.reject();
  }

  return await response.json();
};

interface TApiVerifyCodePostPayload {
  email: string;
  verificationCode: string;
}

export const apiUsersLoginTokensVerifyPost = async ({
  email,
  verificationCode,
}: TApiVerifyCodePostPayload): Promise<TJwt> => {
  const response = await fetch(
    `${REACT_APP_HOST}/api/v1/auth/users/login-tokens/verify`,
    {
      body: JSON.stringify({ verificationCode, email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
  );

  if (!response.ok) {
    return Promise.reject();
  }

  return await response.json();
};
