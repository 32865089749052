import { thunk, action } from 'easy-peasy';
import { utilityThrowError } from '../../../utils/errors';
import type { TCandidateDownloadsStore } from './Downloads.type';
import {
  getDownloadFilenameFallbackFromCandidate,
  getDownloadFilenameFallbackFromCandidates,
} from '../_helpers/getFilenameFallback';

export const downloadsStore: TCandidateDownloadsStore = {
  downloadData: null,
  curriculumUrl: null,

  setDownloadData: action((state, payload) => {
    state.downloadData = payload;
  }),

  setCurriculumUrl: action((state, payload) => {
    state.curriculumUrl = payload;
  }),

  getCommitmentLetter: thunk(async (actions, payload, helpers) => {
    const { jobId, candidateId } = payload;
    const _actions = helpers.getStoreActions();
    const _storeState = helpers.getStoreState();
    let fallbackFilename: string | null = null;

    _actions.candidates.setLoading(true);
    _actions.candidates.setError(null);

    try {
      const { filename, blob } = await _actions.session.authFetchPdf({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/downloads/commitment-letter`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      const url = URL.createObjectURL(blob);

      if (!filename && _storeState.candidates.candidates.results.length > 0) {
        fallbackFilename = getDownloadFilenameFallbackFromCandidates(
          _storeState.candidates.candidates.results,
          candidateId,
          'common.downloads.candidateCommitmentLetterFallback',
        );
      }

      actions.setDownloadData({
        filename: filename ?? fallbackFilename,
        url,
      });
      return { filename: filename ?? fallbackFilename, url };
    } catch (error) {
      _actions.candidates.setError(error);
      utilityThrowError(error);
      return null;
    } finally {
      _actions.candidates.setLoading(false);
      _actions.candidates.setError(null);
    }
  }),

  getDossier: thunk(async (actions, payload, helpers) => {
    const { jobId, candidateId } = payload;
    const _actions = helpers.getStoreActions();
    const _storeState = helpers.getStoreState();
    let fallbackFilename: string | null = null;

    _actions.candidates.setLoading(true);
    _actions.candidates.setError(null);

    try {
      const { filename, blob } = await _actions.session.authFetchPdf({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/downloads/dossier`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      const url = URL.createObjectURL(blob);

      if (!filename && _storeState.candidates.candidate) {
        fallbackFilename = getDownloadFilenameFallbackFromCandidate(
          _storeState.candidates.candidate,
          'common.downloads.candidateDossierFallback',
        );
      }

      actions.setDownloadData({ filename: filename ?? fallbackFilename, url });
      return { filename: filename ?? fallbackFilename, url };
    } catch (error) {
      _actions.candidates.setError(error);
      utilityThrowError(error);
      return null;
    } finally {
      _actions.candidates.setLoading(false);
      _actions.candidates.setError(null);
    }
  }),

  getResignationLetter: thunk(async (actions, payload, helpers) => {
    const { jobId, candidateId } = payload;
    const _actions = helpers.getStoreActions();
    const _storeState = helpers.getStoreState();
    let fallbackFilename: string | null = null;
    _actions.candidates.setLoading(true);
    _actions.candidates.setError(null);

    try {
      const { filename, blob } = await _actions.session.authFetchPdf({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/downloads/resignation-letter`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      const url = URL.createObjectURL(blob);

      if (!filename && _storeState.candidates.candidates.results.length > 0) {
        fallbackFilename = getDownloadFilenameFallbackFromCandidates(
          _storeState.candidates.candidates.results,
          candidateId,
          'common.downloads.candidateResignationLetterFallback',
        );
      }

      actions.setDownloadData({ filename: filename ?? fallbackFilename, url });
      return { filename: filename ?? fallbackFilename, url };
    } catch (error) {
      _actions.candidates.setError(error);
      utilityThrowError(error);
      return null;
    } finally {
      _actions.candidates.setLoading(false);
      _actions.candidates.setError(null);
    }
  }),

  getTechnicalReport: thunk(async (actions, payload, helpers) => {
    const { jobId, candidateId } = payload;
    const _actions = helpers.getStoreActions();
    const _storeState = helpers.getStoreState();
    let fallbackFilename: string | null = null;

    _actions.candidates.setLoading(true);
    _actions.candidates.setError(null);

    try {
      const { filename, blob } = await _actions.session.authFetchPdf({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/downloads/technical-report`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      const url = URL.createObjectURL(blob);

      if (!filename && _storeState.candidates.candidate) {
        fallbackFilename = getDownloadFilenameFallbackFromCandidate(
          _storeState.candidates.candidate,
          'common.downloads.candidateTechnicalReportFallback',
        );
      }

      actions.setDownloadData({ filename: filename ?? fallbackFilename, url });
      return { filename: filename ?? fallbackFilename, url };
    } catch (error) {
      _actions.candidates.setError(error);
      utilityThrowError(error);
      return null;
    } finally {
      _actions.candidates.setLoading(false);
      _actions.candidates.setError(null);
    }
  }),
  getCurriculum: thunk(async (actions, payload, helpers) => {
    const { jobId, candidateId } = payload;
    const _actions = helpers.getStoreActions();

    try {
      const { blob } = await _actions.session.authFetchPdf({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/downloads/curriculum`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      const url = URL.createObjectURL(blob);

      actions.setCurriculumUrl(url);
      return { url };
    } catch (error) {
      _actions.candidates.setError(error);
      utilityThrowError(error);
      return null;
    } finally {
      _actions.candidates.setLoading(false);
      _actions.candidates.setError(null);
    }
  }),
};
