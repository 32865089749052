import { useStoreState } from 'easy-peasy';
import { SEARCHING_TASKS_VIEW_TYPES } from '../../constants/searching';
import { AppTasksStack } from './AppTasksStack';
import { AppTasksMasonry } from './AppTasksMasonry';
import { jobStoreSelector } from '../../model/Job/Job.selectors';
import { useActivitiesStacks } from './_hooks/use-activities-stacks';

export const AppTasks = ({ currentView }) => {
  const job = useStoreState(jobStoreSelector);
  const { stacks, flattenedStacks } = useActivitiesStacks();

  if (currentView === SEARCHING_TASKS_VIEW_TYPES.LIST) {
    return <AppTasksStack stacks={stacks} />;
  }

  if (currentView === SEARCHING_TASKS_VIEW_TYPES.GRID) {
    return <AppTasksMasonry stacks={flattenedStacks} />;
  }

  return null;
};

const sortTasksByDate = (a, b) => (a.completedAt < b.completedAt ? 1 : -1);
