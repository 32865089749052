const getApplicationsSortedAlphabetically = (candidates = []) => {
  candidates.sort((a, b) => a.firstName.localeCompare(b.firstName));
  return candidates;
};

const getApplicationsSortedByState = (candidates = []) => {
  if (!candidates?.length) return [];

  const discardedApplications = getApplicationsSortedAlphabetically(
    candidates?.filter(application => application.discardedAt),
  );
  const interestedButApplications = getApplicationsSortedAlphabetically(
    candidates?.filter(application => application.concerns !== null),
  );
  const interestedApplications = getApplicationsSortedAlphabetically(
    candidates?.filter(
      application => !application.discardedAt && application.concerns === null,
    ),
  );

  return [
    ...interestedApplications,
    ...interestedButApplications,
    ...discardedApplications,
  ];
};

export { getApplicationsSortedByState, getApplicationsSortedAlphabetically };
