import { Pictogram } from '@reverse-hr/pattern-library';
import { useDrivingTime } from '../../../../../utils/hooks/application/use-driving-time';
import type { FC } from 'react';
import { Trans } from 'react-i18next';
import { utilityGetTranslatedLocation } from '../../../../../utils/location';
import { useStoreState } from 'easy-peasy';
import { selectorUser } from '../../../../../model/User';
import type { TCandidate } from '../../../../../model/Candidate/Candidate.type';

const Location: FC<TCandidate> = props => {
  const {
    address,
    drivingTimeToWorkInMinutes,
    drivingDistanceToWorkInKilometers,
  } = props;
  const { locale } = useStoreState(selectorUser);
  const drivingTime = useDrivingTime(drivingTimeToWorkInMinutes);
  const drivingDistanceInKm = drivingDistanceToWorkInKilometers
    ? Number(drivingDistanceToWorkInKilometers)
    : null;

  const parsedDistance = drivingDistanceInKm
    ? drivingDistanceInKm.toFixed(0)
    : null;

  const translatedLocation = utilityGetTranslatedLocation({
    language: locale,
    location: address,
  });

  const locationContent =
    translatedLocation?.city ?? translatedLocation?.address;

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--left-aligned c-app-showcase__cell--top-aligned">
      <Pictogram
        icon="icn-map-outline-24"
        modifier="u-color-primary-80"
        size="small"
      >
        <Trans
          context={parsedDistance !== null ? 'withDistance' : ''}
          i18nKey="showcase.location.locationAndDistance"
          values={{
            location: locationContent,
            distance: parsedDistance,
          }}
        />
      </Pictogram>

      {!!drivingTime ? (
        <Pictogram
          icon="icn-clock-24"
          modifier="u-color-primary-80"
          size="small"
        >
          {drivingTime}
        </Pictogram>
      ) : null}
    </td>
  );
};

export default Location;
