import { action, thunk } from 'easy-peasy';
import { utilityThrowError } from '../../utils/errors';

import type { TJob, TJobStore } from './Job.type';
import { sharesStore } from './shares/Shares';
import { showcaseStore } from './showcase/Showcase';

export const jobStore: TJobStore = {
  loading: true,
  error: false,
  data: null,
  currentJobId: null,
  progress: null,

  setCurrentJobId: action((state, payload) => {
    state.currentJobId = payload;
  }),

  setJobProgress: action((state, payload) => {
    state.progress = payload;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  setError: action((state, payload) => {
    state.error = payload;
  }),

  setData: action((state, payload) => {
    state.data = payload;
  }),

  getJob: thunk(async (actions, payload, helpers) => {
    const _actions = helpers.getStoreActions();
    const { jobId } = payload || {};

    actions.setLoading(true);
    actions.setError(false);

    try {
      const res = await _actions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}`,
      });

      actions.setData(res as TJob);
    } catch (error) {
      actions.setError(true);
      utilityThrowError(
        error,
        'Unable to retrieve job details related to this job ID',
      );
    } finally {
      actions.setLoading(false);
    }
  }),
  shares: sharesStore,
  showcase: showcaseStore,
};
