import { ENDPOINTS } from './endpoints';
import { REACT_APP_HOST } from '../.configs/environment';

const handleResponse = ({ response }) => {
  return response.json().then(resp => {
    if ([401, 403].includes(response.status) || resp.error) {
      return Promise.reject({
        referents: resp.referents,
        type: 'unauthorized',
        locale: resp.locale || 'it',
      });
    }

    return Promise.resolve(resp);
  });
};

export default (method, endpoint, params, payload, replaceParams = null) => {
  const requestOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (method !== 'GET' && !!payload) {
    requestOptions.body = JSON.stringify(payload);
  }
  const getEndpoint = () => {
    var _endpoint = ENDPOINTS[endpoint];
    if (replaceParams) {
      Object.keys(replaceParams).forEach(key => {
        _endpoint = _endpoint.replace(`{${key}}`, replaceParams[key]);
      });
    }
    return _endpoint;
  };

  return fetch(
    `${REACT_APP_HOST}/api${getEndpoint()}${params || ''}`,
    requestOptions,
  )
    .then(response => {
      if (response.status === 204) {
        return new Promise(resolve =>
          resolve({
            status: response.status,
            statusText: response.statusText,
            data: null,
          }),
        );
      } else {
        return handleResponse({ response });
      }
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const utilityGetRequestQueryParams = queryParams => {
  const paramsKeys = Object.keys(queryParams);

  if (paramsKeys.length === 0) {
    return '';
  }

  const queryString = Object.keys(queryParams).reduce(
    (queryString, paramKey) => {
      const paramValue = queryParams[paramKey];

      if (paramValue === undefined) {
        return queryString;
      }

      if (Array.isArray(paramValue)) {
        paramValue.forEach(paramValueItem => {
          queryString.append(paramKey, paramValueItem);
        });

        return queryString;
      }

      queryString.append(paramKey, paramValue);

      return queryString;
    },
    new URLSearchParams(),
  );

  return !!queryString ? `?${queryString}` : '';
};
