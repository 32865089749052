import type { FC } from 'react';
import React, { useEffect } from 'react';
import {
  PageLoginBody,
  PageLoginHeader,
} from '../components/PagesFragments/Login';
import { LoaderFullPage } from '../loaders/LoaderFullPage';
import { APP_BASE_PATH } from '../constants/navigation';

export const PageLogin: FC = () => {
  const token = localStorage.getItem('token');

  const redirectPage =
    localStorage.getItem('EmployerView::authRedirectPath') ||
    `${APP_BASE_PATH}/`;

  useEffect(() => {
    if (token) {
      window.location.assign(redirectPage);
    }
  }, [token]);

  return token ? (
    <LoaderFullPage />
  ) : (
    <div className="c-page-login">
      <PageLoginHeader />
      <PageLoginBody />
    </div>
  );
};
