import {
  HomepageContext,
  HomepageProvider,
} from '../components/Providers/HomepageProvider';
import {
  HomepageCandidates,
  HomepageHeader,
  HomepageJobs,
} from '../components/Homepage';
import Footer from '../components/Footer/Footer';
import React from 'react';
import { HomepageLoader } from '../loaders/HomepageLoader';
import { Route, Switch } from 'react-router-dom';
import { APP_BASE_PATH } from '../constants/navigation';

export const Homepage = () => (
  <HomepageProvider>
    <HomepageContext.Consumer>
      {context =>
        context.isLoading ? (
          <HomepageLoader />
        ) : (
          <>
            <HomepageHeader />

            <Switch>
              <Route
                path={['/candidates', '/customers/:customerId?/candidates']}
              >
                <HomepageCandidates />
              </Route>

              <Route path={['/', '/customers/:customerId?']}>
                <HomepageJobs />
              </Route>
            </Switch>

            <div className="container">
              <Footer
                logo={`${APP_BASE_PATH}/assets/images/logo/reverse-group-logo.png`}
              />
            </div>
          </>
        )
      }
    </HomepageContext.Consumer>
  </HomepageProvider>
);
