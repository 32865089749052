import React from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadFileButton } from '../DownloadFileButton';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Curriculum from '../Curriculum';
import { GTM } from '../../gtm';
import PropTypes from 'prop-types';
import { jobCompanyNameSelector } from '../../model/Job/Job.selectors';
import { getDossierSelector } from '../../model/Candidate/Candidate.selectors';

export const CandidateDossier = ({
  candidateSummary,
  candidateSummaryDetails,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'candidateDetails.dossier',
  });

  const { jobId, candidateId } = useParams();
  const companyName = useStoreState(jobCompanyNameSelector);
  const getDossier = useStoreActions(getDossierSelector);

  const onDownloadDossierClick = async () => {
    try {
      return await getDossier({ candidateId, jobId });
    } finally {
      GTM.trackGTMEvent({
        action: 'dossier',
        label: `${jobId} - ${candidateId} - download`,
      });

      GTM.trackGA4Event(
        'dossier',
        companyName,
        `${jobId} - ${candidateId} - download`,
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col col--x-padded u-mt-space-40">
          <div className="o-antipodes u-py-space-32">
            <p className="o-antipodes__item typo-secondary-heading-05 u-color-primary-100">
              {t('title')}
            </p>

            <DownloadFileButton
              className="o-antipodes__item"
              onClick={onDownloadDossierClick}
              label={t('button')}
            />
          </div>

          <Curriculum
            modifier="c-curriculum--in-page"
            summary={candidateSummary}
            details={candidateSummaryDetails}
          />
        </div>
      </div>
    </div>
  );
};

CandidateDossier.propTypes = {
  candidateSummary: PropTypes.string,
  candidateCurriculumUrl: PropTypes.string,
  candidateSummaryDetails: PropTypes.object,
};
