import React from 'react';
import ReferentCard from './ReferentCard';

const getReferentCards = referents => {
  if (referents.length === 2) {
    const filteredReferents = referents.reduce((acc, curr) => {
      if (!acc.length) {
        acc.push(curr);
      } else {
        if (!acc.find(r => r?.lastName === curr?.lastName)) {
          acc.push(curr);
        } else {
          acc[0].jobTitle = curr.jobTitle;
        }
      }
      return acc;
    }, []);
    if (filteredReferents.length === 1) {
      return [
        {
          manager: {
            ...referents[1],
          },
        },
      ];
    } else {
      return referents.map(referent => ({ manager: referent }));
    }
  } else {
    return [{ manager: referents[0] }, { manager: { email: null } }];
  }
};

const ReferentCardsWrapper = ({ referents }) => {
  const referencesCards = getReferentCards(referents);

  return referencesCards.map((card, index) => {
    return (
      <div
        key={`reference-card-${index}`}
        className="o-cards-scrollable__item"
        data-testid={`reference-card-item`}
        id={`reference-card-${index}`}
      >
        <ReferentCard {...card} />
      </div>
    );
  });
};

ReferentCardsWrapper.defaultProps = {
  referents: [],
};

export default ReferentCardsWrapper;
