import type { TCandidateAddressLocation } from '../model/Candidate/Candidate.type';
import type { TLanguage, TLocationTranslation } from '../models/Common';
import { LANGUAGES } from '../models/Common';

interface TUtilityGetTranslatedLocationPayload {
  location: TCandidateAddressLocation | null;
  language?: TLanguage;
}

export const utilityGetTranslatedLocation = ({
  location,
  language = LANGUAGES.EN,
}: TUtilityGetTranslatedLocationPayload): TLocationTranslation | null => {
  if (!location) {
    return null;
  }

  const availableTranslations = location.translations.map(
    translation => translation.language,
  );

  const translationLanguage = getTranslationLanguage({
    availableTranslations,
    language,
  });

  const translatedLocation = location.translations.find(
    translation => translation.language === translationLanguage,
  );

  return translatedLocation || null;
};

const getTranslationLanguage = ({
  availableTranslations,
  language,
}: {
  availableTranslations: TLanguage[];
  language: TLanguage;
}): TLanguage | null => {
  if (availableTranslations.includes(language)) {
    return language;
  }

  if (availableTranslations.includes(LANGUAGES.EN)) {
    return LANGUAGES.EN;
  }

  return availableTranslations[0] || null;
};
