export const COMMON_ES = {
  formatting: {
    date: {
      daysAgo: 'hace {{ count }} días',
      daysAgo_zero: 'Hoy',
      daysAgo_one: 'el dia de ayer',

      month_1: 'enero',
      month_2: 'febrero',
      month_3: 'marzo',
      month_4: 'abril',
      month_5: 'mayo',
      month_6: 'junio',
      month_7: 'julio',
      month_8: 'agosto',
      month_9: 'septiembre',
      month_10: 'octubre',
      month_11: 'noviembre',
      month_12: 'diciembre',

      dateFormat_pretty:
        '{{- date, datetime(format: dd)}} $t(common.formatting.date.month_{{- date, datetime(format: M) }}) {{- date, datetime(format: yyyy)}}',
    },
  },

  downloads: {
    candidateDossierFallback:
      'Candidate Dossier - {{ firstName }} {{ lastName }}',
    candidateCommitmentLetterFallback:
      'Commitment Letter - {{ firstName }} {{ lastName }}',
    candidateResignationLetterFallback:
      'Resignation Letter - {{ firstName }} {{ lastName }}',
    candidateTechnicalReportFallback:
      'Candidate Technical Report - {{ firstName }} {{ lastName }}',
    signedContractFallback: 'Contract - {{ companyName }}',
  },
};
