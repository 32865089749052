import { AppIntroFlow } from '../AppIntroFlow';
import { Trans, useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PageStateContext } from '../Providers/PageStateProvider';
import { useStoreState } from 'easy-peasy';
import { Alert } from '@reverse-hr/pattern-library';
import classNames from 'classnames';
import { JOB_PAGE_STATES } from '../../model/Job/_constants/Job.constants';
import { candidatesSelector } from '../../model/Candidate/Candidate.selectors';
import { CANDIDATE_EVENT_TYPES } from '../../constants/candidate';
import { APP_BASE_PATH } from '../../constants/navigation';
import { IS_THEME_AKI } from '../../.configs/environment';

export const InterviewingHeader = () => {
  const { t } = useTranslation();
  const candidates = useStoreState(candidatesSelector);
  const pageState = useContext(PageStateContext);

  const isPageStateDoing = pageState === JOB_PAGE_STATES.DOING;

  const isAlertMessageVisible = checkAlertMessageVisibility({
    candidates: candidates.results,
    isPageStateDoing,
  });

  const isChatImgVisible = pageState === JOB_PAGE_STATES.DONE;

  const wrapperClassNames = classNames('col col--x-padded small-12', {
    'large-6': !isPageStateDoing,
    'large-9': isPageStateDoing,
  });

  const introFlowAdditionalClassNames = classNames({
    'c-app-intro-flow--full': isPageStateDoing,
  });

  const chatImageSrc = IS_THEME_AKI
    ? `${APP_BASE_PATH}/assets/images/aaron-king/chat.png`
    : `${APP_BASE_PATH}/assets/images/chat.png`;

  return (
    <div className={wrapperClassNames}>
      <AppIntroFlow
        title={t('interviewing.header.title')}
        body={t('interviewing.header.subtitle', { context: pageState })}
        className={introFlowAdditionalClassNames}
      />

      {isAlertMessageVisible && (
        <div className="u-mt-space-48">
          <Alert type="warning" icon="icn-warning-outline-24">
            <Trans
              i18nKey="interviewing.header.alert"
              components={{ strong: <strong /> }}
            />
          </Alert>
        </div>
      )}

      {isChatImgVisible && (
        <img
          data-testid="interviewing-page-img"
          className="u-mt-space-32"
          width="256"
          src={chatImageSrc}
          alt={t('interviewing.header.title')}
        />
      )}
    </div>
  );
};

const checkAlertMessageVisibility = ({ candidates, isPageStateDoing }) => {
  if (!isPageStateDoing) {
    return false;
  }

  return !!candidates.find(candidate => {
    if (candidate.discardedAt) {
      return false;
    }

    return !!candidate.events.find(event => {
      return event.type === CANDIDATE_EVENT_TYPES.OFFER_MADE;
    });
  });
};
