export const LANG_AKI_FR = {
  footer: {
    company: '© {{ year }} Aaron King International part of Reverse SpA',
    headquarter: 'Registered Office: Piazza Sigmund Freud 1, 20154 Milano',
    informations: [
      'VAT Number: 09714690964',
      'PEC: reversespa@pec.it',
      'Commercial registry: MI - 2109818',
      'Paid up share capital: 50.000€',
      'REA Number: MI - 2109818',
    ],
    companyBaseUrl: 'https://www.reverse.hr/fr/',
    menu: {
      contact: {
        label: 'Contact us',
        url: 'https://reverse.hr/fr/nous-contacter/',
      },
      legal: {
        label: 'Legal',
        url: 'https://www.reverse.hr/fr/legal',
      },
      cookies: {
        label: 'Cookies',
        url: 'https://www.iubenda.com/privacy-policy/19612468/cookie-policy',
      },
    },
  },
};
