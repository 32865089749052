import i18n from 'i18next';
import { format as dateFnsFormat } from 'date-fns';
import { initReactI18next } from 'react-i18next';
import { de, en, es, fr, it } from './lang/reverse';
import {
  LANG_AKI_DE,
  LANG_AKI_FR,
  LANG_AKI_EN,
  LANG_AKI_ES,
  LANG_AKI_IT,
} from './lang/aki';
import { APP_THEMES, IS_THEME_AKI } from './.configs/environment';

const resources = {
  it: {
    reverse: it.translation,
    aki: LANG_AKI_IT,
  },
  de: {
    reverse: de.translation,
    aki: LANG_AKI_DE,
  },
  en: {
    reverse: en.translation,
    aki: LANG_AKI_EN,
  },
  fr: {
    reverse: fr.translation,
    aki: LANG_AKI_FR,
  },
  es: {
    reverse: es.translation,
    aki: LANG_AKI_ES,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    supportedLngs: ['en', 'it', 'de', 'fr', 'es'],
    ns: [APP_THEMES.REVERSE, APP_THEMES.AKI],
    defaultNS: IS_THEME_AKI ? APP_THEMES.AKI : APP_THEMES.REVERSE,
    fallbackNS: 'reverse',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.services.formatter.add('lowercase', value => value.toLowerCase());

i18n.services.formatter.add('datetime', (value, _, options) =>
  dateFnsFormat(value, options.format),
);

export default i18n;
