import { NavLink } from 'react-router-dom';
import { Icon } from '@reverse-hr/pattern-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { GTM } from '../../gtm';
import { jobCompanyNameSelector } from '../../model/Job/Job.selectors';
import {
  BRAND_BASE_URL,
  REVERSE_GROUP_BRAND_SQUARE_LOGOS,
  BRAND_APP_BASE_PATH,
} from '../../constants/brand';
import { APP_BASE_PATH } from '../../constants/navigation';

export const JobCard = ({ job }) => {
  const { t } = useTranslation();
  const companyName = useStoreState(jobCompanyNameSelector);
  const baseUrl = BRAND_BASE_URL[job.reverseGroupCompany];
  const appBasePath = BRAND_APP_BASE_PATH[job.reverseGroupCompany];

  const wrapperClassNames = classNames('c-job-card', {
    'c-job-card--closed': job.closedOn,
  });

  const linkHref = `${baseUrl}${appBasePath}/jobs/${job.id}`;
  const jobStatusIcon = JOB_STATES_ICONS[job.status];

  const trackNavigatingToJob = () => {
    GTM.trackGTMEvent({
      action: EVENT_ACTION_NAVIGATION_TO_JOB,
      label: `${companyName} - ${job.id} - ${job.title}`,
    });

    GTM.trackGA4Event(
      EVENT_ACTION_NAVIGATION_TO_JOB,
      companyName,
      `${job.id} - ${job.title}`,
    );
  };

  return (
    <div className={wrapperClassNames}>
      <a
        className="c-job-card__link"
        href={linkHref}
        target="_blank"
        onClick={trackNavigatingToJob}
      >
        <div className="c-job-card__header">
          <p className="c-job-card__title">{job.title}</p>

          {!!job.city && (
            <p className="c-job-card__location">
              <Icon name="icn-location-outline-24" size={16} />
              {job.city}
            </p>
          )}
        </div>

        <div className="c-job-card__footer">
          <div className="c-job-card__footer-left">
            {!!jobStatusIcon && !job.closedOn && (
              <Icon
                modifier="c-job-card__state-icon"
                name={jobStatusIcon}
                size={20}
              />
            )}

            {t('homepage.jobList.cardState', {
              context: job.closedOn ? 'closed' : job.status,
              date: job.closedOn ? new Date(job.closedOn) : null,
            })}
          </div>

          <div className="c-job-card__footer-right">
            <img
              src={`${APP_BASE_PATH}${
                REVERSE_GROUP_BRAND_SQUARE_LOGOS[job.reverseGroupCompany]
              }`}
              alt={job.reverseGroupCompany}
              width={20}
              height={20}
            />
          </div>
        </div>
      </a>
    </div>
  );
};

const JOB_STATES = {
  SEARCHING: 'searching',
  INTERVIEWING: 'interviewing',
  CLOSING: 'closing',
};

const JOB_STATES_ICONS = {
  [JOB_STATES.SEARCHING]: 'icn-search-small-24',
  [JOB_STATES.INTERVIEWING]: 'icn-chat-24',
  [JOB_STATES.CLOSING]: 'icn-flag-outline-24',
};

const EVENT_ACTION_NAVIGATION_TO_JOB = `Navigazione verso il dettaglio di una job`;
