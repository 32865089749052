import { Trans, useTranslation } from 'react-i18next';
import { AppIntroFlow } from '../AppIntroFlow';
import { Pagination, Table } from '@reverse-hr/pattern-library';
import { useTable } from './use-table';
import { useApplications } from './use-applications';
import { Select } from '../Select';
import classNames from 'classnames';
import { CUSTOMER_APPLICATIONS_STATE_CATEGORIES } from '../../constants/applications';

export const CustomerApplications = ({
  stateCategory,
  jobId,
  isStateFilterAvailable,
}) => {
  const { t } = useTranslation();

  const {
    applications,
    pagination,
    stateSelectOptions,
    sortBy,
    sortDirection,
    isLoading,
    hasError,
    onPageChange,
    onColumnHeaderClick,
    onStateChange,
  } = useApplications({ stateCategory, jobId });

  const { tableColumns, tableRows } = useTable({
    stateCategory,
    applications,
  });

  const tableClassNames = classNames('c-customer-applications__table', {
    'c-customer-applications__table--active':
      stateCategory === CUSTOMER_APPLICATIONS_STATE_CATEGORIES.ACTIVE,
    'c-customer-applications__table--stopped':
      stateCategory === CUSTOMER_APPLICATIONS_STATE_CATEGORIES.STOPPED,
  });

  const isPaginationVisible = pagination.total_pages > 1 && !isLoading;

  return (
    <div className="c-customer-applications">
      <AppIntroFlow
        className="c-app-intro-flow--full c-app-intro-flow--text-reverse"
        title={t('homepage.candidates.title', { context: stateCategory })}
        body={
          <Trans
            i18nKey="homepage.candidates.intro"
            components={{ strong: <strong />, br: <br /> }}
            context={stateCategory}
          />
        }
      />

      {isStateFilterAvailable && (
        <Select
          options={stateSelectOptions}
          defaultValue={stateSelectOptions[0]}
          onChange={onStateChange}
        />
      )}

      <Table
        className={tableClassNames}
        columns={tableColumns}
        rows={tableRows}
        onColumnHeaderClick={onColumnHeaderClick}
        isLoading={isLoading}
        sortOrder={sortDirection}
        sortColumn={sortBy}
        emptyTableContent={t('homepage.candidates.table.noApplications', {
          context: hasError ? 'error' : null,
        })}
      />

      {isPaginationVisible && (
        <div className="c-customer-applications__pagination">
          <Pagination pagination={pagination} onPageChange={onPageChange} />
        </div>
      )}
    </div>
  );
};
