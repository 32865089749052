import PropTypes from 'prop-types';
import classNames from 'classnames';

export const AppIntroFlow = ({ title, label, body, className }) => {
  const wrapperClassNames = classNames('c-app-intro-flow', className);

  return (
    <div className={wrapperClassNames}>
      <p className="c-app-intro-flow__title">{title}</p>

      {!!label && <p className="c-app-intro-flow__label">{label}</p>}
      {!!body && <p className="c-app-intro-flow__body">{body}</p>}
    </div>
  );
};
