import { useStoreState } from 'easy-peasy';
import { AppIntroFlow } from '../AppIntroFlow';
import { useTranslation } from 'react-i18next';
import ReferentCardsWrapper from '../ReferentCardsWrapper';
import { jobStoreSelector } from '../../model/Job/Job.selectors';

export const Referents = () => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector);

  return job ? (
    <>
      <AppIntroFlow title={t('profile.references.title')} />

      <div className="o-cards-scrollable">
        <ReferentCardsWrapper
          referents={[job.salesConsultant, job.recruitmentConsultant]}
        />
      </div>
    </>
  ) : null;
};
