import type { ActionCreator, Actions, State, ThunkCreator } from 'easy-peasy';
import type { StoreModel } from '../../store/types/store-model';
import type { TJob, TJobClient, TJobShare } from './Job.type';
import type { TJobProgressSegment } from '../../components/navigation/JobProgressSwitch/JobProgressSwitch.types';
import type {
  TSharesDeletePayload,
  TSharesPostPayload,
} from './shares/Shares.type';
import { CONTRACT_JOB } from './_constants/Job.constants';

export const jobStoreSelector = (state: State<StoreModel>): TJob | null => {
  if (state.job.data) {
    return state.job.data;
  }

  const contract = state.contract.data;

  if (!contract) {
    return null;
  }

  return {
    ...CONTRACT_JOB,
    client: contract.client,
    companyName: contract.companyName,
    contractId: contract.id,
    id: contract.id,
    recruitmentConsultant: contract.salesConsultant,
    reverseGroupCompany: contract.reverseGroupCompany,
    title: contract.title,
  };
};

export const jobIdSelector = (state: State<StoreModel>): string | null => {
  if (state.job.currentJobId) {
    return state.job.currentJobId;
  }

  return state.contract.data?.id ?? null;
};

export const jobActualStoreSelector = (state: State<StoreModel>): TJob | null =>
  state.job.data;

export const jobLoadingSelector = (state: State<StoreModel>): boolean =>
  state.job.loading;

export const jobProgressSelector = (
  state: State<StoreModel>,
): TJobProgressSegment | null => state.job.progress;

// Action Getters
export const setCurrentJobIdSelector = (
  state: Actions<StoreModel>,
): ActionCreator<string> => state.job.setCurrentJobId;

export const setJobProgressSelector = (
  state: Actions<StoreModel>,
): ActionCreator<TJobProgressSegment> => state.job.setJobProgress;

export const jobSharesSelector = (state: State<StoreModel>): TJobShare[] =>
  state.job.data?.shares ?? [];

export const jobClientSelector = (
  state: State<StoreModel>,
): TJobClient | null => {
  if (state.job.data?.client) {
    return state.job.data?.client;
  }

  return state.contract.data?.client ?? null;
};

export const jobCompanyNameSelector = (
  state: State<StoreModel>,
): string | null => state.job.data?.companyName ?? null;

// Thunk actions
export const getJobSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<{ jobId: string }, TJob> => state.job.getJob;

export const postJobShareSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TSharesPostPayload, TJob> => state.job.shares.postShares;

export const deleteJobShareSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TSharesDeletePayload, TJob> => state.job.shares.deleteShares;
