import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Range } from '@reverse-hr/pattern-library';
import { jobStoreSelector } from '../../../../../model/Job/Job.selectors';

const Salary = props => {
  const { salary } = props;
  const job = useStoreState(jobStoreSelector);
  const { maximumSalary, minimumSalary } = job || {};

  const rangeRemappedStatuses = {
    left: 'positive',
    right: 'warning',
    center: 'positive',
  };

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--center-aligned">
      {!!salary ? (
        <Range
          min={Math.round(minimumSalary / 1000)}
          max={Math.round(maximumSalary / 1000)}
          value={Math.round(salary / 1000)}
          mappedStatuses={rangeRemappedStatuses}
        />
      ) : null}
    </td>
  );
};

export default Salary;
