import { useStoreState } from 'easy-peasy';
import { AppNumbersRow } from './AppNumbersRow';
import { jobStoreSelector } from '../../model/Job/Job.selectors';
import { useMemo } from 'react';

export const AppNumbers = () => {
  const job = useStoreState(jobStoreSelector);

  const {
    atLeastInterviewedProfiles,
    atLeastContactedProfiles,
    notAGoodMatchProfiles,
    profilesWhoAgreedToJobInterview,
    interestedProfilesAfterJobInterviewWithBrandConcerns,
    interestedProfilesAfterJobInterviewWithCompensationConcerns,
    interestedProfilesAfterJobInterviewWithLocationConcerns,
    interestedProfilesAfterJobInterviewWithRoleConcerns,
    interestedProfilesAfterJobInterviewWithoutConcerns,
    presentedProfilesWithConcerns,
    approvedProfilesWithConcerns,
    approvedProfiles,
    presentedProfiles,
  } = job.statistics;

  const jobStatsRows = useMemo(() => {
    const interestedProfilesCountSum =
      approvedProfiles +
      interestedProfilesAfterJobInterviewWithoutConcerns +
      presentedProfiles +
      profilesWhoAgreedToJobInterview;

    const interestedProfilesCount =
      interestedProfilesCountSum -
      approvedProfilesWithConcerns -
      presentedProfilesWithConcerns;

    return [
      {
        profilesCount: atLeastContactedProfiles - notAGoodMatchProfiles,
        profilesWithJobInterviewReportCount: atLeastInterviewedProfiles,
        interestedProfilesCount,
      },
      {
        interestedWithConcerns: [
          {
            value: interestedProfilesAfterJobInterviewWithBrandConcerns,
            reason: 'brand',
          },
          {
            value: interestedProfilesAfterJobInterviewWithCompensationConcerns,
            reason: 'compensation',
          },
          {
            value: interestedProfilesAfterJobInterviewWithLocationConcerns,
            reason: 'location',
          },
          {
            value: interestedProfilesAfterJobInterviewWithRoleConcerns,
            reason: 'role',
          },
        ],
      },
    ];
  }, [job.statistics]);

  return (
    <>
      {jobStatsRows.map((row, rowIdx) => (
        <AppNumbersRow row={row} key={rowIdx} isFirstRow={!rowIdx} />
      ))}
    </>
  );
};
