import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { NavLink, useParams } from 'react-router-dom';

import { Icon } from '@reverse-hr/pattern-library';
import {
  jobProgressSelector,
  jobStoreSelector,
} from '../model/Job/Job.selectors';

const Stepper = ({ modifier, steps, onRouteChange }) => {
  const job = useStoreState(jobStoreSelector) || {};
  const jobProgress = useStoreState(jobProgressSelector);
  const { entityType, entityId: jobId, segment } = useParams();

  const getMappedSteps = steps => {
    let currentIndex = 0;
    steps.forEach((s, i) => {
      if (s.name === jobProgress) {
        currentIndex = i;
      }
    });

    return steps.map((step, index) => {
      const current = step.name === segment;
      const done = job.closedAt || index < currentIndex;

      return {
        ...step,
        url: `/${entityType}/${jobId}/${step.name}`,
        icon: step.icon,
        done,
        current,
      };
    });
  };
  const [mappedSteps, setMappedSteps] = useState(null);

  useEffect(() => {
    const mappedSteps = getMappedSteps(steps);
    let filterCallback = step => {
      return job.isShowcaseEnabled ? step : step.name !== 'showcase';
    };
    const filteredMappedSteps = mappedSteps.filter(filterCallback);
    setMappedSteps(filteredMappedSteps);
  }, [steps, job.isShowcaseEnabled]);

  const handleAnalytics = step => {
    onRouteChange(step);
  };

  return (
    <div className={`c-app-stepper ${modifier}`}>
      <ul className="c-app-stepper__list">
        {mappedSteps
          ? mappedSteps.map((step, index) => {
              const active = step.name === segment;
              return (
                <NavLink
                  data-testid={`stepper-step-link-${index}`}
                  key={`stepper-link-${index}`}
                  to={step.url}
                  onClick={() => handleAnalytics(step)}
                >
                  <li
                    key={`step-${index}`}
                    className={`c-app-stepper__label ${
                      active ? 'c-app-stepper__label--active' : ''
                    } ${
                      !active && step.done ? 'c-app-stepper__label--done' : ''
                    }`}
                  >
                    <div className="c-app-stepper__anchor">
                      <span
                        data-testid={`stepper-step-icon-${index}`}
                        className="c-app-stepper__icon"
                      >
                        <Icon name={step.icon} />
                      </span>
                      <div>
                        <p
                          data-testid={`stepper-step-title-${index}`}
                          className="c-app-stepper__primary-text"
                        >
                          {step.title}
                        </p>
                        <p
                          data-testid={`stepper-step-description-${index}`}
                          className="c-app-stepper__secondary-text"
                        >
                          {step.description}
                        </p>
                      </div>
                    </div>
                  </li>
                </NavLink>
              );
            })
          : null}
      </ul>
    </div>
  );
};

Stepper.defaultProps = {
  modifier: '',
  steps: [],
};

export default Stepper;
