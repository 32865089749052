import { useEffect } from 'react';

export interface TUseDownloadFileProps {
  url: string;
  filename: string;
  cb: () => void;
}

export const useDownloadFile = (props: TUseDownloadFileProps): void => {
  const { url, filename, cb } = props;

  useEffect(() => {
    if (url && filename) {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      cb();
    }
  }, [url, filename, cb]);
};
