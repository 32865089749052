import { Card, LinkWrapper, Pictogram } from '@reverse-hr/pattern-library';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  downloadDataSelector,
  getCandidateCommitmentLetterSelector,
  getCandidateResignationLetterSelector,
  setDownloadDataSelector,
} from '../../model/Candidate/Candidate.selectors';
import { useDownloadFile } from '../../utils/hooks/use-download-file';
import {
  CANDIDATES_SEGMENT,
  SUCCESS_FEE_SEGMENT,
} from '../../constants/navigation.constants';

export const ClosingHiredCard = ({ closing, jobId }) => {
  const { t } = useTranslation();
  const downloadData = useStoreState(downloadDataSelector);
  const setDownloadData = useStoreActions(setDownloadDataSelector);

  useDownloadFile({
    url: downloadData?.url,
    filename: downloadData?.filename,
    cb: () => setDownloadData(null),
  });

  const getResignationLetter = useStoreActions(
    getCandidateResignationLetterSelector,
  );
  const getCommitmentLetter = useStoreActions(
    getCandidateCommitmentLetterSelector,
  );

  const handleDownload = async handleDownloadThunk => {
    return await handleDownloadThunk({
      candidateId: closing.id,
      jobId,
    });
  };

  return (
    <div>
      <Card modifier="u-card-fixed">
        {{
          head: {
            left: (
              <div className="c-card__titles">
                <div className="titles-texts">
                  <p className="c-card__title">
                    {t('common.formatting.fullName', {
                      firstName: closing.firstName,
                      lastName: closing.lastName,
                    })}
                  </p>
                </div>
              </div>
            ),
            right: (
              <Link
                to={`/jobs/${jobId}/candidates/${closing.id}`}
                component={LinkWrapper}
                type="raw"
                shadow={false}
                icon="icn-id-card-24"
                iconOnly
                external={false}
              />
            ),
          },
          body: (
            <>
              {!!closing.offer.hasCommitmentLetter && (
                <LetterLink
                  onClick={() => handleDownload(getCommitmentLetter)}
                  label={t('closing.links.commitment_letter')}
                />
              )}

              {!!closing.offer.hasResignationLetter && (
                <LetterLink
                  onClick={() => handleDownload(getResignationLetter)}
                  label={t('closing.links.resignation_letter')}
                />
              )}
            </>
          ),
          actions: (
            <>
              {!!closing.offer.successFeeRecap && (
                <Link
                  to={`/jobs/${jobId}/${CANDIDATES_SEGMENT}/${closing.id}/${SUCCESS_FEE_SEGMENT}`}
                  component={LinkWrapper}
                  type="outline"
                  label={t('closing.links.prospect')}
                  icon="icn-chevron-big-right-24"
                  iconPosition="right"
                />
              )}
            </>
          ),
        }}
      </Card>
    </div>
  );
};

const LetterLink = ({ url, label, onClick }) => (
  <div>
    <button
      type="unstyled"
      external
      onClick={onClick}
      target="_blank"
      className="u-card-fixed__letter-button"
    >
      <Pictogram icon="icn-download-24" size="small" children={label} />
    </button>
  </div>
);
