import type { ActionCreator, Actions, State, ThunkCreator } from 'easy-peasy';
import type { StoreModel } from '../../store/types/store-model';
import type {
  TGetContractPayload,
  TContractStore,
  TSignedContractData,
} from './Contract.type';

/**
 * Selectors
 */
export const contractStoreStateSelector = (
  state: State<StoreModel>,
): TContractStore['data'] => state.contract.data;

export const signedContractDataSelector = (
  state: State<StoreModel>,
): TSignedContractData | null => state.contract.signedContractData;

/**
 * Actions and Thunk selectors
 */
export const getSignedContractSelector = (
  actions: Actions<StoreModel>,
): ThunkCreator<TGetContractPayload, TSignedContractData> =>
  actions.contract.getSignedContract;

export const setSignedContractDataSelector = (
  actions: Actions<StoreModel>,
): ActionCreator<TSignedContractData> => actions.contract.setSignedContractData;
