import type { TDealEntity, TDealEntityStore } from './DealEntity.type';
import { action, thunk } from 'easy-peasy';

export const dealEntityStore: TDealEntityStore = {
  data: null,
  error: null,
  isLoading: false,

  setDealEntity: action((state, dealEntity) => {
    state.data = dealEntity;
  }),

  setError: action((state, error) => {
    state.error = error;
  }),

  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading;
  }),

  getDealEntity: thunk(async (actions, { dealId, applicationId }, helpers) => {
    const _storeActions = helpers.getStoreActions();

    actions.setIsLoading(true);
    actions.setError(null);

    const endpointSegments = [dealId, applicationId]
      .filter(segment => Boolean(segment))
      .join('/');

    try {
      const response = await _storeActions.session.authFetch({
        endpoint: `/v1/employer-view/ids/${endpointSegments}`,
      });

      actions.setDealEntity(response as TDealEntity);
    } catch (error: unknown) {
      actions.setError(error);
    } finally {
      actions.setIsLoading(false);
    }
  }),
};
