import React from 'react';
import { Avatar } from '@reverse-hr/pattern-library';

const Manager = ({ modifier, manager, reversed }) => {
  return (
    <div
      className={`c-app-user ${modifier} ${
        reversed ? 'c-app-user--reversed' : ''
      }`}
    >
      <div className="c-app-user__avatar">
        <Avatar avatar={manager.avatarUrl} size={36} />
      </div>

      <div className="c-app-user__info">
        <p className="primary-txt">{`${manager.firstName} ${manager.lastName}`}</p>
        <p className="secondary-txt">{manager.jobTitle}</p>
      </div>
    </div>
  );
};

Manager.defaultProps = {
  modifier: '',
  reversed: false,
  manager: {},
};

export default Manager;
