import { action, thunk } from 'easy-peasy';
import TableRows from '../pages/stepper/showcase/constants/table-rows';
import { getApplicationsSortedByState } from '../pages/stepper/showcase/helpers/sort-candidates';

const reorderList = (list, startPositionId, landingPositionId) => {
  const startPosition = list.findIndex(
    listItem => listItem.id === startPositionId,
  );
  const landingPosition = list.findIndex(
    listItem => listItem.id === landingPositionId,
  );
  const [movedItem] = list.splice(startPosition, 1);

  list.splice(landingPosition, 0, movedItem);
  return list;
};

const hideListItem = (list, itemId) => {
  return list.map(listItem => {
    if (itemId === listItem.id) {
      listItem.isVisible = false;
    }

    return listItem;
  });
};

const appendNewItemsToList = (outputList, item) => {
  const isAlreadyInOutputList = outputList.find(
    outputListItem => outputListItem.id === item.id,
  );
  if (!!isAlreadyInOutputList) return outputList;

  return [
    ...outputList,
    {
      id: item.id,
      isVisible: true,
    },
  ];
};

const showcaseTableModel = {
  rows: [],
  columns: [],
  areDiscardedVisible: false,
  areInterestedButVisible: false,
  isEditedByUser: false,
  lastSaveDate: null,
  isConfigurationLoaded: false,

  initTable: thunk((actions, payload, { getStoreState }) => {
    const {
      job,
      candidates: { candidates },
    } = getStoreState();
    const showcaseConfiguration = job.data?.showcaseConfiguration;

    if (!candidates) {
      actions.setIsConfigurationLoaded(true);
      return;
    }

    actions.setRows(showcaseConfiguration?.attributes);
    actions.setColumns({
      candidates: candidates.results,
      showcaseConfiguration: showcaseConfiguration?.candidates,
    });
    actions.setInterestedButVisibility(
      showcaseConfiguration?.showCandidatesWithConcerns || false,
    );
    actions.setDiscardedVisibility(
      showcaseConfiguration?.showDiscardedCandidates || false,
    );

    actions.setIsEditedByUser(false);
    actions.setIsConfigurationLoaded(true);
  }),

  saveTableStatus: thunk(
    async (actions, payload, { getState, getStoreState, getStoreActions }) => {
      const { job } = getStoreState();
      const jobId = job.data?.id;
      const {
        session: { authFetch },
      } = getStoreActions();
      const { rows, columns, areDiscardedVisible, areInterestedButVisible } =
        getState();

      const requestPayload = {
        candidates: columns.map(column => ({
          id: column.id,
          isVisible: column.isVisible,
        })),
        attributes: rows.map(row => ({
          name: row.id,
          isVisible: row.isVisible,
        })),
        showCandidatesWithConcerns: areInterestedButVisible,
        showDiscardedCandidates: areDiscardedVisible,
      };

      try {
        await authFetch({
          endpoint: `/v1/employer-view/jobs/${jobId}/showcase-configuration`,
          requestOptions: {
            method: 'PUT',
            body: JSON.stringify(requestPayload),
          },
        });

        actions.setLastSaveDate(new Date());
      } catch (error) {
        console.error(error);
      }
    },
  ),

  resetTable: thunk(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      const { candidates, job } = getStoreState();
      const _actions = getStoreActions();
      const jobId = job.data?.id;
      const {
        session: { authFetch },
      } = getStoreActions();

      actions.setIsEditedByUser(false);
      actions.clearFilters(candidates.candidates.results);

      try {
        await _actions.job.showcase.deleteShowcase({ jobId });
        actions.setLastSaveDate(null);
      } catch (error) {
        console.error(error);
      }
    },
  ),

  setRows: action((state, showcaseConfiguration = []) => {
    const convertedConfiguration = showcaseConfiguration.map(row => ({
      ...row,
      id: row.name,
    }));
    const configurationWithNewAttributes = TableRows.reduce(
      appendNewItemsToList,
      convertedConfiguration,
    );

    state.rows = configurationWithNewAttributes.map(attribute => {
      const tableRowConfiguration = TableRows.find(
        tableRow => tableRow.id === attribute.id,
      );

      return {
        ...attribute,
        hasVisibilityAction: tableRowConfiguration?.hasVisibilityAction,
      };
    });
  }),

  setColumns: action((state, { candidates, showcaseConfiguration = [] }) => {
    const sortedApplications = getApplicationsSortedByState(candidates);
    const configurationWithNewApplications = sortedApplications.reduce(
      appendNewItemsToList,
      showcaseConfiguration,
    );

    state.columns = configurationWithNewApplications.reduce(
      (outputColumns, column) => {
        const currentApplication = sortedApplications.find(
          application => application.id === column.id,
        );
        if (!currentApplication) return outputColumns;

        return [
          ...outputColumns,
          {
            candidateId: currentApplication.id,
            id: String(column.id),
            isVisible: column.isVisible,
            isDiscarded: !!currentApplication.discardedAt,
            isInterestedBut: currentApplication.concerns !== null,
          },
        ];
      },
      [],
    );
  }),

  setDiscardedVisibility: action((state, areDiscardedVisible) => {
    state.isEditedByUser = true;
    state.areDiscardedVisible = areDiscardedVisible;
  }),

  setInterestedButVisibility: action((state, areInterestedButVisible) => {
    state.isEditedByUser = true;
    state.areInterestedButVisible = areInterestedButVisible;
  }),

  clearFilters: action((state, candidates) => {
    const sortedApplications = getApplicationsSortedByState(candidates);

    state.areDiscardedVisible = false;
    state.areInterestedButVisible = false;

    state.columns = sortedApplications.map(application => ({
      id: String(application.id),
      isVisible: true,
      isDiscarded: !!application.discardedAt,
      isInterestedBut: application.concerns !== null,
    }));

    state.rows = TableRows.map(row => ({
      ...row,
      isVisible: true,
    }));
  }),

  setRowsOrder: action((state, { startPositionId, landingPositionId }) => {
    const newRowsOrder = [...state.rows];
    state.isEditedByUser = true;
    state.rows = reorderList(newRowsOrder, startPositionId, landingPositionId);
  }),

  setColumnsOrder: action((state, { startPositionId, landingPositionId }) => {
    const newColumnsOrder = [...state.columns];
    state.isEditedByUser = true;
    state.columns = reorderList(
      newColumnsOrder,
      startPositionId,
      landingPositionId,
    );
  }),

  hideColumn: action((state, columnId) => {
    state.isEditedByUser = true;
    state.columns = hideListItem([...state.columns], columnId);
  }),

  hideRow: action((state, rowName) => {
    state.isEditedByUser = true;
    state.rows = hideListItem([...state.rows], rowName);
  }),

  setIsEditedByUser: action((state, isEditedByUser) => {
    state.isEditedByUser = isEditedByUser;
  }),

  setLastSaveDate: action((state, lastSaveDate) => {
    state.lastSaveDate = lastSaveDate;
  }),

  setIsConfigurationLoaded: action((state, isConfigurationLoaded) => {
    state.isConfigurationLoaded = isConfigurationLoaded;
  }),
};

export default showcaseTableModel;
