import { useHistory, useParams } from 'react-router-dom';
import type {
  TJobProgressSegment,
  TJobProgressSwitchParams,
} from '../JobProgressSwitch.types';
import { JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES } from '../JobProgressSwitch.types';
import { useStoreState } from '../../../../store/hooks';
import { selectorRootState } from '../../../../model';
import { JOB_PROGRESS_STATES } from '../../../../model/Job/_constants/Job.constants';
import { useEffect } from 'react';
import { contractStoreStateSelector } from '../../../../model/Contract/Contract.selectors';

export interface JobProgressSwitchNotFoundProps {
  progress: TJobProgressSegment | null;
}

export const JobProgressSwitchNotFound = ({
  progress,
}: JobProgressSwitchNotFoundProps): null => {
  const { initialized: isInitialized } = useStoreState(selectorRootState);
  const contract = useStoreState(contractStoreStateSelector);
  const { entityType, entityId } = useParams<TJobProgressSwitchParams>();
  const history = useHistory();

  const redirectPageSegment = progress ?? JOB_PROGRESS_STATES.PROFILING;

  const isRedirectActive =
    !contract?.jobId ||
    entityType !== JOB_PROGRESS_SWITCH_SEGMENT_ENTITIES.CONTRACTS;

  useEffect(() => {
    if (isInitialized && isRedirectActive) {
      history.push(`/${entityType}/${entityId}/${redirectPageSegment}`);
    }
  }, [isInitialized, isRedirectActive]);

  return null;
};
