import type { ActionCreator, Actions, State, ThunkCreator } from 'easy-peasy';
import type { StoreModel } from '../../store/types/store-model';
import type {
  TCandidate,
  TCandidates,
  TFeedbackMessage,
  TGetCandidatePayload,
  TGetCandidatesPayload,
} from './Candidate.type';
import type {
  TDownloadResponseData,
  TDownloadsPayload,
} from './downloads/Downloads.type';
import type { TFeedbackMessagePostPayload } from './feedback-messages/FeedbackMessages.type';

export const candidatesSelector = (state: State<StoreModel>): TCandidates =>
  state.candidates.candidates;

export const candidatesLoadingSelector = (state: State<StoreModel>): boolean =>
  state.candidates.loading;

export const candidateDetailLoadingSelector = (
  state: State<StoreModel>,
): boolean => state.candidates.candidateDetailLoading;

export const candidatesResultSelector = (
  state: State<StoreModel>,
): TCandidate[] => state.candidates.candidates.results;

export const candidateSelector = (
  state: State<StoreModel>,
): TCandidate | null => state.candidates.candidate;

export const candidateLoadingSelector = (state: State<StoreModel>): boolean =>
  state.candidates.loading;

export const candidateErrorSelector = (state: State<StoreModel>): unknown =>
  state.candidates.error;

export const downloadDataSelector = (
  state: State<StoreModel>,
): TDownloadResponseData | null => state.candidates.downloads.downloadData;

export const candidateFeedbacksSelector = (
  state: State<StoreModel>,
): TFeedbackMessage[] => state.candidates.candidate?.feedbackMessages || [];

export const feedbacksLoadingSelector = (state: State<StoreModel>): boolean =>
  state.candidates.feedbackMessages.feedbacksLoading;

export const curriculumUrlSelector = (
  state: State<StoreModel>,
): string | null => state.candidates.downloads.curriculumUrl;

/** Actions and Thunk selectors */
export const getCandidatesSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TGetCandidatesPayload, TCandidate[]> =>
  state.candidates.getCandidates;

export const getCandidateSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TGetCandidatePayload, TCandidate[]> =>
  state.candidates.getCandidate;

export const getCandidateCommitmentLetterSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TDownloadsPayload, TCandidate[]> =>
  state.candidates.downloads.getCommitmentLetter;

export const getCandidateResignationLetterSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TDownloadsPayload, TCandidate[]> =>
  state.candidates.downloads.getResignationLetter;

export const setDownloadDataSelector = (
  state: Actions<StoreModel>,
): ActionCreator<TDownloadResponseData | null> =>
  state.candidates.downloads.setDownloadData;

export const postFeedbackMessageSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TFeedbackMessagePostPayload, any> =>
  state.candidates.feedbackMessages.postFeedbackMessage;

export const setFeedbacksLoadingSelector = (
  state: Actions<StoreModel>,
): ActionCreator<boolean> =>
  state.candidates.feedbackMessages.setFeedbacksLoading;

export const getDossierSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TDownloadsPayload, TCandidate[]> =>
  state.candidates.downloads.getDossier;

export const putClientFeedbackSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TDownloadsPayload, TCandidate[]> =>
  state.candidates.clientFeedback.putClientFeedback;

export const getTechnicalReportSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TDownloadsPayload, TCandidate[]> =>
  state.candidates.downloads.getTechnicalReport;

export const getCurriculumSelector = (
  state: Actions<StoreModel>,
): ThunkCreator<TDownloadsPayload, TCandidate[]> =>
  state.candidates.downloads.getCurriculum;
