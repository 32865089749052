import { DownloadFileButton } from '../DownloadFileButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@reverse-hr/pattern-library';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  transformHtmlInJSX,
  transformNewlinesInBrTags,
} from '../../utils/string-transformations';
import { GTM } from '../../gtm';
import { jobCompanyNameSelector } from '../../model/Job/Job.selectors';
import { getTechnicalReportSelector } from '../../model/Candidate/Candidate.selectors';

export const ScoutReport = ({ scoutReport }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'candidateDetails.scout',
  });

  const { candidateId, jobId } = useParams();
  const companyName = useStoreState(jobCompanyNameSelector);
  const getTechnicalReport = useStoreActions(getTechnicalReportSelector);

  const trackScoutReportEvent = eventType => {
    GTM.trackGTMEvent({
      action: 'report_scout',
      label: `${jobId} - ${candidateId} - ${eventType}`,
    });

    GTM.trackGA4Event(
      'report_scout',
      companyName,
      `${jobId} - ${candidateId} - ${eventType}`,
    );
  };

  const onDownloadReportClick = async () => {
    try {
      return await getTechnicalReport({ candidateId, jobId });
    } finally {
      trackScoutReportEvent('download');
    }
  };

  const onAccordionToggle = reportType => trackScoutReportEvent(reportType);

  return (
    <div className="container">
      <div className="row">
        <div className="col col--x-padded u-mt-space-40">
          <div className="o-antipodes u-py-space-32">
            <p className="o-antipodes__item typo-secondary-heading-05 u-color-primary-100">
              {t('title')}
            </p>

            <DownloadFileButton
              className="o-antipodes__item"
              onClick={onDownloadReportClick}
              label={t('button')}
            />
          </div>
        </div>

        {SCOUT_REPORT_TYPE_LIST.map(reportType => (
          <div
            key={reportType}
            className="col col--x-padded small-12 large-6 u-pb-space-16"
          >
            <Accordion
              id={reportType}
              title={t('accordionTitle', { context: reportType })}
              labels={{
                open: t('accordionOpen'),
                close: t('accordionClose'),
              }}
              onToggle={() => onAccordionToggle(reportType)}
            >
              {transformHtmlInJSX(
                transformNewlinesInBrTags(scoutReport[reportType]),
              )}
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

ScoutReport.propTypes = {
  scoutReport: PropTypes.shape({
    skillsAssessment: PropTypes.string.isRequired,
    overallAssessment: PropTypes.string.isRequired,
  }),
};

const SCOUT_REPORT_TYPES = {
  SKILLS_ASSESSMENT: 'skillsAssessment',
  OVERALL_ASSESSMENT: 'overallAssessment',
};

const SCOUT_REPORT_TYPE_LIST = Object.values(SCOUT_REPORT_TYPES);
