import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays } from 'date-fns';
import classNames from 'classnames';
import { PageStateContext } from '../Providers/PageStateProvider';
import { useContext } from 'react';
import { jobStoreSelector } from '../../model/Job/Job.selectors';

export const AppDaysInfo = ({ className }) => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector);
  const pageState = useContext(PageStateContext);

  const wrapperClassNames = classNames('c-app-days-info', className);

  const daysUntil = job.presentationMeetingScheduledAt
    ? differenceInCalendarDays(
        new Date(job.presentationMeetingScheduledAt.utcDateTime),
        new Date(),
      )
    : t('searching.daysCard.daysNumber');

  return (
    <div className={wrapperClassNames}>
      <p className="c-app-days-info__label">
        {t('searching.daysCard.intro', { context: pageState })}
      </p>

      <div className="c-app-days-info__days">
        <p className="c-app-days-info__number">{daysUntil}</p>

        <p className="c-app-days-info__days-label">
          {t('searching.daysCard.days', { count: daysUntil })}
        </p>
      </div>

      <p className="c-app-days-info__body">
        {t('searching.daysCard.body', { context: pageState })}
      </p>
    </div>
  );
};
