import { useEffect } from 'react';
import { utilityGetPageState } from '../../../../utils/get-page-state';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import {
  jobActualStoreSelector,
  jobProgressSelector,
  setCurrentJobIdSelector,
} from '../../../../model/Job/Job.selectors';
import { contractStoreStateSelector } from '../../../../model/Contract/Contract.selectors';
import {
  setCurrentRouteSelector,
  setCurrentStatusSelector,
} from '../../../../store/selectors/common-selectors';
import { useHistory, useParams } from 'react-router-dom';
import type { TJobProgressSwitchParams } from '../JobProgressSwitch.types';
import { APP_BASE_PATH } from '../../../../constants/navigation';

export const useEntityNavigation = (): void => {
  // STATE
  const initialized: boolean = useStoreState(state => state.initialized);
  const job = useStoreState(jobActualStoreSelector);
  const contract = useStoreState(contractStoreStateSelector);
  const jobProgress = useStoreState(jobProgressSelector);

  // ACTIONS
  const setCurrentStatus = useStoreActions(setCurrentStatusSelector);
  const setCurrentRoute = useStoreActions(setCurrentRouteSelector);
  const setCurrentJobId = useStoreActions(setCurrentJobIdSelector);

  // ROUTER
  const history = useHistory();

  const { entityType, entityId, segment } =
    useParams<TJobProgressSwitchParams>();

  const updatePageStatus = (): void => {
    if (!initialized || !entityId) {
      return;
    }

    setCurrentJobId(entityId);

    if (!segment) {
      return history.push(`/${entityType}/${entityId}/${jobProgress}`);
    }

    setCurrentStatus(
      utilityGetPageState({
        progress: jobProgress,
        currentRoute: segment,
      }),
    );

    setCurrentRoute(segment);
  };

  useEffect(() => {
    if (contract?.jobId && !job) {
      window.location.assign(
        `${APP_BASE_PATH}/jobs/${contract.jobId}/${segment ?? ''}`,
      );
    }
  }, [contract, job]);

  useEffect(() => {
    updatePageStatus();
  }, [initialized, entityId, segment, jobProgress]);
};
