import { ProgressBar } from '@reverse-hr/pattern-library';
import useSkills from '../../../../../utils/hooks/application/use-skills';
import { useStoreState } from '../../../../../store/hooks';
import { jobStoreSelector } from '../../../../../model/Job/Job.selectors';

const Skills = ({ skills, filterType }) => {
  const job = useStoreState(jobStoreSelector);
  const { requirements } = job || {};
  const result = useSkills(skills, requirements);

  return (
    <td className="c-app-showcase__cell">
      {result[filterType].map((skill, idx) => (
        <ProgressBar
          key={`${skill.jobRequirement.id}_${idx}`}
          label={skill.jobRequirement.description}
          value={skill.evaluation}
          className="u-mb-space-16"
        />
      ))}
    </td>
  );
};

export default Skills;
