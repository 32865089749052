import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import session from './session';
import showcaseTable from './showcase-table';

import { GTM } from '../gtm';
import Sentry from '../sentry';
import { ModelCustomer } from './Customer';
import { ModelUser } from './User';
import { JOB_PAGE_STATES } from '../model/Job/_constants/Job.constants';
import { utilityChangeLocale } from '../utils/locale';
import { jobStore } from './Job/Job';
import { candidatesStore } from './Candidate/Candidate';
import { contractStore } from './Contract/Contract';
import { dealEntityStore } from './DealEntity/DealEntity';

export default {
  // global state
  initialized: false,
  currentRoute: null,
  currentStatus: JOB_PAGE_STATES.DOING,
  authenticationError: null,
  appError: null,
  modalId: null,

  initialize: thunk(
    async (
      actions,
      { isShareModalOpen, jobId, contractId },
      { getStoreActions, getStoreState },
    ) => {
      try {
        const {
          contract: { getContract },
          session: { authenticate },
          user: { fetchUser },
          showcaseTable: { initTable },
          job: { getJob },
          candidates: { getCandidates },
        } = getStoreActions();

        await utilityChangeLocale();

        if (isShareModalOpen) {
          actions.setModalId('share');
          actions.setModalOpened(true);
        }

        if (!jobId && !contractId) {
          try {
            return await authenticate();
          } catch (_error) {
            return;
          }
        }

        try {
          if (jobId) {
            await getJob({ jobId });
            await getCandidates({ jobId, itemsPerPage: 0 });
          } else {
            await getContract({ contractId });
          }
        } catch (_error) {
          return;
        }

        await fetchUser();
        initTable();

        const {
          customer: { companyName },
          job: { title },
          user: { type: userType, email },
        } = getStoreState();

        if (companyName && title && userType) {
          const accessType = !jobId ? 'prospetto' : 'cliente';

          const eventAction = `Accesso ${accessType}`;

          GTM.trackGTMEvent({
            action: eventAction,
            label: `${companyName} - ${jobId} - ${title}`,
          });

          GTM.trackGA4Event(eventAction, companyName, `${jobId} - ${title}`);

          Sentry.setUser({ userType, email });
        }
      } finally {
        actions.setInitialized(true);
      }
    },
  ),

  // actions
  setInitialized: action((state, payload) => {
    state.initialized = payload;
  }),

  setAuthenticationError: action((state, payload) => {
    state.authenticationError = payload;
  }),

  setCurrentRoute: action((state, payload) => {
    state.currentRoute = payload;
  }),

  setCurrentStatus: action((state, payload) => {
    state.currentStatus = payload;
  }),

  setModalOpened: action((state, payload) => {
    state.isModalOpened = payload;
  }),
  setModalId: action((state, payload) => {
    state.modalId = payload;
  }),

  setAppError: action((state, error) => {
    state.appError = error;
  }),

  updateDocumentTitle: thunk(
    async (actions, payload, { getState, dispatch }) => {
      const {
        currentRoute,
        job: { data: job },
        contract: { data: contract },
      } = getState();
      const step = i18next.t(`general.document_title.${currentRoute}`);

      document.title = `${step} - ${job?.title ?? contract?.title} - Reverse`;
    },
  ),
  changeLanguage: thunk(
    async (actions, selectedLanguage, { getStoreActions }) => {
      try {
        const {
          session: { authFetch },
        } = getStoreActions();
        await authFetch({
          endpoint: `/v1/auth/users/me/locale/${selectedLanguage}`,
          requestOptions: { method: 'PUT' },
        });
        window.location.reload();
      } catch (error) {
        await utilityChangeLocale(error.locale);
      }
    },
  ),
  // models
  customer: ModelCustomer,
  dealEntity: dealEntityStore,
  user: ModelUser,
  session,
  showcaseTable,
  job: jobStore,
  candidates: candidatesStore,
  contract: contractStore,
};

export const selectorRootActions = actions => actions;
export const selectorRootState = state => state;
