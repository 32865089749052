import { AppIntroFlow } from '../components/AppIntroFlow';
import { useTranslation } from 'react-i18next';
import { ClosingBody, ClosingSidebar } from '../components/Closing';
import { useStoreState } from 'easy-peasy';
import { candidatesSelector } from '../model/Candidate/Candidate.selectors';
import { useParams } from 'react-router-dom';

const Closing = () => {
  const { t } = useTranslation();
  const { entityId: jobId } = useParams();
  const { results: candidates } = useStoreState(candidatesSelector) || [];

  const closingApplications =
    candidates?.filter(
      application => !!application.offer && !!application.offer.acceptedAt,
    ) || [];

  return (
    <section data-testid="closing-page">
      <div className="container">
        <div className="row">
          <div className="col col--x-padded small-12 large-6">
            <AppIntroFlow
              title={t('closing.header.title')}
              body={t('closing.header.subtitle')}
            />

            <ClosingBody closings={closingApplications} jobId={jobId} />
          </div>

          <div className="col col--x-padded small-12 large-6">
            <ClosingSidebar hasClosings={!!closingApplications.length} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Closing;
