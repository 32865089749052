import { useTranslation } from 'react-i18next';
import { ClosingHiredCard } from './ClosingHiredCard';

export const ClosingBody = ({ closings, jobId }) => {
  const { t } = useTranslation();

  return (
    closings.length > 0 && (
      <>
        <p className="typo-text-body u-color-grey-60 u-mb-space-16">
          {t('closing.bingoHeader.label')}
        </p>

        <div className="o-grid-rpo-hiring">
          {closings.map(closing => (
            <ClosingHiredCard
              closing={closing}
              jobId={jobId}
              key={closing.id}
            />
          ))}
        </div>
      </>
    )
  );
};
