import type { Action, Thunk } from 'easy-peasy';
import type {
  TJobShowcaseConfiguration,
  TJobShowcaseStore,
} from './showcase/Showcase.type';
import type { TJobSharesStore } from './shares/Shares.type';
import type { StoreModel } from '../../store/types/store-model';
import type { TJobProgressSegment } from '../../components/navigation/JobProgressSwitch/JobProgressSwitch.types';
import type { TCurrency } from '../../models/Common';
import type { ObjectValues } from '../../utils/types/ObjectValues';
import type {
  JOB_ACTIVITY_TYPES,
  JOB_REQUIREMENT,
} from './constants/type.constants';
import type { Nullable } from '../../utils/types/Nullable';

export interface TJob {
  client: TJobClient;
  closedAt: Nullable<string>;
  companyName: string;
  contractId: string;
  id: string;
  isShowcaseEnabled: boolean;
  maximumSalary: number;
  minimumSalary: number;
  presentationMeetingScheduledAt: Nullable<TPresentationMeetingScheduledAt>;
  presentationMeetingSlidesUrl: Nullable<string>;
  profilingBoardUrl: Nullable<string>;
  requirements: TJobRequirement[];
  reverseGroupCompany: TReverseGroupCompany;
  recruitmentConsultant: TReverseConsultant;
  salaryCurrency: TCurrency;
  shares: TJobShare[];
  showcaseConfiguration: Nullable<TJobShowcaseConfiguration>;
  statistics: TJobStatistics;
  title: string;
}

export interface TJobStatistics {
  activities: TJobActivity[];
  addedProfiles: number;
  approvedProfiles: number;
  atLeastContactedProfiles: number;
  atLeastInterviewedProfiles: number;
  candidatesAtFirstInterview: number;
  candidatesConsideredForAdditionalInterviews: number;
  candidatesConsideredForAnOffer: number;
  candidatesDislikedByClient: number;
  candidatesLikedByClient: number;
  candidatesTakenIntoConsideration: number;
  candidatesWaitingForLikeOrDislike: number;
  candidatesWithAcceptedOffer: number;
  candidatesWithApprovedScoutReport: number;
  candidatesWithDeclinedOffer: number;
  candidatesWithDraftScoutReport: number;
  candidatesWithPendingOffer: number;
  evaluatedApplicants: number;
  interestedProfilesAfterJobInterviewWithBrandConcerns: number;
  interestedProfilesAfterJobInterviewWithCompensationConcerns: number;
  interestedProfilesAfterJobInterviewWithLocationConcerns: number;
  interestedProfilesAfterJobInterviewWithRoleConcerns: number;
  interestedProfilesAfterJobInterviewWithoutConcerns: number;
  notAGoodMatchProfiles: number;
  notInterestedProfilesBeforeJobInterview: number;
  presentedProfiles: number;
  profilesWhoAgreedToJobInterview: number;
  unevaluatedApplicants: number;
  notInterestedProfilesBecauseOfBrandConcerns: number;
  notInterestedProfilesBecauseOfCompensationConcerns: number;
  notInterestedProfilesBecauseOfLocationConcerns: number;
  notInterestedProfilesBecauseOfRoleConcerns: number;
  approvedProfilesWithConcerns: number;
  presentedProfilesWithConcerns: number;
}

const REVERSE_GROUP_COMPANIES = {
  IT: 'IT',
  DE: 'DE',
  FR: 'FR',
  ES: 'ES',
  AKI: 'AKI',
} as const;

export type TReverseGroupCompany = ObjectValues<typeof REVERSE_GROUP_COMPANIES>;

export interface TJobClient {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface TJobActivity {
  completedAt: string;
  headHunter: TJobHeadHunter;
  type: TJobActivityType;
}

export type TJobActivityType = ObjectValues<typeof JOB_ACTIVITY_TYPES>;

export interface TJobHeadHunter {
  avatarUrl: string;
  fistName: string;
  lastName: string;
}

export interface TJobShare {
  clientId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface TPresentationMeetingScheduledAt {
  localDateTime: string;
  utcDateTime: string;
  timeZone: string;
}

export interface TJobRequirement {
  description: string;
  id: string;
  type: TJobRequirementType;
}

type TJobRequirementType = ObjectValues<typeof JOB_REQUIREMENT>;

export const SalaryCurrency = {
  CHF: 'CHF',
  EUR: 'EUR',
  GBP: 'GBP',
  PLN: 'PLN',
  TND: 'TND',
  USD: 'USD',
} as const;

export interface TReverseConsultant {
  avatarUrl: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
}

/**
 * STORE MODEL TYPES
 */
export interface TJobStore {
  // store data model
  loading: boolean;
  error: unknown;
  data: TJob | null;
  currentJobId: string | null;
  progress: TJobProgressSegment | null;

  // store data actions, internal
  setCurrentJobId: Action<TJobStore, string>;
  setJobProgress: Action<TJobStore, TJobProgressSegment>;
  setLoading: Action<TJobStore, boolean>;
  setError: Action<TJobStore, unknown>;
  setData: Action<TJobStore, TJob>;

  // store data thunks, external to API
  getJob: Thunk<TJobStore, { jobId: string }, any, StoreModel>;

  // store data sub-stores
  shares: TJobSharesStore;
  showcase: TJobShowcaseStore;
}
