import { useStoreState } from 'easy-peasy';
import Horsejog from '../Horsejog';
import React, { useMemo } from 'react';
import { candidatesSelector } from '../../model/Candidate/Candidate.selectors';

export const InterviewingHorsejogs = () => {
  const { results: candidates } = useStoreState(candidatesSelector);
  const candidatesSortedByDiscardedAt = useMemo(() => {
    return candidates.sort((a, b) => {
      if (!!a.discardedAt === !!b.discardedAt) {
        return a.firstName.localeCompare(b.firstName);
      }

      return a.discardedAt ? 1 : -1;
    });
  }, [candidates]);

  return (
    <>
      {candidatesSortedByDiscardedAt.map((candidate, index) => (
        <Horsejog
          key={`${candidate.lastName}-${index}`}
          id={`horsejog-${candidate.id}-${index}`}
          horse={candidate}
        />
      ))}
    </>
  );
};
