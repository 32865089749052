import { thunk } from 'easy-peasy';
import { utilityThrowError } from '../../../utils/errors';
import type { TCandidateClientFeedbackStore } from './ClientFeedback.type';
import type { TCandidate } from '../Candidate.type';

export const candidateClientFeedbackStore: TCandidateClientFeedbackStore = {
  putClientFeedback: thunk(async (_, payload, helpers) => {
    const { jobId, candidateId, notes } = payload;
    const _actions = helpers.getStoreActions();

    _actions.candidates.setLoading(true);
    _actions.candidates.setError(true);

    try {
      let res = null;

      if (notes) {
        res = await _actions.session.authFetch({
          endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/dislike`,
          requestOptions: {
            method: 'PUT',
            body: JSON.stringify({ notes }),
          },
        });
      } else {
        res = await _actions.session.authFetch({
          endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}/like`,
          requestOptions: {
            method: 'PUT',
          },
        });
      }

      _actions.candidates.setCandidate(res as TCandidate);
    } catch (error) {
      _actions.candidates.setError(error);
      utilityThrowError(error);
    } finally {
      _actions.candidates.setLoading(false);
    }
  }),
};
