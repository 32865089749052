const IMAGE_FOLDER_PATH = '/assets/images';
const REVERSE_SQUARE_LOGO = `${IMAGE_FOLDER_PATH}/logo/square/reverse-square.png`;
const REVERSE_GROUP_BRAND_LOGO = `${IMAGE_FOLDER_PATH}/logo/reverse-group-logo.png`;
const REVERSE_LOGIN_BANNER_IMAGE = `${IMAGE_FOLDER_PATH}/reverse/login-banner.jpg`;

export const REVERSE_GROUP_BRAND_SQUARE_LOGOS = {
  AKI: '/assets/images/logo/square/aki-square.png',
  IT: REVERSE_SQUARE_LOGO,
  EN: REVERSE_SQUARE_LOGO,
  DE: REVERSE_SQUARE_LOGO,
  FR: REVERSE_SQUARE_LOGO,
  ES: REVERSE_SQUARE_LOGO,
};

export const REVERSE_GROUP_BRAND_LOGOS = {
  AKI: '/assets/images/logo/aaron-king-logo.png',
  IT: REVERSE_GROUP_BRAND_LOGO,
  EN: REVERSE_GROUP_BRAND_LOGO,
  DE: REVERSE_GROUP_BRAND_LOGO,
  FR: REVERSE_GROUP_BRAND_LOGO,
  ES: REVERSE_GROUP_BRAND_LOGO,
};

export const REVERSE_GROUP_LOGIN_BANNER_IMAGES = {
  AKI: REVERSE_LOGIN_BANNER_IMAGE,
  IT: REVERSE_LOGIN_BANNER_IMAGE,
  EN: REVERSE_LOGIN_BANNER_IMAGE,
  DE: REVERSE_LOGIN_BANNER_IMAGE,
  FR: REVERSE_LOGIN_BANNER_IMAGE,
  ES: REVERSE_LOGIN_BANNER_IMAGE,
};

export const BRAND_BASE_URL = {
  AKI: 'https://employer-view.aaronking.it',
  IT: 'https://app.reverse.hr',
  EN: 'https://app.reverse.hr',
  DE: 'https://app.reverse.hr',
  FR: 'https://app.reverse.hr',
  ES: 'https://app.reverse.hr',
};

export const BRAND_APP_BASE_PATH = {
  AKI: '',
  IT: '/employer_view',
  EN: '/employer_view',
  DE: '/employer_view',
  FR: '/employer_view',
  ES: '/employer_view',
};
