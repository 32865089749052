import {
  JOB_PAGE_STATES,
  JOB_PROGRESS_STATES_LIST,
  JOB_PROGRESS_STATES,
} from '../model/Job/_constants/Job.constants';

export const utilityGetPageState = ({
  progress,
  currentRoute,
  hasClosedAtDate = false,
  hasApplications = false,
}) => {
  const progressIndex = JOB_PROGRESS_STATES_LIST.indexOf(progress);
  const currentPageIndex = JOB_PROGRESS_STATES_LIST.indexOf(currentRoute);

  if (currentRoute === JOB_PROGRESS_STATES.INTERVIEWING && hasApplications) {
    return currentPageIndex >= progressIndex
      ? JOB_PAGE_STATES.DOING
      : JOB_PAGE_STATES.DONE;
  }

  if (!progress) {
    return JOB_PAGE_STATES.TO_DO;
  }

  if (hasClosedAtDate) {
    return JOB_PAGE_STATES.DONE;
  }

  if (progress === currentRoute) {
    return JOB_PAGE_STATES.DOING;
  }

  return currentPageIndex > progressIndex
    ? JOB_PAGE_STATES.TO_DO
    : JOB_PAGE_STATES.DONE;
};
