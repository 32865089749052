export const DEAL_CANDIDATE_DETAILS_SEGMENT = 'candidate_detail';
export const DEAL_SUCCESS_FEE_SEGMENT = 'success_fee';

export const DEAL_CANDIDATE_SEGMENTS = [
  DEAL_CANDIDATE_DETAILS_SEGMENT,
  DEAL_SUCCESS_FEE_SEGMENT,
];

export const CANDIDATES_SEGMENT = 'candidates';
export const SUCCESS_FEE_SEGMENT = 'success-fee';
