import { action, thunk } from 'easy-peasy';
import type {
  TCandidates,
  TCandidatesStore,
  TCandidate,
} from './Candidate.type';
import { feedbackMessagesStore } from './feedback-messages/FeedbackMessages';
import { downloadsStore } from './downloads/Downloads';
import { candidateClientFeedbackStore } from './client-feedback/ClientFeedback';

export const candidatesStore: TCandidatesStore = {
  candidates: {
    results: [],
    pagination: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    aggregations: {
      name: null,
      values: [],
    },
  },
  candidate: null,
  error: null,
  candidateError: null,
  loading: false,
  candidateDetailLoading: false,

  setCandidates: action((state, payload) => {
    state.candidates = payload;
  }),

  setCandidate: action((state, payload) => {
    state.candidate = payload;
  }),

  setCandidateDetailLoading: action((state, payload) => {
    state.candidateDetailLoading = payload;
  }),

  setError: action((state, payload) => {
    state.error = payload;
  }),

  setCandidateError: action((state, payload) => {
    state.candidateError = payload;
  }),

  setLoading: action((state, payload) => {
    state.error = payload;
  }),

  getCandidates: thunk(async (actions, payload, helpers) => {
    const { jobId, itemsPerPage, currentPage } = payload || {};
    const _storeActions = helpers.getStoreActions();

    actions.setLoading(true);
    actions.setError(null);

    try {
      const queryParams = new URLSearchParams();

      if (String(itemsPerPage)) {
        const itemsPerPageVal = itemsPerPage ? itemsPerPage.toString() : '0';
        queryParams.append('itemsPerPage', itemsPerPageVal);
      }

      if (currentPage) {
        queryParams.append('currentPage', currentPage.toString());
      }

      const res = await _storeActions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates${
          queryParams.toString().length > 0 ? `?${queryParams.toString()}` : ''
        }`,
      });

      actions.setCandidates(res as TCandidates);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),

  getCandidate: thunk(async (actions, payload, helpers) => {
    const { jobId, candidateId } = payload || {};
    const _storeActions = helpers.getStoreActions();

    actions.setCandidateDetailLoading(true);
    actions.setCandidateError(null);

    try {
      const res = await _storeActions.session.authFetch({
        endpoint: `/v1/employer-view/jobs/${jobId}/candidates/${candidateId}`,
      });

      actions.setCandidate(res as TCandidate);
    } catch (error) {
      actions.setCandidateError(error);
    } finally {
      actions.setCandidateDetailLoading(false);
    }
  }),

  feedbackMessages: feedbackMessagesStore,
  downloads: downloadsStore,
  clientFeedback: candidateClientFeedbackStore,
};
