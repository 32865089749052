import Video from '../Video';
import { useStoreState } from 'easy-peasy';
import classNames from 'classnames';
import { AppBanner } from '../AppBanner';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PageStateContext } from '../Providers/PageStateProvider';
import { GTM } from '../../gtm';
import {
  jobCompanyNameSelector,
  jobStoreSelector,
} from '../../model/Job/Job.selectors';
import { JOB_PAGE_STATES } from '../../model/Job/_constants/Job.constants';
import { APP_BASE_PATH } from '../../constants/navigation';

export const SearchingSidebar = () => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector);
  const companyName = useStoreState(jobCompanyNameSelector);
  const pageState = useContext(PageStateContext);

  const isPageStateDone = pageState === JOB_PAGE_STATES.DONE;

  const wrapperClassNames = classNames('col col--x-padded small-12', {
    'col--y-padded large-3': isPageStateDone,
    'large-4': !isPageStateDone,
  });

  const sidebarElementClassNames = classNames('o-sidebar', {
    'o-sidebar--y-bottom': isPageStateDone,
    'o-sidebar--y-center': !isPageStateDone,
  });

  const websiteImageSrc = `${APP_BASE_PATH}/assets/images/website.png`;

  const handleDownloadJraClick = () => {
    const eventAction = 'Download JRA';

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${companyName} - ${job.id} - ${job.title}`,
    });

    GTM.trackGA4Event(eventAction, companyName, `${job.id} - ${job.title}`);
  };

  return (
    <div className={wrapperClassNames}>
      <div className={sidebarElementClassNames}>
        {pageState === JOB_PAGE_STATES.TO_DO ? (
          <Video video="smartphone" sources={['webm']} />
        ) : null}

        {pageState === JOB_PAGE_STATES.DOING ? (
          <img
            width="256"
            src="/employer_view/assets/images/website.png"
            alt=""
          />
        ) : null}

        {isPageStateDone && !!job.presentationMeetingSlidesUrl ? (
          <AppBanner
            isLayoutSkyscraper
            title={t('searching.jraCard.title')}
            body={t('searching.jraCard.subtitle')}
            ctaLabel={t('searching.jraCard.download')}
            ctaUrl={job.presentationMeetingSlidesUrl}
            onClick={handleDownloadJraClick}
          />
        ) : null}
      </div>
    </div>
  );
};
