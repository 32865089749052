import { useState, type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CodeInput } from '../../CodeInput';
import { Action } from '@reverse-hr/pattern-library';
import {
  apiGenerateTokenPost,
  apiUsersLoginTokensVerifyPost,
} from '../../../api/session';
import { setJwt } from '../../../utils/session';
import { APP_BASE_PATH } from '../../../constants/navigation';

export interface TPageLoginWithCodeProps {
  userMail: string;
}

export const PageLoginWithCode: FC<TPageLoginWithCodeProps> = props => {
  const { userMail } = props;
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation('', {
    keyPrefix: 'login.withCode',
  });

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    if (hasError) setHasError(false);

    try {
      const redirectPage =
        localStorage.getItem('EmployerView::authRedirectPath') ||
        `${APP_BASE_PATH}/`;
      const jwtResponse = await apiUsersLoginTokensVerifyPost({
        email: userMail,
        verificationCode: code,
      });

      setJwt(jwtResponse);
      window.location.assign(redirectPage);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  const handleNewCodeRequest = async (): Promise<void> => {
    try {
      await apiGenerateTokenPost(userMail);
    } catch (e) {
      console.error('ERROR::NEW_CODE_GENERATION_ERROR');
    }
  };

  const handleInputChange = (value: string): void => {
    if (hasError) {
      setHasError(false);
    }
    setCode(value);
  };

  return (
    <>
      <div className="c-page-login__intro">
        <h1 className="c-page-login__title">{t('title')}</h1>

        <p className="c-page-login__description">
          <Trans i18nKey="login.withCode.description" />
        </p>
      </div>

      <div className="c-page-login__code-wrapper">
        <CodeInput
          label={t('codeLabel') as string}
          length={6}
          error={hasError ? t('codeError') : undefined}
          onChange={handleInputChange}
        />
      </div>

      <div className="c-page-login__submit">
        <Action
          disabled={code.length < 6 || isLoading}
          label={t('submitCta') || ''}
          modifier="c-page-login__cta"
          onClick={handleSubmit}
        />
        <p className="c-page-login__support">
          <Trans i18nKey="login.withCode.support">
            0<button onClick={handleNewCodeRequest}>1</button>
          </Trans>
        </p>
      </div>
    </>
  );
};
