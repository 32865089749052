import i18next from 'i18next';
import type { TCandidate } from '../Candidate.type';

export const getDownloadFilenameFallbackFromCandidates = (
  candidates: TCandidate[],
  candidateId: string,
  i18nStringRef: string,
): string | null => {
  const candidate = candidates.find(c => c.id === candidateId);

  if (!candidate) {
    return null;
  }

  return i18next.t(i18nStringRef, {
    firstName: candidate.firstName,
    lastName: candidate.lastName,
  });
};

export const getDownloadFilenameFallbackFromCandidate = (
  candidate: TCandidate,
  i18nStringRef: string,
): string | null => {
  if (!candidate) {
    return null;
  }

  return i18next.t(i18nStringRef, {
    firstName: candidate.firstName,
    lastName: candidate.lastName,
  });
};
