export const SEARCHING_DE = {
  header: {
    title: 'Suche',
    subtitle:
      'Auf dieser Seite finden Sie alle Daten zur Bewerbersuche. Wieviele Personen haben wir angesprochen, mit wievielen haben wir gesprochen, wieviele sind interessiert.',
    subtitle_done: '',
  },
  numbers: {
    title_profilesCount: 'Ansprachen',
    title_profilesWithJobInterviewReportCount: 'Interviewed',
    title_interestedProfilesCount: 'Interessenten',
    title_interestedWithConcerns:
      'Passende Kandidaten lehnten aus den folgenden Gründen ab:',
    description_profilesCount:
      'Personen, die wir als passend bewertet und angesprochen haben.',
    description_profilesWithJobInterviewReportCount:
      'Mit diesen Personen haben wir ein Erstgespräch geführt.',
    description_interestedProfilesCount:
      'Personen, die ihr Interesse an dem Angebot bekundet haben. Mit den meisten von ihnen haben wir bereits gesprochen oder das erste Vorstellungsgespräch vereinbart.',
    description_brand: 'Brand',
    description_role: 'Rolle',
    description_compensation: 'Gehalt',
    description_location: 'Ort',
  },
  daysCard: {
    'intro_to-do': '',
    intro_doing: 'Sie werden alle Daten in',
    daysNumber: '10',
    days: 'Tagen',
    days_one: 'Tage',
    'body_to-do':
      'nach Vertragsunterschrift werden Sie in alle Informationen einsehen können',
    body_doing: 'einsehen können',
  },
  jraCard: {
    title:
      'Die Zusammenfassung der Suchaktivitäten, der Marktanalyse, der passenden Bewerber:',
    subtitle:
      'Hier finden sie die Unterlagen die die Suche und die Marktanalyse beinhalten. ',
    image: 'contract',
  },
  tasks: {
    title:
      'Möchten Sie wissen, wie unsere Bewerbersuche vorangeht? <strong>Sehen Sie sich meine Aktivitäten an</strong>',
    viewLabel_grid: 'Rasteransicht',
    viewLabel_list: 'Listenansicht',
    card: {
      date: '$t(common.formatting.date.dateFormat_time) - $t(common.formatting.date.dateFormat)',
      task: '',
      taskDescription: '',
      taskDescription_job_interview_report_filled:
        'hat mit {{ count }} Personen gesprochen',
      taskDescription_profile_added:
        'hat {{ count }} Hunting Aktivitäten durchgeführt',
      taskDescription_profile_added_one:
        'hat {{ count }} Hunting Aktivitäten durchgeführt',
      taskDescription_application_set_first_day_on_the_job:
        'hat das Anfangsdatum von {{ candidateFullName }} eingetragen',
      taskDescription_application_handle_client_interview_feedback:
        'hat bei der finalen Entscheidung zu {{ candidateFullName }} mitgeholfen',
      taskDescription_application_handle_additional_client_interviews:
        'ha preparato il colloquio con {{ candidateFullName }}',
      taskDescription_application_help_negotiate_offer:
        'hat die Verhandlung mit {{ candidateFullName }} unterst\u00fctzt',
      taskDescription_application_present_offer_to_candidate:
        'hat die Einstellung von {{ candidateFullName }} unterst\u00fctzt',
      taskDescription_application_schedule_client_interview:
        'hat das Bewerbunggespr\u00e4ch zwischen {{ companyName }} und {{ candidateFullName }} arrangiert',
      taskDescription_application_ask_for_client_feedback_on_curriculum:
        'hat das Feedback zu {{ candidateFullName }} erhalten',
      taskDescription_application_prepare_for_additional_client_interview:
        'hat das Bewerbunggespr\u00e4ch zwischen {{ companyName }} und {{ candidateFullName }} arrangiert',
      taskDescription_application_handle_additional_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_prepare_for_client_interview:
        'hat das Bewerbunggespr\u00e4ch zwischen {{ companyName }} und {{ candidateFullName }} arrangiert',
      taskDescription_application_handle_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_schedule_client_interview_after_scout_report:
        'hat das Bewerbunggespr\u00e4ch zwischen {{ companyName }} und {{ candidateFullName }} arrangiert',
      taskDescription_application_notify_client_of_offer_refusal:
        'ha comunicato a {{ companyName }} che {{ candidateFullName }} si \u00e8 ritirato',
      taskDescription_application_check_in_day_before_new_job:
        'hat {{ candidateFullName }} viel Erfolg gew\u00fcnscht',
      taskDescription_application_check_in_after_fifteen_days_on_new_job:
        'hat {{ candidateFullName }} gefragt, wie es l\u00e4uft',
      taskDescription_application_check_in_after_fifty_days_on_new_job:
        'ha chiesto un ulteriore feedback a {{ candidateFullName }} sulla sua nuova esperienza',
      taskDescription_application_prepare_for_scout_interview:
        'hat das Scoutgespr\u00e4ch mit {{ candidateFullName }} arrangiert',
      taskDescription_application_handle_client_dislike:
        'hat bez\u00fcglich des negativen Feedbacks zu {{ candidateFullName }} nachgefragt',
      taskDescription_application_ask_for_scout_report:
        'hat den Scoutbericht erhalten und analysiert',
      taskDescription_application_approve_scout_report:
        '{{ candidateFullName }} kommt nach dem Scoutgespr\u00e4ch in die n\u00e4chste Runde',
      taskDescription_task_job_send_profiling_meeting_recap_email:
        'hat die Zusammenfassung des Pofilings gesendet',
      taskDescription_task_job_schedule_presentation_meeting:
        'hat das JRA Datum festgelegt',
      taskDescription_task_job_contact_client_after_profiling_meeting:
        'hat eine Mail mit dem Zugangslink f\u00fcr das Dashboard gesendet',
      taskDescription_task_job_prepare_for_presentation_meeting:
        'hat Bewerber an das Unternehmen gesendet',
      taskDescription_task_job_hunt_candidates:
        'hat die Stellenausschreibung auf den versch. Kan\u00e4len ver\u00f6ffentlicht',
      taskDescription_task_job_find_scout: 'hat den Scout ausgew\u00e4hlt',
      taskDescription_task_job_schedule_profiling_meeting:
        'hat den Videocall f\u00fcr das Profiling angesetzt',
      taskDescription_task_job_prepare_job_post:
        'hat das Mandat auf der Reverse Plattform ver\u00f6ffentlicht',
      taskDescription_task_job_prepare_for_profiling_meeting:
        'hat den Videcall f\u00fcr das Profiling vorbereitet',
    },
  },
};
