import React from 'react';
import { Parser } from 'html-to-react';
import { Avatar, Bubble } from '@reverse-hr/pattern-library';
import { formatDate, formatLinebreak } from '../../../utils/functions';
import PropTypes from 'prop-types';

const ChatBubble = ({
  type,
  modifier,
  isAuthorHeadHunter,
  authorAvatar,
  authorFullName,
  leftAt,
  hourLabel,
  content,
}) => {
  const htmlParser = new Parser();

  const getFormattedDate = () => {
    if (!!leftAt) {
      return formatDate(leftAt, 'dd/MM/yyyy HH:mm').split(' ').join(hourLabel);
    }
  };

  const getSanitizedMessage = content => {
    return content
      ?.replace(/\<|\\u003C/g, '&lt;')
      ?.replace(/\>|\\u003E/g, '&gt;');
  };

  return (
    <div
      data-testid="chat-bubble-container"
      className={`c-app-chat-bubble c-app-chat-bubble--${type} ${modifier}`}
    >
      <div
        data-testid={
          isAuthorHeadHunter ? `chat-bubble-manager` : 'chat-bubble-customer'
        }
        className="c-app-chat-bubble__user"
      >
        <Avatar avatar={isAuthorHeadHunter ? authorAvatar : null} size={56} />
      </div>

      <div className="c-app-chat-bubble__main">
        <p
          data-testid="chat-bubble-author-and-date"
          className="c-app-chat-bubble__info"
        >
          {authorFullName} - {getFormattedDate()}
        </p>
        <div data-testid="chat-bubble-text">
          <Bubble corner={type === 'sender' ? 'top-right' : 'top-left'}>
            {htmlParser.parse(formatLinebreak(getSanitizedMessage(content)))}
          </Bubble>
        </div>
      </div>
    </div>
  );
};

ChatBubble.propTypes = {
  type: PropTypes.oneOf(['sender', 'receiver']),
  modifier: PropTypes.string,
  isAuthorHeadHunter: PropTypes.bool,
  authorAvatar: PropTypes.string,
  authorFullName: PropTypes.string,
  leftAt: PropTypes.string,
  hourLabel: PropTypes.string,
  content: PropTypes.string,
};

ChatBubble.defaultProps = {
  type: 'sender',
  modifier: '',
  isAuthorHeadHunter: false,
  authorFullName: '',
};

export default ChatBubble;
