import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorScreen } from './ErrorScreen';
import { APP_BASE_PATH } from '../../constants/navigation';

export const ErrorScreenGeneric: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'general.error',
  });

  const warningImageSrc = `${APP_BASE_PATH}/assets/images/warning.png`;

  return (
    <ErrorScreen>
      <img
        alt={t('title')}
        className="c-error-screen__alert-image"
        src={warningImageSrc}
      />

      <p className="c-error-screen__subtitle">{t('subtitle')}</p>
    </ErrorScreen>
  );
};
