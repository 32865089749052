import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import TheHeader from '../components/TheHeader';
import Footer from '../components/Footer/Footer';
import Masthead from '../components/Masthead';
import Helper from '../components/Helper';
import Stepper from '../components/Stepper';
import Modal from '../components/Modal';
import { ShareLinkModal } from '../components/ShareLinkModal';

import { header, hero } from '../utils/default-values';
import useShareModal from '../utils/hooks/use-share-modal';
import { selectorUser } from '../model/User';
import { MODAL_IDS_MAP } from '../constants/modals';
import { GTM } from '../gtm';
import { USER_TYPES_MAP } from '../constants/session';
import { selectorSession } from '../model/session';
import { LoaderFullPage } from '../loaders/LoaderFullPage';
import { jobStoreSelector } from '../model/Job/Job.selectors';
import { APP_BASE_PATH } from '../constants/navigation';
import { IS_THEME_AKI } from '../.configs/environment';

const MainLayout = ({ children }) => {
  const [helperText, setHelperText] = useState(null);
  const [manager, setManager] = useState(null);

  const location = useLocation();

  // STATE
  const user = useStoreState(selectorUser);
  const job = useStoreState(jobStoreSelector) || {};
  const currentRoute = useStoreState(state => state.currentRoute);
  const currentStatus = useStoreState(state => state.currentStatus);
  const isModalOpened = useStoreState(state => state.isModalOpened);
  const modalId = useStoreState(state => state.modalId);
  const initialized = useStoreState(state => state.initialized);
  const { loading } = useStoreState(selectorSession);

  const { handleShareButtonClick } = useShareModal();

  useEffect(() => {
    if (currentRoute) {
      const payload =
        currentRoute === 'profiling'
          ? {
              name: user.firstName,
              context: user.firstName !== null && 'withName',
            }
          : null;

      setHelperText(hero(currentStatus, payload).helperText[currentRoute]);
    }
  }, [location, currentRoute, currentStatus]);

  useEffect(() => {
    if (job) {
      setManager(job?.recruitmentConsultant ?? job?.salesConsultant);
    }
  }, [job?.recruitmentConsultant, job?.salesConsultant]);

  const handleAnalytics = step => {
    const eventAction = `Navigazione ${
      user.type === USER_TYPES_MAP.MANAGER ? 'prospect' : 'cliente'
    }`;

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${job.companyName} - ${job.id} - ${job.title} - ${step.name}`,
    });

    GTM.trackGA4Event(
      eventAction,
      job.companyName,
      `${job.id} - ${job.title} - ${step.name}`,
    );
  };

  const getSteps = () => {
    return hero().steps(!!job.profilingBoardUrl);
  };

  return (
    <main>
      {!initialized || loading ? (
        <LoaderFullPage />
      ) : (
        <>
          <div className="container">
            <div className="row">
              <div className="col col--x-padded u-py-space-16">
                <TheHeader labels={header()} onClick={handleShareButtonClick} />
              </div>
            </div>
          </div>
          <Masthead
            job={job}
            currentRoute={currentRoute}
            isBackToHomepageAvailable
          >
            {{
              left: (
                <div className="c-app-masthead__stepper">
                  <Stepper steps={getSteps()} onRouteChange={handleAnalytics} />
                </div>
              ),
              right: (
                <Helper
                  modifier="c-app-helper--masthead"
                  text={helperText}
                  manager={manager}
                />
              ),
            }}
          </Masthead>
        </>
      )}
      <div className="c-main-layout">{children}</div>
      <Footer
        logo={
          IS_THEME_AKI
            ? `${APP_BASE_PATH}/assets/images/logo/aaron-king-logo.png`
            : undefined
        }
        payoff={IS_THEME_AKI ? 'aki-payoff' : undefined}
      />
      <Modal visible={isModalOpened && modalId === MODAL_IDS_MAP.SHARE}>
        <ShareLinkModal />
      </Modal>
    </main>
  );
};

export default MainLayout;
