import { Header } from '@reverse-hr/pattern-library';
import React, { FC } from 'react';
import { IS_THEME_AKI } from '../../../.configs/environment';
import { REVERSE_GROUP_BRAND_LOGOS } from '../../../constants/brand';
import { APP_BASE_PATH } from '../../../constants/navigation';

export const PageLoginHeader: FC = () => {
  const logo = IS_THEME_AKI
    ? REVERSE_GROUP_BRAND_LOGOS.AKI
    : REVERSE_GROUP_BRAND_LOGOS.IT;

  return (
    <Header
      externalLogo={`${APP_BASE_PATH}${logo}`}
      modifier="c-page-login__header"
    >
      <div></div>
    </Header>
  );
};
