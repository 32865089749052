import { SalaryCurrency } from '../Job.type';

export const JOB_PROGRESS_STATES = {
  PROFILING: 'profiling',
  SEARCHING: 'searching',
  INTERVIEWING: 'interviewing',
  CLOSING: 'closing',
};

export const JOB_PROGRESS_STATES_LIST = Object.values(JOB_PROGRESS_STATES);

export const JOB_PAGE_STATES = {
  TO_DO: 'to-do',
  DOING: 'doing',
  DONE: 'done',
};

export const CONTRACT_JOB = {
  presentationMeetingScheduledAt: null,
  presentationMeetingSlidesUrl: null,
  profilingBoardUrl: null,
  isShowcaseEnabled: false,
  closedAt: null,
  minimumSalary: 0,
  maximumSalary: 0,
  salaryCurrency: SalaryCurrency.EUR,
  requirements: [],
  shares: [],
  showcaseConfiguration: null,
  statistics: {
    activities: [],
    addedProfiles: 0,
    approvedProfiles: 0,
    atLeastContactedProfiles: 0,
    atLeastInterviewedProfiles: 0,
    candidatesAtFirstInterview: 0,
    candidatesConsideredForAdditionalInterviews: 0,
    candidatesConsideredForAnOffer: 0,
    candidatesDislikedByClient: 0,
    candidatesLikedByClient: 0,
    candidatesTakenIntoConsideration: 0,
    candidatesWaitingForLikeOrDislike: 0,
    candidatesWithAcceptedOffer: 0,
    candidatesWithApprovedScoutReport: 0,
    candidatesWithDeclinedOffer: 0,
    candidatesWithDraftScoutReport: 0,
    candidatesWithPendingOffer: 0,
    evaluatedApplicants: 0,
    interestedProfilesAfterJobInterviewWithBrandConcerns: 0,
    interestedProfilesAfterJobInterviewWithCompensationConcerns: 0,
    interestedProfilesAfterJobInterviewWithLocationConcerns: 0,
    interestedProfilesAfterJobInterviewWithRoleConcerns: 0,
    interestedProfilesAfterJobInterviewWithoutConcerns: 0,
    notAGoodMatchProfiles: 0,
    notInterestedProfilesBeforeJobInterview: 0,
    presentedProfiles: 0,
    profilesWhoAgreedToJobInterview: 0,
    unevaluatedApplicants: 0,
    notInterestedProfilesBecauseOfBrandConcerns: 0,
    notInterestedProfilesBecauseOfCompensationConcerns: 0,
    notInterestedProfilesBecauseOfLocationConcerns: 0,
    notInterestedProfilesBecauseOfRoleConcerns: 0,
    approvedProfilesWithConcerns: 0,
    presentedProfilesWithConcerns: 0,
  },
};
