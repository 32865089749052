export const LANG_AKI_IT = {
  footer: {
    company: '© {{ year }} Aaron King International parte di Reverse SpA',
    headquarter: 'Sede legale: Piazza Sigmund Freud 1, 20154 Milano',
    informations: [
      'Partita IVA: 09714690964',
      'PEC: reversespa@pec.it',
      'Registro delle Imprese di Milano - 2109818',
      'Capitale sociale interamente versato: 50.000€',
      'Numero REA: MI - 2109818',
    ],
    companyBaseUrl: 'https://www.reverse.hr/it/',
    menu: {
      contact: {
        label: 'Contattaci',
        url: 'https://www.reverse.hr/it/contattaci/',
      },
      legal: {
        label: 'Legal',
        url: 'https://www.reverse.hr/it/legal',
      },
      cookies: {
        label: 'Cookies',
        url: 'https://www.iubenda.com/privacy-policy/19612468/cookie-policy',
      },
    },
  },
};
