import { format } from 'date-fns';
import type { TJobActivity } from '../../../model/Job/Job.type';

export interface TStackedActivity extends TJobActivity {
  count: number;
}

export interface StacksByDate {
  [date: string]: TStackedActivity[];
}

export const getStacks = (activities: TJobActivity[]): StacksByDate => {
  const stacksByDate = activities.reduce<StacksByDate>((acc, activity) => {
    const dateKey = format(new Date(activity.completedAt), 'yyyy-MM-dd');

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }

    // Handle type counting and prevent duplication
    const existingActivity = acc[dateKey]?.find(a => a.type === activity.type);

    if (existingActivity) {
      existingActivity.count += 1;
    } else {
      acc[dateKey]?.push({ ...activity, count: 1 });
    }

    return acc;
  }, {});

  return stacksByDate;
};

export const sortStacksKeys = (activitiesDates: StacksByDate): string[] => {
  return Object.keys(activitiesDates).sort((a, b) => (a < b ? 1 : -1));
};

export const sortTasksByDate = (activities: TJobActivity[]): TJobActivity[] =>
  activities.sort((a, b) => (a.completedAt < b.completedAt ? 1 : -1));
