import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { selectorUser } from '../../model/User';
import PropTypes from 'prop-types';

export const WelcomeMessage = ({
  labelClassName = 'typo-text-label',
  nameClassName = 'typo-text-body',
}) => {
  const { t } = useTranslation();
  const user = useStoreState(selectorUser);

  return (
    <div className="c-welcome-message c-welcome-message--homepage u-txt-right">
      <p className={labelClassName}>{t('header.welcome')}</p>

      {!!user.firstName && !!user.lastName && (
        <p className={nameClassName}>{t('common.formatting.fullName', user)}</p>
      )}
    </div>
  );
};

WelcomeMessage.propTypes = {
  labelClassName: PropTypes.string,
  nameClassName: PropTypes.string,
};

WelcomeMessage.defaultProps = {
  labelClassName: 'typo-text-label',
  nameClassName: 'typo-text-body',
};
