import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ErrorBoundary } from '@sentry/react';

import model from './model';
import fetcher from './utils/api-service';
import { createStore, StoreProvider } from 'easy-peasy';
import './i18n';
import Sentry from './sentry';
import '@reverse-hr/pattern-library/dist/index.css';
import './assets/stylesheets/main.scss';
import { ErrorScreenGeneric } from './components/ErrorScreen';
import type { TSessionStoreModel } from './models/Session';
import type { TJobStore } from './model/Job/Job.type';
import type { TCandidatesStore } from './model/Candidate/Candidate.type';

export interface StoreModel {
  session: TSessionStoreModel;
  job: TJobStore;
  candidates: TCandidatesStore;
}

const store = createStore(model, {
  injections: {
    apiService: { fetcher },
  },
});
Sentry.initialize();

ReactDOM.render(
  <StoreProvider store={store}>
    <React.StrictMode>
      <ErrorBoundary fallback={<ErrorScreenGeneric />}>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  </StoreProvider>,
  document.getElementById('root'),
);
